.loginFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.loginForm {
  margin: 40px;
}

.input_container {
  width: 100% !important;
  margin: 10px 0px;
}

.password_requirements {
  margin-top: 10px;
  font-size: 12px;
}

.password_requirements_error {
  margin-top: 10px;
  font-size: 12px;
  color: var(--color-theme-red);
}

.btnContainer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}
