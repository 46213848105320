.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px !important;
}

.root p {
  font-size: 16px !important;
}

.subscription_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: bold;
}

.subscription_header_blue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: bold;
  color: var(--color-theme-blue);
}

.right_row {
  display: flex;
  align-items: center;
}

.right_row div {
  width: 170px;
}

.left_row {
  display: flex;
  align-items: center;
}

.content_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.content_container_blue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  color: var(--color-theme-blue);
}

.left_container {
  width: 436px;
  padding: 40px;
}

.features_header {
  font-weight: bold;
  padding-bottom: 10px;
}

.list_item {
  display: flex;
  width: 360px;
  align-items: center;
}

.list_item p {
  padding: 10px;
  font-weight: bold;
}

.check_circle_icon {
  width: 14px !important;
  height: 14px !important;
}

.right_container {
  background-color: var(--color-theme-gray);
  width: 100%;
  display: flex;
  padding: 40px;
}

.right_container_lite {
  background-color: var(--color-theme-gray);
  width: 100%;
  display: flex;
  padding: 40px;
  justify-content: flex-end;
}

.image_container {
  display: flex;
}

.pro_illustration {
  width: 193px;
  height: 328px;
}

.lite_illustration {
  margin-right: 40px;
  width: 118px;
  height: 265px;
}

.enterprise_illustration {
  width: 142px;
  height: 342px;
}

.plan {
  font-weight: bold;
}

.plus_circle {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 100px;
  left: 420px;
  background-color: var(--color-theme-white);
  border-radius: 100%;
}

.rocket_icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.star_icon {
  width: 28px !important;
  height: 28px !important;
  margin-left: 10px;
}

.star_icon_blue {
  width: 28px !important;
  height: 28px !important;
  margin-left: 10px;
  color: var(--color-theme-blue) !important;
}

.camera_icon {
  margin-left: 10px;
}

.btn_upgrade {
  font-weight: bold !important;
  width: 255px;
  height: 37px;
  color: var(--color-theme-steelgray) !important;
  border: 1px solid var(--color-theme-steelgray) !important;
}

.btn_upgrade:hover {
  font-weight: bold !important;
  width: 255px;
  height: 37px;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-black) !important;
}

.btn_upgrade_blue {
  font-weight: bold !important;
  width: 255px;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.btn_upgrade_blue:hover {
  font-weight: bold !important;
  height: 37px;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.current_plan {
  font-weight: bold;
}

.check_icon {
  margin-left: 10px !important;
}

.arrow_icon {
  width: 24px !important;
  height: 24px !important;
  color: var(--color-theme-gray-white);
}

.info_text {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  margin-top: 10px;

}
.info_text p {
  font-size: 13px !important;
  color: var(--color-theme-steelgray);
}
