.root {
  margin-bottom: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.root_inactive {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.root_active {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.root:hover {
  background-color: var(--color-bg-caselist-item-hover);
}

.root_deactivated {
  background-color: var(--color-theme-red);
  color: var(--color-theme-red);
}

.left_container {
  width: 60px;
  text-align: center;
  min-width: 60px;
  margin-right: 10px;
}

.user_role {
  font-size: 10px;
  padding-top: 5px;
}

.middle_container {
  width: 210px;
}

.host_value {
  width: 220px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.user_name_container {
  width: 220px;
  display: block;
  padding-top: 5px;
  font-weight: bold;
}

.user_name_value {
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
}

.total_value {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
}

.right_container {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.right_icon_container {
  text-align: center;
}

.callback_icon {
  color: var(--color-font-red);
}

.callback_date {
  font-size: 10px;
  padding-top: 5px;
}

.right_icon_container {
  text-align: center;
}

.view_date {
  font-size: 10px;
  padding-top: 5px;
}

.empty {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.license_icon_container {
  display: inline-block;
  width: 45px;
  text-align: right;
}

.license_icon {
  color: var(--color-font-green);
}

.license_icon svg {
  height: 16px;
}

.host_url {
  display: inline-block;
  width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.curr_sign {
  font-size: 10px;
}

.user_role {
  padding: 0px;
}

.user_role {
  color: var(--color-font-10);
}

.deactivated {
  color: var(--color-theme-deactivated);
  background-color: var(--color-theme-bright-gray);
}

.pending {
  color: var(--color-theme-gray-white);
  background-color: var(--color-theme-white);
}

.active {
  background-color: var(--color-theme-white);
}

.selected {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}
