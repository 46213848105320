.btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.save_activate_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin: 20px !important;
}
