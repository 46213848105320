.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.settlement_portal_title {
  display: flex;
  align-items: center;
}

.device_icon {
  padding-right: 10px;
}

.arrow_icon {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.tabs_container {
  margin-top: 60px;
  border: 1px solid var(--color-theme-bright-gray);
}

.tabs_content_container {
  padding: 60px 30px 30px 30px;
  border: 1px solid var(--color-theme-bright-gray);
  border-top: none;
}

.tabs_content_container_images {
  border: 1px solid var(--color-theme-bright-gray);
  border-top: none;
}

.tab_content_portal_address {
  width: 70%;
}

.tab_content_legal_contact {
  width: 775px;
}

.example_input_container {
  display: flex;
  align-items: center;
}

.example_input_container p {
  padding: 0px 10px;
  margin-bottom: 8px;
}

.input_container {
  width: 370px !important;
}

.subdomain_container {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-theme-bright-gray);
}

.label {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.label b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.confirm_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0px;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.confirm_btn_disabled {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.description {
  padding-bottom: 20px;
}

.upload_size {
  padding-left: 10px;
}
/* legal & contact */

.input_row {
  display: flex;
  justify-content: space-between;
}

/* image upload */

.general_col_images {
  display: flex;
  width: 55%;
  justify-content: space-between;
}

.general_image_container {
  display: flex;
  justify-content: space-between;
}

.upload_title_container {
  display: flex;
}

.upload_size {
  color: var(--color-theme-silver);
  font-weight: bold !important;
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}

.cloud_upload_icon {
  color: var(--color-theme-cloud);
  cursor: pointer;
}

.cloud_upload_icon:hover {
  color: var(--color-theme-blue);
}

.logo_box {
  width: 300px;
  height: 84px;
  border-radius: 2px;
  overflow: scroll;
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.logo {
  width: 100%;
  object-fit: cover;
}

.favicon_box {
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 2px;
  overflow: scroll;
  position: relative;
}

.favicon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload_label {
  border: 1px solid var(--color-theme-silver);
  border-radius: 15px;
  background-color: var(--color-theme-silver);
  padding: 2px 10px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 1px 1px 1px var(--color-theme-darkgray);
  text-align: center;
}

.upload_label_disabled {
  border: 1px solid var(--color-theme-silver);
  border-radius: 15px;
  background-color: var(--color-theme-silver);
  padding: 2px 10px;
  margin: 10px;
  text-align: center;
}


.upload_input {
  display: none;
}

.upload_btn {
  width: 120px;
  height: 28px;
  font-weight: bold !important;
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  margin: 0px 10px !important;
}

.about_us_image_box {
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 200px;
  overflow: scroll;
  position: relative;
}

.about_us_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo_favicon_container {
  padding: 30px;
  padding-top: 60px;
}

.about_us_container {
  border-top: 1px solid var(--color-theme-bright-gray);
  padding: 30px;
}

.about_us_content {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.about_us_image_text {
  padding-bottom: 20px;
  width: 70%;
}

.delete_icon_container_logo {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.delete_icon_container_favicon {
  width: 84px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.delete_icon_container_about_us {
  width: 150px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.delete_icon {
  color: var(--color-theme-cloud);
  cursor: pointer;
  padding: 5px;
}

.delete_icon:hover {
  color: var(--color-theme-blue);
}

/* upgrade */
.upgrade_container {
  display: flex;
}

.upgrade_domain_image {
  width: 400px;
  height: 200px;
}

.upgrade_logo_image {
  width: 280px;
  height: 200px;
}

.upgrade_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
}

.upgrade_text_large {
  font-size: 20px;
  font-weight: bold;
}

.upgrade_btn_container {
  padding: 20px 0px;
}


.button_confirm {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.deletionPopupText{
  padding: 5px;
}

.deletionPopupButtonContainer{
  display: flex;
    margin-top: 36px;
    flex-direction: row-reverse;
}