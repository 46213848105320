.root {
  border: 1px solid var(--color-theme-bright-gray);
}

.user_header {
  background-color: var(--color-theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
  border-bottom: 1px solid var(--color-theme-bright-gray);
}

.user_header_deactivated {
  background-color: var(--color-theme-bright-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
  color: var(--color-theme-deactivated);
}

.user_header_pending {
  background-color: var(--color-theme-white);
  color: var(--color-theme-gray-white);
}

.user_header_pending b {
  color: var(--color-font-10);
}

.user_name_container {
  width: 40%;
  display: flex;
}

.user_name_container h2 {
  margin-right: 30px;
}

.icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.icon_container div {
  font-size: 11px;
}

.content_container {
  padding: 40px 30px 30px 30px;
  border-top: 1px solid var(--color-theme-gray);
  display: flex;
  justify-content: space-between;
}

.label p {
  font-size: 13px !important;
  color: var(--color-theme-red);
  padding: 0px;
  margin: 0px;
}

.btn_container {
  height: 330px;
  display: flex;
  align-items: flex-end;
  width: 50%;
  justify-content: space-between;
}

.btn_save_and_send_invite {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.btn_save_and_send_invite:disabled {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-gray-white) !important;
  border: 1px solid var(--color-theme-gray-white) !important;
  height: 40px;
}

.btn_send_invite_again {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-10) !important;
  height: 40px;
}

.btn_save_container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.btn_save {
  width: 170px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.btn_delete {
  width: 200px;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-10) !important;
  height: 40px;
}

.btn_cancel {
  width: 120px;
  font-weight: 600 !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  height: 40px;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.confirm_btn_disabled {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.input_container {
  width: 370px !important;
  padding: 5px 0px;
}

.accept_container {
  display: flex;
  align-items: flex-start;
  width: 250px;
  justify-content: space-between;
  margin: 20px 0px;
}

.checkbox_label {
  font-size: 11px;
}
