.root {
  width: 850px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.confirm_btn_container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  min-width: 450px;
  width: 100%;
}

.confirm_btn_container_bottom{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  min-width: 450px;
  width: 100%;
  margin-top: 15px;
}

.currencyRow {
  min-width: 450px;
  max-width: 650px;
}

.feeRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  border: 1px solid DARKGRAY;
  border-radius: 5px;
  padding: 0px 0px 0px 7px;
  margin-top: 10px;
}

.feeRowTextInput {
  margin-right: 32px !important;
}

.mt40{
  margin-top: 40px;
}