.content {
  background-color: var(--color-bg-content);
  min-width: 1200px;
  margin: 91px 0 40px 210px;
  padding: 0px 0px;
}

.content_no_user {
  background-color: var(--color-bg-content);
  min-width: 1200px;
  margin: 91px 0 40px;
  padding: 0px 0px;
}


/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

p {
  font-family: "Roboto";
  line-height: 1.6;
}

div {
  font-family: "Roboto";
}

h2 {
  font-family: "Roboto";
}
