.login {
  background-color: var(--color-bg-login);
  width: 100vw;
  height: 100vh;
}

.loginFormContainer {
  width: 448px;
  min-height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25vh; 
  left: 50vw; 
  transform: translate(-50%, -50%);
}

.loginFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.loginForm {
  margin: 40px;
}

.input_container {
  width: 100% !important;
  margin: 10px 0px;
}

.password_input {
  width: 100% !important;
  margin-top: 20px !important;
}

.btnContainer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
  margin-right: 55px;
}

.button:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.inputError {
  margin-top: 5px;
  color: var(--color-font-red);
  font-size: 12px;
}

.loginError {
  margin: 12px;
  font-size: 16px;
  color: var(--color-theme-red);
}

.link {
  color: var(--color-font-10);
  padding: 32px;
  font-size: 16px;
}

.forgot_password_container {
  color: var(--color-theme-blue);
  font-size: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.forgot_password_gray {
  color: var(--color-theme-lightgray);

  font-size: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.error_message {
  color: var(--color-theme-red);
}

/* email unverified */
.email_unverified {
  width: 448px;
  height: 179px;
  background-color: var(--color-theme-blue);
  color: var(--color-theme-white);
}

.email_unverified_content {
  padding: 15px;
}

.unverified_info_text {
  display: flex;
}

.email_unverified_content p {
  font-weight: bold;
}

.resend_btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resend_btn {
  color: var(--color-theme-white) !important;
  border: 1px solid var(--color-theme-white) !important;
  width: 336px;
  height: 41px;
  font-weight: bold !important;
  margin: 10px !important;
}

.resend_email_loading {
  color: var(--color-theme-white) !important;
  margin: 10px;
}

.resend_email_success {
  padding: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.resend_email_success p {
  width: 100px;
  padding-left: 20px;
}

.verification_success_container {
  background-color: var(--color-theme-success-green);
  color: var(--color-theme-white);
  display: flex;
  height: 55px;
  margin-top: 20px;
  align-items: center;
  font-weight: bold;
}

.check_icon {
  padding: 0px 10px 0px 20px;
}

.info_icon {
  margin: 3px 10px 0 10px;
}
