.root {
  width: 865px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
}

.section_container {
  width: 850px;
  margin-bottom: 5px;
}

.no_data{
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}

.top_container{
  width: 850px;
  margin-bottom: 5px;
  display: flex;
}