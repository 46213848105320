.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
}

.languages_container {
  display: flex;
}

.language_list_container {
  position: fixed;
  left: 210px;
  top: 91px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: -webkit-calc(100vh - 105px);
  height: -moz-calc(100vh - 105px);
  height: calc(100vh - 140px);
}

.tabs_and_content_container {
  width: 950px;
  overflow: hidden;
  margin-left: 310px;
  background-color: var(--color-bg-content);
}

.add_language_container {
  background-color: var(--color-bg-caselist);
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
}

.btn_add_language {
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
}

.btn_add_language:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.plus_sign {
  margin-right: 10px;
  font-size: 20px;
}

.translation_no_data {
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}
