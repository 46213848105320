.address_modal {
  max-width: 816px;
  padding: 0px 25px;
}
.address_container {
  display: flex;
  justify-content: space-between;
}

.text_label {
  margin-bottom: 5px;
}

.address_row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.address_header {
  padding-top: 10px;
  color: #283371;
}

.half_form {
  width: 48%;
}

.country_text {
  margin: 14px 0px 0px 0px !important;
  font-weight: bold;
}

.billing_header {
  font-size: 16px;
}

.billing_container {
  margin-bottom: 20px;
  min-width: 530px;
}

.billing_input {
  width: 100%;
  height: 30px;
  border: none;
  border-bottom: 1px solid #e4e7e8;
}

.button {
  float: right;
}

.required_info {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}

.mobile_view {
  display: none;
}

.actionsContainer {
  padding-left: 15px;

  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}
@media only screen and (max-width: 600px) {
  .address_modal {
    min-width: 0px;
    max-width: 816px;
    background-color: #ffffff;
    padding: 0px 25px;
  }
  .billing_container {
    margin-bottom: 20px;
    min-width: 100%;
  }
  .mobile_view {
    display: initial;
  }
  .normal_view {
    display: none;
  }
  .actionsContainer {
    align-items: center;
  }
  .country_text {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .mobile_view {
    display: initial;
  }
  .normal_view {
    display: none;
  }
  .address_modal {
    width: -webkit-fill-available;
    height: auto;
    padding: 15px;
  }
  .billing_container {
    margin-bottom: 20px;
    min-width: 100%;
  }

  .address_container {
    display: block;
  }
  .address_header_mobile {
    display: flex;
    margin: 0px;
  }
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nameWarningContainer {
  font-size: 13px;
  margin-bottom: 19px;
  margin-top: -25px;
  color: red;
}
