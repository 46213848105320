.charts_view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.chart {
  margin-top: 3%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 3px 15px;
}

.chart_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart_title_filter {
  width: 30%;
}

.chart_currency_filter {
  width: 15%;
}

.custom_tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 300px;
  overflow: scroll;
  pointer-events: all !important;
}

.custom_tooltip_label {
  margin-top: 0 !important;
  margin-bottom: 3px !important;
}

.tooltip_entry {
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}

.custom_tooltip_total {
  font-style: oblique;
  margin-top: 3px !important;
  margin-bottom: 0 !important;
}

.year_label {
  font-size: 12px;
}
