@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
.Footer_footer__UiIRZ {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  color: var(--color-font-10);
  background-color: var(--color-bg-footer);
  position: fixed;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
}

.Footer_footer_text__10B9N {
  font-size: 12px;
  padding-left: 30px;
  min-width: 550px;
}

.Footer_footer_text__10B9N p {
  font-size: 12px;
}

.Footer_footer__UiIRZ li {
  padding: 10px;
}

.Footer_footer_links_container__2cYu4 {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 80px;
  min-width: 160px;
}

.Footer_footer_links__3ko8M {
  text-decoration: none;
  color: var(--color-theme-10);
}

.Footer_footer_links__3ko8M:hover {
  border-bottom: 1px solid var(--color-theme-2);
}

.Sidebar_sidebar__KoCKq {
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 104px);
  overflow-y: scroll;
  width: 210px;
  background-color: var(--color-bg-sidebar);
  flex-grow: 1;
  padding-top: 75px;
}

.Sidebar_sidebar_header__6X5i7 {
  padding-left: 25px;
}

.Sidebar_navbar__uKjXy {
  margin-top: 8px;
  color: var(--color-font-10);
}

.Sidebar_List_item__2CW8V {
  background-color: var(--color-bg-sidebar-item) !important;
}

.Sidebar_List_item__2CW8V:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.Sidebar_selected__3xw3t {
  background-color: var(--color-bg-sidebar-item-selected) !important;
}

.Sidebar_selected__3xw3t:hover {
  background-color: var(--color-bg-sidebar-item-selected) !important;
}

.Sidebar_row_container__1fqAc {
  width: 220px;
  display: flex;
  align-items: center;
}

.Sidebar_count__3sscT {
  font-size: 15px;
  text-align: right;
  width: 30px;
  display: inline-block;
  font-weight: bold;
}

.Sidebar_cases_item__1F-GJ {
  font-size: 12px;
  color: var(--color-font-10);
  height: 50px;
}

.Sidebar_cases_icon__3eR2X {
  height: 22px;
}

.Sidebar_cases_icon__3eR2X svg {
  font-size: 22px !important;
  margin-left: 7px;
  margin-right: 10px;
}

.Sidebar_cases_title__2bWza {
  width: 106px;
  font-size: 16px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.Sidebar_status_item__20hPo {
  height: 45px;
  font-size: 12px;
}

.Sidebar_status_icon__NS4lx {
  height: 19px;
}

.Sidebar_status_no_icon__2Lft1 {
  margin-left: 65px;
}

.Sidebar_status_icon__NS4lx svg {
  font-size: 20px !important;
  margin-left: 24px;
  margin-right: 11px;
}

.Sidebar_status_title__1_0Xb {
  width: 90px;
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.Sidebar_status_title_non_clickable__2zATi {
  width: 90px;
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: default;
}

.Sidebar_settings_container__1LLf1 {
  padding-bottom: 150px;
}

.Sidebar_settings_nav__2JBiK {
  overflow: scroll;
}

.Sidebar_settings_icon__2xxri {
  font-size: 22px !important;
  margin-left: 7px;
  margin-right: 10px;
}

.Sidebar_email_flows_agent__GxJOJ {
  margin: 0px 0px;
}

.Header_root__1X4RS {
  padding: 25px 0;
  width: 100%;
  background-color: var(--color-bg-header);
  color: var(--color-font-10);
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  border: 1px solid var(--color-theme-cloud);
  align-items: center;
}

.Header_logo__ztHSm {
  width: 180px;
  font-size: 27px;
  font-weight: 900;
  padding: 10px;
}

.ChangePassword_container__3X-6h {
  width: 450px;
  height: 425px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  position: absolute;
  top: 92px;
  right: 5px;

  padding: 25px 40px 75px;

  box-shadow: 0px 3px 6px var(--color-theme-gray);
  border: 1px solid var(--color-theme-cloud);

  box-sizing: border-box;
}

.ChangePassword_button_container__1AgoP {
  display: flex;
  justify-content: space-between;

  width: 370px;
}

.ChangePassword_btn_cancel__EPzpb {
  font-weight: bold !important;
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-silver);
}

.ChangePassword_btn_confirm__3IAXi {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.ChangePassword_changePasswordHeader___VC3z {
  font-size: 22px;
  margin: 0 !important;
  height: 30px;
  color: var(--color-font-2);
  font-weight: bold;
  text-transform: uppercase;
}

.ChangePassword_succsse_message__34DfE {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: -1px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-green);
}

.ChangeName_container__Sw8JT {
  width: 450px;
  height: 425px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  position: absolute;
  top: 92px;
  right: 5px;

  padding: 25px 40px 75px;

  box-shadow: 0px 3px 6px var(--color-theme-gray);
  border: 1px solid var(--color-theme-cloud);

  box-sizing: border-box;
}

/* p {
  font-size: 22px;
  margin: 0 !important;
} */

.ChangeName_button_container__1zK_2 {
  justify-content: space-between;
  width: 370px;
  display: flex;
}

.ChangeName_btn_cancel__LE2e- {
  font-weight: bold !important;
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-silver);
}

.ChangeName_btn_confirm__3GoO8 {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.ChangeName_changeNameHeader__UmkAD {
  font-size: 22px !important;
  height: 30px;
  color: var(--color-font-2);
  font-weight: bold;
  text-transform: uppercase;
}

.ChangeName_email__1f8Hr {
  font-size: 16px !important;
  color: var(--color-font-2);
}

.ChangeName_succsse_message__1NExa {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: -1px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-green);
}

.ChangeName_error_message__bSWJr {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: -1px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-red);
}

.ChangeSignature_container__3X4DA {
  width: 550px;
  height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  top: 92px;
  right: 10px;

  padding: 0px 20px;

  box-shadow: 0px 3px 6px var(--color-theme-gray);
  border: 1px solid var(--color-theme-cloud);

  box-sizing: border-box;
  overflow-y: scroll;
}

.ChangeSignature_signatre_body__f6VWy {
  height: 90%;
  min-height: 470px;
  overflow-y: scroll;
}

.ChangeSignature_button_container__1wrhc {
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.ChangeSignature_btn_cancel__3vEuh {
  font-weight: bold !important;
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-silver);
}

.ChangeSignature_btn_confirm__LzMNV {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.ChangeSignature_changeNameHeader__-_YpT {
  font-size: 22px;
  color: var(--color-font-2);
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.ChangeSignature_describtion__2YlBC {
  text-align: left;
}

.ChangeSignature_email__2a0K- {
  font-size: 16px !important;
  color: var(--color-font-2);
}

.ChangeSignature_succsse_message__1az_d {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: 0px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-green);
}

.ChangeSignature_error_message__1DZ6n {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: 0px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-red);
}

.sun-editor {
  width: auto;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #dadada;
  background-color: #fff;
  color: #000;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.sun-editor * {
  box-sizing: border-box;
  -webkit-user-drag: none;
  overflow: visible;
}
.sun-editor-common button,
.sun-editor-common input,
.sun-editor-common select,
.sun-editor-common textarea {
  font-size: 14px;
  line-height: 1.5;
}
.sun-editor-common blockquote,
.sun-editor-common body,
.sun-editor-common button,
.sun-editor-common code,
.sun-editor-common dd,
.sun-editor-common div,
.sun-editor-common dl,
.sun-editor-common dt,
.sun-editor-common fieldset,
.sun-editor-common form,
.sun-editor-common h1,
.sun-editor-common h2,
.sun-editor-common h3,
.sun-editor-common h4,
.sun-editor-common h5,
.sun-editor-common h6,
.sun-editor-common input,
.sun-editor-common legend,
.sun-editor-common li,
.sun-editor-common ol,
.sun-editor-common p,
.sun-editor-common pre,
.sun-editor-common select,
.sun-editor-common td,
.sun-editor-common textarea,
.sun-editor-common th,
.sun-editor-common ul {
  margin: 0;
  padding: 0;
  border: 0;
}
.sun-editor-common dl,
.sun-editor-common li,
.sun-editor-common menu,
.sun-editor-common ol,
.sun-editor-common ul {
  list-style: none !important;
}
.sun-editor-common hr {
  margin: 6px 0 !important;
}
.sun-editor textarea {
  resize: none;
  border: 0;
  padding: 0;
}
.sun-editor button {
  border: 0;
  background-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
  outline: none;
}
.sun-editor button,
.sun-editor input,
.sun-editor select,
.sun-editor textarea {
  vertical-align: middle;
}
.sun-editor button span {
  display: block;
  margin: 0;
  padding: 0;
}
.sun-editor button .txt {
  display: block;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sun-editor button * {
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.sun-editor svg {
  fill: currentColor;
}
.sun-editor .se-svg,
.sun-editor button > svg {
  width: 16px;
  height: 16px;
  margin: auto;
  fill: currentColor;
  display: block;
  text-align: center;
  float: none;
}
.sun-editor .close > svg,
.sun-editor .se-dialog-close > svg {
  width: 10px;
  height: 10px;
}
.sun-editor .se-btn-select > svg {
  float: right;
  width: 10px;
  height: 10px;
}
.sun-editor .se-btn-list > .se-list-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -1px 10px 0 0;
  vertical-align: middle;
}
.sun-editor .se-line-breaker > button > svg {
  width: 24px;
  height: 24px;
}
.sun-editor button > i:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 15px;
  line-height: 2;
}
.sun-editor button > [class="se-icon-text"] {
  font-size: 20px;
  line-height: 1;
}
.sun-editor .se-arrow,
.sun-editor .se-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 11px solid transparent;
}
.sun-editor .se-arrow.se-arrow-up {
  top: -11px;
  left: 20px;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #dadada;
}
.sun-editor .se-arrow.se-arrow-up:after {
  top: 1px;
  margin-left: -11px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}
.sun-editor .se-toolbar .se-arrow.se-arrow-up:after {
  border-bottom-color: #fafafa;
}
.sun-editor .se-arrow.se-arrow-down {
  top: 0;
  left: 0;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #dadada;
}
.sun-editor .se-arrow.se-arrow-down:after {
  top: -12px;
  margin-left: -11px;
  content: " ";
  border-bottom-width: 0;
  border-top-color: #fff;
}
.sun-editor .se-toolbar .se-arrow.se-arrow-down:after {
  border-top-color: #fafafa;
}
.sun-editor .se-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.sun-editor button {
  color: #000;
}
.sun-editor .se-btn {
  float: left;
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 4px;
  margin: 1px !important;
  padding: 0;
  font-size: 12px;
  line-height: 27px;
}
.sun-editor .se-btn:enabled:focus,
.sun-editor .se-btn:enabled:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-btn:enabled:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-btn-primary {
  color: #000;
  background-color: #c7deff;
  border: 1px solid #80bdff;
  border-radius: 4px;
}
.sun-editor .se-btn-primary:focus,
.sun-editor .se-btn-primary:hover {
  color: #000;
  background-color: #80bdff;
  border-color: #3f9dff;
  outline: 0 none;
}
.sun-editor .se-btn-primary:active {
  color: #fff;
  background-color: #3f9dff;
  border-color: #4592ff;
  box-shadow: inset 0 3px 5px #4592ff;
}
.sun-editor input,
.sun-editor select,
.sun-editor textarea {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.sun-editor input:focus,
.sun-editor select:focus,
.sun-editor textarea:focus {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7deff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sun-editor .se-btn:enabled.active {
  color: #4592ff;
  outline: 0 none;
}
.sun-editor .se-btn:enabled.active:focus,
.sun-editor .se-btn:enabled.active:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-btn:enabled.active:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-btn:enabled.on {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-btn:enabled.on:focus,
.sun-editor .se-btn:enabled.on:hover {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}
.sun-editor .se-btn:enabled.on:active {
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  box-shadow: inset 0 3px 5px #b1b1b1;
}
.sun-editor .se-btn-list:disabled,
.sun-editor .se-btn:disabled,
.sun-editor button:disabled {
  cursor: not-allowed;
  background-color: inherit;
  color: #bdbdbd;
}
.sun-editor .se-loading-box {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 2147483647;
}
.sun-editor .se-loading-box .se-loading-effect {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 25px;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner 0.8s linear infinite;
          animation: spinner 0.8s linear infinite;
  margin: -25px 0 0 -25px;
}
.sun-editor .se-line-breaker {
  position: absolute;
  display: none;
  width: 100%;
  height: 1px;
  cursor: text;
  border-top: 1px solid #3288ff;
  z-index: 7;
}
.sun-editor .se-line-breaker > button.se-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  top: -15px;
  float: none;
  left: -50%;
  background-color: #fff;
  border: 1px solid #0c2240;
  opacity: 0.6;
  cursor: pointer;
}
.sun-editor .se-line-breaker > button.se-btn:hover {
  opacity: 0.9;
  background-color: #fff;
  border-color: #041b39;
}
.sun-editor .se-line-breaker-component {
  position: absolute;
  display: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #0c2240;
  opacity: 0.6;
  border-radius: 4px;
  cursor: pointer;
  z-index: 7;
}
.sun-editor .se-line-breaker-component:hover {
  opacity: 0.9;
}
.sun-editor .se-toolbar {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  outline: 1px solid #dadada;
  z-index: 5;
}
.sun-editor .se-toolbar-shadow {
  display: block !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  z-index: 0 !important;
}
.sun-editor .se-toolbar-cover {
  position: absolute;
  display: none;
  font-size: 36px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fefefe;
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: not-allowed;
  z-index: 4;
}
.sun-editor .se-toolbar-separator-vertical {
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0;
  vertical-align: top;
}
.sun-editor .se-toolbar.se-toolbar-balloon,
.sun-editor .se-toolbar.se-toolbar-inline {
  display: none;
  position: absolute;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.sun-editor .se-toolbar.se-toolbar-balloon {
  z-index: 2147483647;
  width: auto;
}
.sun-editor .se-toolbar.se-toolbar-sticky {
  position: fixed;
  top: 0;
}
.sun-editor .se-toolbar-sticky-dummy {
  display: none;
  position: static;
  z-index: -1;
}
.sun-editor .se-btn-module {
  display: inline-block;
}
.sun-editor .se-btn-module-border {
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-left: 1px;
  margin-right: 1px;
}
.sun-editor .se-btn-module-enter {
  display: block;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.sun-editor .se-toolbar-more-layer {
  margin: 0 -3px;
  background-color: #fafafa;
}
.sun-editor .se-toolbar-more-layer .se-more-layer {
  display: none;
  border-top: 1px solid #dadada;
}
.sun-editor .se-toolbar-more-layer .se-more-layer .se-more-form {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 4px 3px 0;
}
.sun-editor .se-btn-module .se-btn-more.se-btn-more-text {
  width: auto;
  padding: 0 4px;
}
.sun-editor .se-btn-module .se-btn-more:focus,
.sun-editor .se-btn-module .se-btn-more:hover {
  color: #000;
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}
.sun-editor .se-btn-module .se-btn-more.on {
  color: #333;
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}
.sun-editor .se-btn-module .se-btn-more.on:hover {
  color: #000;
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  outline: 0 none;
}
.sun-editor .se-menu-list,
.sun-editor .se-menu-list li {
  float: left;
  padding: 0;
  margin: 0;
}
.sun-editor .se-menu-list li {
  position: relative;
}
.sun-editor .se-btn-select {
  width: auto;
  display: flex;
  padding: 4px 6px;
}
.sun-editor .se-btn-select .txt {
  flex: auto;
  text-align: left;
}
.sun-editor .se-btn-select.se-btn-tool-font {
  width: 100px;
}
.sun-editor .se-btn-select.se-btn-tool-format {
  width: 82px;
}
.sun-editor .se-btn-select.se-btn-tool-size {
  width: 78px;
}
.sun-editor .se-btn-tray {
  position: relative;
  width: 100%;
  height: auto;
  padding: 4px 3px 0;
  margin: 0;
}
.sun-editor .se-menu-tray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
}
.sun-editor .se-submenu {
  overflow-x: hidden;
  overflow-y: auto;
}
.sun-editor .se-menu-container {
  overflow-x: unset;
  overflow-y: unset;
}
.sun-editor .se-list-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 5;
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 6px 0;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
}
.sun-editor .se-list-layer .se-list-inner {
  padding: 0;
  margin: 0;
  overflow-x: initial;
  overflow-y: initial;
  overflow: visible;
}
.sun-editor .se-list-layer button {
  margin: 0;
  width: 100%;
}
.sun-editor .se-list-inner ul {
  width: 100%;
  padding: 0;
}
.sun-editor .se-list-inner li > button {
  min-width: 100%;
  width: -webkit-max-content;
  width: max-content;
}
.sun-editor .se-list-inner .se-list-basic li {
  width: 100%;
}
.sun-editor .se-list-inner .se-list-basic li button.active {
  background-color: #80bdff;
  border: 1px solid #3f9dff;
  border-left: 0;
  border-right: 0;
}
.sun-editor .se-list-inner .se-list-basic li button.active:hover {
  background-color: #3f9dff;
  border: 1px solid #4592ff;
  border-left: 0;
  border-right: 0;
}
.sun-editor .se-list-inner .se-list-basic li button.active:active {
  background-color: #4592ff;
  border: 1px solid #407dd1;
  border-left: 0;
  border-right: 0;
  box-shadow: inset 0 3px 5px #407dd1;
}
.sun-editor .se-list-inner .se-list-checked li button > .se-svg {
  float: left;
  padding: 6px 6px 0 0;
}
.sun-editor .se-list-inner .se-list-checked li button > .se-svg > svg {
  display: none;
}
.sun-editor .se-list-inner .se-list-checked li button.se-checked {
  color: #4592ff;
}
.sun-editor
  .se-list-inner
  .se-list-checked
  li
  button.se-checked
  > .se-svg
  > svg {
  display: block;
}
.sun-editor .se-btn-list {
  width: 100%;
  height: auto;
  min-height: 32px;
  padding: 0 14px;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  text-indent: 0;
  text-decoration: none;
  text-align: left;
}
.sun-editor .se-btn-list.default_value {
  background-color: #f3f3f3;
  border-top: 1px dotted #b1b1b1;
  border-bottom: 1px dotted #b1b1b1;
}
.sun-editor .se-btn-list:focus,
.sun-editor .se-btn-list:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-btn-list:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-list-layer.se-list-font-size {
  min-width: 140px;
  max-height: 300px;
}
.sun-editor .se-list-layer.se-list-font-family {
  min-width: 156px;
}
.sun-editor .se-list-layer.se-list-font-family .default {
  border-bottom: 1px solid #ccc;
}
.sun-editor .se-list-layer.se-list-line {
  width: 125px;
}
.sun-editor .se-list-layer.se-list-line hr {
  border-width: 1px 0 0;
  height: 1px;
}
.sun-editor .se-list-layer.se-list-align .se-list-inner {
  left: 9px;
}
.sun-editor .se-list-layer.se-list-format {
  min-width: 156px;
}
.sun-editor .se-list-layer.se-list-format li {
  padding: 0;
  width: 100%;
}
.sun-editor .se-list-layer.se-list-format ul .se-btn-list {
  line-height: 100%;
}
.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value="h1"] {
  height: 40px;
}
.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value="h2"] {
  height: 34px;
}
.sun-editor .se-list-layer.se-list-format ul p {
  font-size: 13px;
}
.sun-editor .se-list-layer.se-list-format ul div {
  font-size: 13px;
  padding: 4px 2px;
}
.sun-editor .se-list-layer.se-list-format ul h1 {
  font-size: 2em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul h2 {
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul h3 {
  font-size: 1.17em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul h4 {
  font-size: 1em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul h5 {
  font-size: 0.83em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul h6 {
  font-size: 0.67em;
  font-weight: 700;
  color: #333;
}
.sun-editor .se-list-layer.se-list-format ul blockquote {
  font-size: 13px;
  color: #999;
  height: 22px;
  margin: 0;
  background-color: transparent;
  line-height: 1.5;
  border-color: #b1b1b1;
  padding: 0 0 0 7px;
  border-left: 5px #b1b1b1;
  border-style: solid;
}
.sun-editor .se-list-layer.se-list-format ul pre {
  font-size: 13px;
  color: #666;
  padding: 4px 11px;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.sun-editor .se-selector-table {
  display: none;
  position: absolute;
  top: 34px;
  left: 1px;
  z-index: 5;
  padding: 5px 0;
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.sun-editor .se-selector-table .se-table-size {
  font-size: 18px;
  padding: 0 5px;
}
.sun-editor .se-selector-table .se-table-size-picker {
  position: absolute !important;
  z-index: 3;
  font-size: 18px;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.sun-editor .se-selector-table .se-table-size-highlighted {
  position: absolute !important;
  z-index: 2;
  font-size: 18px;
  width: 1em;
  height: 1em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAADJmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4QTZCNzMzN0I3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4QTZCNzMzNkI3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOCAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MzYyNEUxRUI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MzYyNEUxRkI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl0yAuwAAABBSURBVDhPY/wPBAxUAGCDGvdBeWSAeicIDTfIXREiQArYeR9hEBOEohyMGkQYjBpEGAxjg6ib+yFMygCVvMbAAABj0hwMTNeKJwAAAABJRU5ErkJggg==")
    repeat;
}
.sun-editor .se-selector-table .se-table-size-unhighlighted {
  position: relative !important;
  z-index: 1;
  font-size: 18px;
  width: 10em;
  height: 10em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}
.sun-editor .se-selector-table .se-table-size-display {
  padding-left: 5px;
}
.sun-editor .se-list-layer.se-table-split {
  top: 36px;
}
.sun-editor .se-list-layer .se-selector-color {
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  max-width: 270px;
  height: auto;
  padding: 0;
  margin: auto;
}
.sun-editor .se-list-layer .se-selector-color .se-color-pallet {
  width: 100%;
  height: 100%;
  padding: 0;
}
.sun-editor .se-list-layer .se-selector-color .se-color-pallet li {
  display: flex;
  float: left;
  position: relative;
  margin: 0;
}
.sun-editor .se-list-layer .se-selector-color .se-color-pallet button {
  display: block;
  cursor: default;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
}
.sun-editor .se-list-layer .se-selector-color .se-color-pallet button.active,
.sun-editor .se-list-layer .se-selector-color .se-color-pallet button:focus,
.sun-editor .se-list-layer .se-selector-color .se-color-pallet button:hover {
  border: 3px solid #fff;
}
.sun-editor .se-form-group {
  display: flex;
  width: 100%;
  min-height: 40px;
  height: auto;
  padding: 4px;
}
.sun-editor .se-form-group input {
  flex: auto;
  display: inline-block;
  width: auto;
  height: 33px;
  font-size: 12px;
  margin: 1px 0;
  padding: 0;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}
.sun-editor .se-form-group button,
.sun-editor .se-submenu-form-group button {
  float: right;
  width: 34px;
  height: 34px;
  margin: 0 2px !important;
}
.sun-editor .se-form-group button.se-btn {
  border: 1px solid #ccc;
}
.sun-editor .se-form-group > div {
  position: relative;
}
.sun-editor .se-form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.sun-editor .se-form-group-label {
  width: 100%;
  height: auto;
  padding: 0 4px;
}
.sun-editor .se-form-group-label label {
  font-size: 13px;
  font-weight: 700;
}
.sun-editor .se-submenu .se-form-group input {
  width: auto;
  height: 33px;
  color: #555;
}
.sun-editor .se-submenu .se-form-group .se-color-input {
  width: 72px;
  text-transform: uppercase;
  border: none;
  border-bottom: 2px solid #b1b1b1;
  outline: none;
}
.sun-editor .se-submenu .se-form-group .se-color-input:focus {
  border-bottom: 3px solid #b1b1b1;
}
.sun-editor .se-wrapper {
  position: relative !important;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 1;
}
.sun-editor .se-wrapper .se-wrapper-inner {
  width: 100%;
  height: 100%;
  min-height: 65px;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}
.sun-editor .se-wrapper .se-wrapper-inner:focus {
  outline: none;
}
.sun-editor .se-wrapper .se-wrapper-code {
  background-color: #191919;
  color: #fff;
  font-size: 13px;
  word-break: break-all;
  padding: 4px;
  margin: 0;
  resize: none !important;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block;
}
.sun-editor .se-wrapper .se-wrapper-code-mirror {
  font-size: 13px;
}
.sun-editor .se-wrapper .se-placeholder {
  position: absolute;
  display: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
  color: #b1b1b1;
  font-size: 13px;
  line-height: 1.5;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin-top: 0;
  padding-top: 16px;
  padding-left: 16px;
  margin-left: 0;
  padding-right: 16px;
  margin-right: 0;
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.sun-editor .se-resizing-bar {
  display: flex;
  width: auto;
  height: auto;
  min-height: 16px;
  border-top: 1px solid #dadada;
  padding: 0 4px;
  background-color: #fafafa;
  cursor: ns-resize;
}
.sun-editor .se-resizing-bar.se-resizing-none {
  cursor: default;
}
.sun-editor .se-resizing-back {
  position: absolute;
  display: none;
  cursor: default;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}
.sun-editor .se-resizing-bar .se-navigation {
  flex: auto;
  position: relative;
  width: auto;
  height: auto;
  color: #666;
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.5;
  background: transparent;
}
.sun-editor .se-resizing-bar .se-char-counter-wrapper {
  flex: none;
  position: relative;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  color: #999;
  font-size: 13px;
  background: transparent;
}
.sun-editor .se-resizing-bar .se-char-counter-wrapper.se-blink {
  color: #b94a48;
  -webkit-animation: blinker 0.2s linear infinite;
          animation: blinker 0.2s linear infinite;
}
.sun-editor .se-resizing-bar .se-char-counter-wrapper .se-char-label {
  margin-right: 4px;
}
.sun-editor .se-dialog {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}
.sun-editor .se-dialog button,
.sun-editor .se-dialog input,
.sun-editor .se-dialog label {
  font-size: 14px;
  line-height: 1.5;
  color: #111;
  margin: 0;
}
.sun-editor .se-dialog .se-dialog-back {
  background-color: #222;
  opacity: 0.5;
}
.sun-editor .se-dialog .se-dialog-back,
.sun-editor .se-dialog .se-dialog-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  position: relative;
  width: auto;
  max-width: 500px;
  margin: 1.75rem auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 509px) {
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    width: 100%;
  }
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
  display: inline-block;
  padding: 6px 12px;
  margin: 0 0 10px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  border-radius: 4px;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header {
  height: 50px;
  padding: 6px 15px;
  border-bottom: 1px solid #e5e5e5;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
  float: right;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: none;
  filter: alpha(opacity=100);
  opacity: 1;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 2.5;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-body {
  position: relative;
  padding: 15px 15px 5px;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form {
  margin-bottom: 10px;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer {
  margin-top: 10px;
  margin-bottom: 0;
}
.sun-editor .se-dialog .se-dialog-inner input:disabled {
  background-color: #f3f3f3;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text {
  width: 100%;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-h,
.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-w {
  width: 70px;
  text-align: center;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-x {
  margin: 0 8px;
  width: 25px;
  text-align: center;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
  height: auto;
  min-height: 55px;
  padding: 10px 15px 0;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div {
  float: left;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
  margin: 0 5px 0 0;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-radio {
  margin-left: 12px;
  margin-right: 6px;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-check {
  margin-left: 12px;
  margin-right: 4px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form-footer
  .se-dialog-btn-check {
  margin-left: 0;
  margin-right: 4px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form-footer
  label:first-child {
  margin-right: 16px;
  margin-left: 0;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files {
  position: relative;
  display: flex;
  align-items: center;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  > input {
  flex: auto;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button {
  flex: auto;
  opacity: 0.8;
  border: 1px solid #ccc;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button.se-file-remove
  > svg {
  width: 8px;
  height: 8px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button:hover {
  background-color: #f0f0f0;
  outline: 0 none;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  .se-dialog-files-edge-button:active {
  background-color: #e9e9e9;
  box-shadow: inset 0 3px 5px #d6d6d6;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-select {
  display: inline-block;
  width: auto;
  height: 34px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857143;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
  display: inline-block;
  width: 70px;
  height: 34px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857143;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 0 4px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-input-form.se-input-url {
  direction: ltr;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-input-form.se-input-url:disabled {
  text-decoration: line-through;
  color: #999;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-video-ratio {
  width: 70px;
  margin-left: 4px;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form a {
  color: #004cff;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
  border: 1px solid #ccc;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-dialog-tabs {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid #e5e5e5;
}
.sun-editor .se-dialog-tabs button {
  background-color: #e5e5e5;
  border-right: 1px solid #e5e5e5;
  float: left;
  outline: none;
  padding: 2px 13px;
  transition: 0.3s;
}
.sun-editor .se-dialog-tabs button:hover {
  background-color: #fff;
}
.sun-editor .se-dialog-tabs button.active {
  background-color: #fff;
  border-bottom: 0;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-input-form.se-math-exp {
  resize: vertical;
  height: 14em;
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 4px;
  direction: ltr;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-input-select.se-math-size {
  width: 6em;
  height: 28px;
  margin-left: 1em;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview {
  font-size: 13px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-math-preview
  > span {
  display: inline-block;
  box-shadow: 0 0 0 0.1rem #c7deff;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-math-preview
  > span
  * {
  direction: ltr;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-math-preview
  > .se-math-katex-error {
  color: #b94a48;
  box-shadow: 0 0 0 0.1rem #f2dede;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-math-preview
  > .se-math-katex-error
  svg {
  width: auto;
  height: 30px;
  color: #b94a48;
}
.sun-editor .se-dialog .se-dialog-inner .se-link-preview {
  display: block;
  height: auto;
  max-height: 18px;
  font-size: 13px;
  font-weight: 400;
  font-family: inherit;
  color: #666;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre;
}
.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form {
  width: 100%;
  display: flex;
  margin-top: 4px;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-anchor-preview-form
  .se-svg.se-anchor-preview-icon {
  flex: unset;
  display: none;
  line-height: 1.5;
  color: #4592ff;
}
.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-anchor-preview-form
  .se-link-preview {
  flex: auto;
  margin: 0;
}
.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel {
  height: 34px;
}
.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-btn {
  width: 46px;
  color: #3f9dff;
}
.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-wrapper {
  display: flex;
  line-height: 1.5;
  padding-top: 6px;
}
.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-preview {
  text-align: left;
}
.sun-editor .se-controller .se-arrow.se-arrow-up {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.sun-editor .se-controller {
  position: absolute;
  display: none;
  overflow: visible;
  z-index: 6;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}
.sun-editor .se-controller .se-btn-group {
  position: relative;
  display: flex;
  vertical-align: middle;
  padding: 2px;
  top: 0;
  left: 0;
}
.sun-editor .se-controller .se-btn-group .se-btn-group-sub {
  left: 50%;
  min-width: auto;
  width: -webkit-max-content;
  width: max-content;
  display: none;
}
.sun-editor .se-controller .se-btn-group .se-btn-group-sub button {
  margin: 0;
  min-width: 72px;
}
.sun-editor .se-controller .se-btn-group button {
  position: relative;
  min-height: 34px;
  height: auto;
  border: none;
  border-radius: 4px;
  margin: 1px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
}
.sun-editor .se-controller .se-btn-group button:focus:enabled,
.sun-editor .se-controller .se-btn-group button:hover:enabled {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-controller .se-btn-group button:active:enabled {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-controller .se-btn-group button span {
  display: block;
  padding: 0;
  margin: 0;
}
.sun-editor .se-controller .se-btn-group button:enabled.active {
  color: #4592ff;
  outline: 0 none;
}
.sun-editor .se-controller .se-btn-group button:enabled.active:focus,
.sun-editor .se-controller .se-btn-group button:enabled.active:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-controller .se-btn-group button:enabled.active:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}
.sun-editor .se-controller .se-btn-group button:enabled.on {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}
.sun-editor .se-controller .se-btn-group button:enabled.on:focus,
.sun-editor .se-controller .se-btn-group button:enabled.on:hover {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}
.sun-editor .se-controller .se-btn-group button:enabled.on:active {
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  box-shadow: inset 0 3px 5px #b1b1b1;
}
.sun-editor .se-controller .se-form-group input {
  min-width: 120px;
}
.sun-editor .se-controller-resizing {
  margin-top: -50px !important;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
}
.sun-editor
  .se-controller-resizing
  .se-btn-group
  .se-btn-group-sub.se-resizing-align-list {
  width: 74px;
}
.sun-editor .se-resizing-container {
  position: absolute;
  display: none;
  outline: 1px solid #3f9dff;
  background-color: transparent;
}
.sun-editor .se-resizing-container .se-modal-resize {
  position: absolute;
  display: inline-block;
  background-color: #3f9dff;
  opacity: 0.3;
}
.sun-editor .se-resizing-container .se-resize-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sun-editor .se-resizing-container .se-resize-dot > span {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #3f9dff;
  border: 1px solid #4592ff;
}
.sun-editor .se-resizing-container .se-resize-dot > span.tl {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.tr {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.bl {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.br {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.lw {
  left: -7px;
  bottom: 50%;
  cursor: w-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.th {
  left: 50%;
  top: -7px;
  cursor: n-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.rw {
  right: -7px;
  bottom: 50%;
  cursor: e-resize;
}
.sun-editor .se-resizing-container .se-resize-dot > span.bh {
  right: 50%;
  bottom: -7px;
  cursor: s-resize;
}
.sun-editor .se-resizing-container .se-resize-display {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
}
.sun-editor .se-controller-table,
.sun-editor .se-controller-table-cell {
  width: auto;
}
.sun-editor .se-controller-link,
.sun-editor .se-controller-table,
.sun-editor .se-controller-table-cell {
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
}
.sun-editor .se-controller-link:after,
.sun-editor .se-controller-link:before {
  box-sizing: border-box;
}
.sun-editor .se-controller-link .link-content {
  padding: 0;
  margin: 0;
}
.sun-editor .se-controller-link .link-content a {
  display: inline-block;
  color: #4592ff;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 5px;
}
.sun-editor .se-select-list {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: auto;
  max-width: 100%;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border: 1px solid #bababa;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
}
.sun-editor .se-select-list .se-select-item {
  line-height: 28px;
  min-height: 28px;
  font-size: 13px;
  padding: 0 5px;
  margin: 2px 0;
  cursor: pointer;
}
.sun-editor .se-select-list.__se_select-menu-mouse-move .se-select-item:hover,
.sun-editor
  .se-select-list:not(.__se_select-menu-mouse-move)
  .se-select-item.active {
  background-color: #e1e1e1;
}
.sun-editor .se-dialog-form-files .se-select-list {
  width: 100%;
}
.sun-editor .se-file-browser {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}
.sun-editor .se-file-browser button,
.sun-editor .se-file-browser input,
.sun-editor .se-file-browser label {
  font-size: 14px;
  line-height: 1.5;
  color: #111;
  margin: 0;
}
.sun-editor .se-file-browser .se-file-browser-back {
  background-color: #222;
  opacity: 0.5;
}
.sun-editor .se-file-browser .se-file-browser-back,
.sun-editor .se-file-browser .se-file-browser-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
  position: relative;
  width: 960px;
  max-width: 100%;
  margin: 20px auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.sun-editor .se-file-browser .se-file-browser-header {
  height: auto;
  min-height: 50px;
  padding: 6px 15px;
  border-bottom: 1px solid #e5e5e5;
}
.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-close {
  float: right;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: none;
  filter: alpha(opacity=100);
  opacity: 1;
}
.sun-editor
  .se-file-browser
  .se-file-browser-header
  .se-file-browser-close
  > svg {
  width: 12px;
  height: 12px;
}
.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 2.2;
}
.sun-editor .se-file-browser .se-file-browser-tags {
  display: block;
  width: 100%;
  padding: 0;
  text-align: left;
  margin: 0 -15px;
}
.sun-editor .se-file-browser .se-file-browser-tags a {
  display: inline-block;
  background-color: #f5f5f5;
  padding: 6px 12px;
  margin: 8px 0 8px 8px;
  color: #333;
  text-decoration: none;
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  cursor: pointer;
}
.sun-editor .se-file-browser .se-file-browser-tags a:hover {
  background-color: #e1e1e1;
}
.sun-editor .se-file-browser .se-file-browser-tags a:active {
  background-color: #d1d1d1;
}
.sun-editor .se-file-browser .se-file-browser-tags a.on {
  background-color: #ebf3fe;
  color: #4592ff;
}
.sun-editor .se-file-browser .se-file-browser-tags a.on:hover {
  background-color: #d8e8fe;
}
.sun-editor .se-file-browser .se-file-browser-tags a.on:active {
  background-color: #c7deff;
}
.sun-editor .se-file-browser .se-file-browser-body {
  position: relative;
  height: auto;
  min-height: 350px;
  padding: 20px;
  overflow-y: auto;
}
.sun-editor .se-file-browser .se-file-browser-body .se-file-browser-list {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
    width: 748px;
  }
}
@media screen and (max-width: 768px) {
  .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
    width: 600px;
  }
}
.sun-editor .se-file-browser .se-file-browser-list .se-file-item-column {
  position: relative;
  display: block;
  height: auto;
  float: left;
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-column {
  width: calc(25% - 20px);
  margin: 0 10px;
}
@media screen and (max-width: 992px) {
  .sun-editor
    .se-file-browser
    .se-file-browser-list.se-image-list
    .se-file-item-column {
    width: calc(33% - 20px);
  }
}
@media screen and (max-width: 768px) {
  .sun-editor
    .se-file-browser
    .se-file-browser-list.se-image-list
    .se-file-item-column {
    width: calc(50% - 20px);
  }
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-img {
  position: relative;
  display: block;
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 4px;
  outline: 0;
  margin: 10px 0;
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-img:hover {
  opacity: 0.8;
  box-shadow: 0 0 0 0.2rem #3288ff;
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-img
  > img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  outline: 0;
  height: auto;
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-img
  > .se-file-img-name {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  color: #fff;
  left: 0;
  bottom: 0;
  padding: 5px 10px;
  background-color: transparent;
  width: 100%;
  height: 30px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.sun-editor
  .se-file-browser
  .se-file-browser-list.se-image-list
  .se-file-item-img
  > .se-file-img-name.se-file-name-back {
  background-color: #333;
  opacity: 0.6;
}
.sun-editor .se-notice {
  position: absolute;
  top: 0;
  display: none;
  z-index: 7;
  width: 100%;
  height: auto;
  word-break: break-all;
  font-size: 13px;
  color: #b94a48;
  background-color: #f2dede;
  padding: 15px;
  margin: 0;
  border: 1px solid #eed3d7;
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}
.sun-editor .se-notice button {
  float: right;
  padding: 7px;
}
.sun-editor .se-tooltip {
  position: relative;
  overflow: visible;
}
.sun-editor .se-tooltip .se-tooltip-inner {
  visibility: hidden;
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  top: 120%;
  left: 50%;
  background: transparent;
  opacity: 0;
  z-index: 1;
  line-height: 1.5;
  transition: opacity 0.5s;
  margin: 0;
  padding: 0;
  bottom: auto;
  float: none;
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  left: -50%;
  font-size: 0.9em;
  margin: 0;
  padding: 4px 6px;
  border-radius: 2px;
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: unset;
  white-space: nowrap;
  cursor: auto;
}
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-bottom-color: #333;
}
.sun-editor .se-tooltip:hover .se-tooltip-inner {
  visibility: visible;
  opacity: 1;
}
.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
  display: block !important;
}
.sun-editor
  .se-tooltip
  .se-tooltip-inner
  .se-tooltip-text
  .se-shortcut
  > .se-shortcut-key {
  display: inline;
  font-weight: 700;
}
.sun-editor.se-rtl .se-btn-tray {
  direction: rtl;
}
.sun-editor.se-rtl .se-btn-select svg {
  margin: auto 1px;
}
.sun-editor.se-rtl .se-btn-select .txt {
  flex: auto;
  text-align: right;
  direction: rtl;
}
.sun-editor.se-rtl .se-btn-list {
  text-align: right;
}
.sun-editor.se-rtl .se-btn-list > .se-list-icon {
  margin: -1px 0 0 10px;
}
.sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix),
.sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix) li {
  float: right;
}
.sun-editor.se-rtl .se-list-layer * {
  direction: rtl;
}
.sun-editor.se-rtl .se-list-layer.se-list-format ul blockquote {
  padding: 0 7px 0 0;
  border-right-width: 5px;
  border-left-width: 0;
}
.sun-editor.se-rtl .se-list-layer .se-selector-color .se-color-pallet li {
  float: right;
}
.sun-editor.se-rtl .se-list-inner .se-list-checked li button > .se-svg {
  float: right;
  padding: 6px 0 0 6px;
}
.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text,
.sun-editor.se-rtl .se-wrapper .se-placeholder {
  direction: rtl;
}
.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
  direction: ltr;
}
.sun-editor.se-rtl .se-dialog * {
  direction: rtl;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-form .se-video-ratio {
  margin-left: 0;
  margin-right: 4px;
}
.sun-editor.se-rtl
  .se-dialog
  .se-dialog-inner
  .se-dialog-header
  .se-dialog-close {
  float: left;
}
.sun-editor.se-rtl .se-dialog-tabs button,
.sun-editor.se-rtl
  .se-dialog
  .se-dialog-inner
  .se-dialog-header
  .se-modal-title {
  float: right;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-size-text {
  padding-right: 34px;
}
.sun-editor.se-rtl
  .se-dialog
  .se-dialog-inner
  .se-dialog-footer
  .se-btn-primary {
  float: left;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div {
  float: right;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
  margin: 0 0 0 5px;
}
.sun-editor.se-rtl
  .se-dialog
  .se-dialog-inner
  .se-dialog-form-footer
  label:first-child {
  margin-left: 16px;
  margin-right: 0;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-preview {
  margin-left: 4px;
  text-align: right;
}
.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-btn {
  float: right;
}
.sun-editor.se-rtl .se-file-browser * {
  direction: rtl;
}
.sun-editor.se-rtl .se-file-browser .se-file-browser-tags {
  text-align: right;
}
.sun-editor.se-rtl .se-file-browser .se-file-browser-tags a {
  margin: 8px 8px 0;
}
.sun-editor.se-rtl
  .se-file-browser
  .se-file-browser-header
  .se-file-browser-close {
  float: left;
}
.sun-editor.se-rtl .se-controller .se-btn-group,
.sun-editor.se-rtl .se-resizing-container .se-resize-display {
  direction: rtl;
}
.sun-editor .se-btn-module-border.module-float-left {
  float: left;
}
.sun-editor .se-btn-module-border.module-float-right {
  float: right;
}
.sun-editor .se-error {
  color: #d9534f;
}
.sun-editor input.se-error:focus,
select.se-error:focus,
textarea.se-error:focus {
  border: 1px solid #f2dede;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #eed3d7;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sun-editor hr.__se__solid {
  border-style: solid none none;
}
.sun-editor hr.__se__dotted {
  border-style: dotted none none;
}
.sun-editor hr.__se__dashed {
  border-style: dashed none none;
}
@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(361deg);
            transform: rotate(361deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotate(361deg);
            transform: rotate(361deg);
  }
}
.sun-editor-editable {
  font-size: 13px;
  color: #333;
  background-color: #fff;
  line-height: 1.5;
  word-break: normal;
  word-wrap: break-word;
  padding: 16px;
  margin: 0;
}
.sun-editor-editable * {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}
.sun-editor-editable.se-rtl * {
  direction: rtl;
}
.sun-editor-editable .se-component > figure {
  direction: ltr;
}
.sun-editor-editable audio,
.sun-editor-editable figcaption,
.sun-editor-editable figure,
.sun-editor-editable iframe,
.sun-editor-editable img,
.sun-editor-editable td,
.sun-editor-editable th,
.sun-editor-editable video {
  position: relative;
}
.sun-editor-editable span {
  display: inline;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
.sun-editor-editable span.katex {
  display: inline-block;
}
.sun-editor-editable span.katex * {
  direction: ltr;
}
.sun-editor-editable a {
  color: #004cff;
  text-decoration: none;
}
.sun-editor-editable span[style~="color:"] a {
  color: inherit;
}
.sun-editor-editable a:focus,
.sun-editor-editable a:hover {
  cursor: pointer;
  color: #0093ff;
  text-decoration: underline;
}
.sun-editor-editable a.on {
  color: #0093ff;
  background-color: #e8f7ff;
}
.sun-editor-editable pre {
  display: block;
  padding: 8px;
  margin: 0 0 10px;
  font-family: monospace;
  color: #666;
  line-height: 1.45;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  white-space: pre-wrap !important;
  word-wrap: break-word;
  overflow: visible;
}
.sun-editor-editable ol {
  list-style-type: decimal;
}
.sun-editor-editable ol,
.sun-editor-editable ul {
  list-style-position: outside;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}
.sun-editor-editable ul {
  list-style-type: disc;
}
.sun-editor-editable li {
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 5px;
}
.sun-editor-editable ol ol,
.sun-editor-editable ol ul,
.sun-editor-editable ul ol,
.sun-editor-editable ul ul {
  margin: 0;
}
.sun-editor-editable ol ol,
.sun-editor-editable ul ol {
  list-style-type: lower-alpha;
}
.sun-editor-editable ol ol ol,
.sun-editor-editable ul ol ol,
.sun-editor-editable ul ul ol {
  list-style-type: upper-roman;
}
.sun-editor-editable ol ul,
.sun-editor-editable ul ul {
  list-style-type: circle;
}
.sun-editor-editable ol ol ul,
.sun-editor-editable ol ul ul,
.sun-editor-editable ul ul ul {
  list-style-type: square;
}
.sun-editor-editable sub,
.sun-editor-editable sup {
  font-size: 75%;
  line-height: 0;
}
.sun-editor-editable sub {
  vertical-align: sub;
}
.sun-editor-editable sup {
  vertical-align: super;
}
.sun-editor-editable p {
  display: block;
  margin: 0 0 10px;
}
.sun-editor-editable div {
  display: block;
  margin: 0;
  padding: 0;
}
.sun-editor-editable blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #b1b1b1;
  border-width: 0 0 0 5px;
}
.sun-editor-editable blockquote blockquote {
  border-color: #c1c1c1;
}
.sun-editor-editable blockquote blockquote blockquote {
  border-color: #d1d1d1;
}
.sun-editor-editable blockquote blockquote blockquote blockquote {
  border-color: #e1e1e1;
}
.sun-editor-editable.se-rtl blockquote {
  padding-left: 5px;
  padding-right: 20px;
  border-left-width: 0;
  border-right-width: 5px;
}
.sun-editor-editable h1 {
  font-size: 2em;
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
}
.sun-editor-editable h1,
.sun-editor-editable h2 {
  display: block;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  font-weight: 700;
}
.sun-editor-editable h2 {
  font-size: 1.5em;
  -webkit-margin-before: 0.83em;
          margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
          margin-block-end: 0.83em;
}
.sun-editor-editable h3 {
  font-size: 1.17em;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}
.sun-editor-editable h3,
.sun-editor-editable h4 {
  display: block;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  font-weight: 700;
}
.sun-editor-editable h4 {
  font-size: 1em;
  -webkit-margin-before: 1.33em;
          margin-block-start: 1.33em;
  -webkit-margin-after: 1.33em;
          margin-block-end: 1.33em;
}
.sun-editor-editable h5 {
  font-size: 0.83em;
  -webkit-margin-before: 1.67em;
          margin-block-start: 1.67em;
  -webkit-margin-after: 1.67em;
          margin-block-end: 1.67em;
}
.sun-editor-editable h5,
.sun-editor-editable h6 {
  display: block;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  font-weight: 700;
}
.sun-editor-editable h6 {
  font-size: 0.67em;
  -webkit-margin-before: 2.33em;
          margin-block-start: 2.33em;
  -webkit-margin-after: 2.33em;
          margin-block-end: 2.33em;
}
.sun-editor-editable hr {
  display: flex;
  border-width: 1px 0 0;
  border-color: #000;
  border-image: initial;
  height: 1px;
}
.sun-editor-editable hr.__se__solid {
  border-style: solid none none;
}
.sun-editor-editable hr.__se__dotted {
  border-style: dotted none none;
}
.sun-editor-editable hr.__se__dashed {
  border-style: dashed none none;
}
.sun-editor-editable hr.on {
  border-color: #4592ff;
  box-shadow: 0 0 0 0.1rem #c7deff;
}

.sun-editor-editable table td.se-table-selected-cell,
.sun-editor-editable table th.se-table-selected-cell {
  outline: 1px double #4592ff;
}
.sun-editor-editable.se-disabled * {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.sun-editor-editable .se-component {
  display: flex;
  padding: 1px;
  margin: 0 0 10px;
}
.sun-editor-editable[contenteditable="true"] .se-component {
  outline: 1px dashed #e1e1e1;
}
.sun-editor-editable[contenteditable="true"] .se-component.se-component-copy {
  box-shadow: 0 0 0 0.2rem #3f9dff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sun-editor-editable .__se__float-left {
  float: left;
  margin-right: 4px;
}
.sun-editor-editable .__se__float-right {
  float: right;
  margin-left: 4px;
}
.sun-editor-editable .__se__float-center {
  float: center;
}
.sun-editor-editable .__se__float-none {
  float: none;
}
.sun-editor-editable audio,
.sun-editor-editable iframe,
.sun-editor-editable img,
.sun-editor-editable video {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  max-width: 100%;
}
.sun-editor-editable[contenteditable="true"]:not(.se-read-only) figure:after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  display: block;
  background: transparent;
}
.sun-editor-editable[contenteditable="true"] figure a,
.sun-editor-editable[contenteditable="true"] figure iframe,
.sun-editor-editable[contenteditable="true"] figure img,
.sun-editor-editable[contenteditable="true"] figure video {
  z-index: 0;
}
.sun-editor-editable[contenteditable="true"] figure figcaption {
  display: block;
  z-index: 2;
}
.sun-editor-editable[contenteditable="true"] figure figcaption:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7deff;
}
.sun-editor-editable .se-image-container,
.sun-editor-editable .se-video-container {
  width: auto;
  height: auto;
  max-width: 100%;
}
.sun-editor-editable figure {
  display: block;
  outline: none;
  padding: 0;
  margin: 0;
}
.sun-editor-editable .__se__float-center figure,
.sun-editor-editable .__se__float-left figure,
.sun-editor-editable .__se__float-right figure {
  margin: auto !important;
}
.sun-editor-editable figure figcaption {
  padding: 1em 0.5em;
  margin: 0;
  background-color: #f9f9f9;
  outline: none;
}
.sun-editor-editable figure figcaption p {
  line-height: 2;
  margin: 0;
}
.sun-editor-editable .se-image-container a img {
  padding: 1px;
  margin: 1px;
  outline: 1px solid #4592ff;
}
.sun-editor-editable .se-video-container iframe,
.sun-editor-editable .se-video-container video {
  outline: 1px solid #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
.sun-editor-editable .se-video-container figure {
  left: 0;
  width: 100%;
  max-width: 100%;
}
.sun-editor-editable audio {
  width: 300px;
  height: 54px;
}
.sun-editor-editable audio.active {
  outline: 2px solid #80bdff;
}
.sun-editor-editable.se-show-block div,
.sun-editor-editable.se-show-block h1,
.sun-editor-editable.se-show-block h2,
.sun-editor-editable.se-show-block h3,
.sun-editor-editable.se-show-block h4,
.sun-editor-editable.se-show-block h5,
.sun-editor-editable.se-show-block h6,
.sun-editor-editable.se-show-block li,
.sun-editor-editable.se-show-block ol,
.sun-editor-editable.se-show-block p,
.sun-editor-editable.se-show-block pre,
.sun-editor-editable.se-show-block ul {
  border: 1px dashed #3f9dff !important;
  padding: 14px 8px 8px !important;
}
.sun-editor-editable.se-show-block ol,
.sun-editor-editable.se-show-block ul {
  border: 1px dashed #d539ff !important;
}
.sun-editor-editable.se-show-block pre {
  border: 1px dashed #27c022 !important;
}
.se-show-block p {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPAQMAAAAF7dc0AAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAaSURBVAjXY/j/gwGCPvxg+F4BQiAGDP1HQQByxxw0gqOzIwAAAABJRU5ErkJggg==")
    no-repeat;
}
.se-show-block div {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPAQMAAAAxlBYoAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j//wcDDH+8XsHwDYi/hwNx1A8w/nYLKH4XoQYJAwCXnSgcl2MOPgAAAABJRU5ErkJggg==")
    no-repeat;
}
.se-show-block h1 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAfSURBVAjXY/j/v4EBhr+9B+LzEPrDeygfhI8j1CBhAEhmJGY4Rf6uAAAAAElFTkSuQmCC")
    no-repeat;
}
.se-show-block h2 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j/v4EBhr+dB+LtQPy9geEDEH97D8T3gbgdoQYJAwA51iPuD2haEAAAAABJRU5ErkJggg==")
    no-repeat;
}
.se-show-block h3 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQPy9geHDeQgN5p9HqEHCADeWI+69VG2MAAAAAElFTkSuQmCC")
    no-repeat;
}
.se-show-block h4 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPAQMAAADTSA1RAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j//wADDH97DsTXIfjDdiDdDMTfIRhZHRQDAKJOJ6L+K3y7AAAAAElFTkSuQmCC")
    no-repeat;
}
.se-show-block h5 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAlSURBVAjXY/j/v4EBhr+1A/F+IO5vYPiwHUh/B2IQfR6hBgkDABlWIy5uM+9GAAAAAElFTkSuQmCC")
    no-repeat;
}
.se-show-block h6 {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQLy/geFDP5S9HSKOrA6KAR9GIza1ptJnAAAAAElFTkSuQmCC")
    no-repeat;
}
.se-show-block li {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA7SURBVDhPYxgFcNDQ0PAfykQBIHEYhgoRB/BpwCfHBKWpBkaggYxQGgOgBzyQD1aLLA4TGwWDGjAwAACR3RcEU9Ui+wAAAABJRU5ErkJggg==")
    no-repeat;
}
.se-show-block ol {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABHSURBVDhPYxgFcNDQ0PAfhKFcFIBLHCdA1oBNM0kGEmMAPgOZoDTVANUNxAqQvURMECADRiiNAWCagDSGGhyW4DRrMAEGBgAu0SX6WpGgjAAAAABJRU5ErkJggg==")
    no-repeat;
}
.se-show-block ul {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVDhPYxgFDA0NDf+hTBSALI5LDQgwQWmqgVEDKQcsUBoF4ItFGEBXA+QzQpmDGjAwAAA8DQ4Lni6gdAAAAABJRU5ErkJggg==")
    no-repeat;
}
.sun-editor-editable .__se__p-bordered,
.sun-editor .__se__p-bordered {
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  padding: 4px 0;
}
.sun-editor-editable .__se__p-spaced,
.sun-editor .__se__p-spaced {
  letter-spacing: 1px;
}
.sun-editor-editable .__se__p-neon,
.sun-editor .__se__p-neon {
  font-weight: 200;
  font-style: italic;
  background: #000;
  color: #fff;
  padding: 6px 4px;
  border: 2px solid #fff;
  border-radius: 6px;
  text-transform: uppercase;
  -webkit-animation: neonFlicker 1.5s infinite alternate;
          animation: neonFlicker 1.5s infinite alternate;
}
@-webkit-keyframes neonFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  to {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2px #f40, 0 0 4px #f40, 0 0 6px #f40, 0 0 8px #f40, 0 0 10px #f40;
    box-shadow: 0 0 0.5px #fff, inset 0 0 0.5px #fff, 0 0 2px #08f,
      inset 0 0 2px #08f, 0 0 4px #08f, inset 0 0 4px #08f;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}
@keyframes neonFlicker {
  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  to {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
      0 0 2px #f40, 0 0 4px #f40, 0 0 6px #f40, 0 0 8px #f40, 0 0 10px #f40;
    box-shadow: 0 0 0.5px #fff, inset 0 0 0.5px #fff, 0 0 2px #08f,
      inset 0 0 2px #08f, 0 0 4px #08f, inset 0 0 4px #08f;
  }
  20%,
  24%,
  55% {
    text-shadow: none;
    box-shadow: none;
  }
}
.sun-editor-editable .__se__t-shadow,
.sun-editor .__se__t-shadow {
  text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff,
    0 0 0.2rem #999, 0 0 0.4rem #888, 0 0 0.6rem #777, 0 0 0.8rem #666,
    0 0 1rem #555;
}
.sun-editor-editable .__se__t-code,
.sun-editor .__se__t-code {
  font-family: monospace;
  color: #666;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 6px;
  padding: 0.2em 0.4em;
}

.Profile_user_container__YDbCE {
  flex: 1 1;
  cursor: pointer;
}

.Profile_user_name__3b_4T {
  padding-right: 25px;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.6px;
}

.Profile_user_icon__1x7jP {
  margin-right: 25px;
  -webkit-transform: scale(1.41);
          transform: scale(1.41);
  color: var(--color-theme-blue);
}

.Profile_right_container__2QfFn {
  flex: 1 1;
  text-align: right;
  min-width: 400px;
  display: flex;
  overflow-y: scroll;
}

.Profile_MuiButtonBaseRoot__1n92a {
  text-transform: "none";
  border-radius: 0 !important;
  height: 89px;
  width: 340px;
}

.Profile_MuiButtonBaseRoot__1n92a:hover {
  background-color: var(--color-theme-lightblue) !important;
}

.Profile_menu__r12zv {
  overflow-y: scroll;
}

.DialogPopup_paper__1dYa6 {
  min-width: 450px;
  max-width: 650px !important;
  overflow: visible;
  min-height: 100px;
  background-color: var(--color-bg-popup) !important;
  color: var(--color-font-10) !important;
}

.DialogPopup_paper_big__1aYQc {
  min-width: 1000px;
  max-width: 1000px !important;
  overflow: visible;
  min-height: 100px;
  background-color: var(--color-bg-popup) !important;
  color: var(--color-font-10) !important;
}

.DialogPopup_paper__1dYa6::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.DialogPopup_paper__1dYa6::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}
.DialogPopup_paper_big__1aYQc::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.DialogPopup_paper_big__1aYQc::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.DialogPopup_popup_header__4kxpp {
  text-transform: uppercase;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--color-bg-popup-header);
}

.DialogPopup_popup_content__NX0Ku {
  padding: 15px;
}

.TosPopup_tos_title__2eM6b {
  padding-bottom: 35px;
  font-weight: 300;
  font-size: 15px;
}

.TosPopup_tosContentContainer__1Pt6c {
  width: 850px;
  height: 500px;
  padding: 10px;
  background-color: var(--color-theme-white);
  color: var(--color-font-0);
  overflow-y: scroll;
}

.TosPopup_iframe__1DBmi {
  width: 100%;
  height: 100%;
  color: var(--color-font-0);
  overflow-y: scroll;
  border: none;
}

.Layout_content__126Oe {
  background-color: var(--color-bg-content);
  min-width: 1200px;
  margin: 91px 0 40px 210px;
  padding: 0px 0px;
}

.Layout_content_no_user__27yv2 {
  background-color: var(--color-bg-content);
  min-width: 1200px;
  margin: 91px 0 40px;
  padding: 0px 0px;
}


/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

p {
  font-family: "Roboto";
  line-height: 1.6;
}

div {
  font-family: "Roboto";
}

h2 {
  font-family: "Roboto";
}

.Cases_root__2ncMA {
  width: 100%;
}

.Cases_hide_case_list__1xPKH {
  display: none;
}

.Cases_case_list_container__2Kl-i {
  position: fixed;
  left: 210px;
  top: 110px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  padding-top: 60px;
  overflow-x: hidden;
  height: calc(100vh - 103px);
}

.Cases_case_details_container__2O88S {
  overflow: scroll;
  margin-left: 310px;
  background-color: var(--color-bg-content);
  overflow: scroll;
  height: calc(100vh - 107px);
  padding-bottom: 38px;
}

.Cases_add_case_container__-iQiU {
  z-index: 2;
  position: fixed;
  left: 210px;
  top: 91px;
  width: 310px;
  background-color: var(--color-bg-caselist);
}

.Cases_filter_container__3Mk8l {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.Cases_filter_counter__2TdDr {
  margin-right: 20px;
}

.Cases_btn_add_case__1UhjO {
  flex: 2 1;
  width: 160px !important;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
  line-height: 0px !important;
  border-radius: 5px !important;
  margin: 10px !important;
  margin-bottom: 0px !important;
}

.Cases_btn_add_case__1UhjO:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.Cases_plus_sign__1Z-6A {
  margin-right: 10px;
  font-size: 20px;
}

.Cases_case_row_unfolded__321tU {
  overflow: hidden;
  transition: max-height 0.3s ease-in;
  max-height: 90px;
}

.Cases_case_row_folded___VygY {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
}

.Cases_filterIconActive__1Zlj7 {
  position: relative !important;
  height: 30px !important;
  margin-left: 14px !important;
  color: rgb(75, 75, 75) !important;
  cursor: pointer !important;
  min-width: 20px !important;
}

.Cases_filterMenuPaper__22syE {
  width: 218px;
  padding: 6px 6px;
  position: absolute;
  text-align: left;
  z-index: 2;
}

.Cases_filterMenuRow__1gZiO {
  padding: 12px 2px;
  cursor: pointer;
}

.Cases_filterMenuRow__1gZiO:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.Cases_add_case_button__2p0wI {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Cases_add_case_button__2p0wI > button {
  background-color: white;
}

.CaseRow_container__1OoUV {
  margin-bottom: 10px;
}

.CaseRow_root_plus_7__1G6Xp {
  display: flex;
  color: var(--color-theme-10);
  height: 52px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;

}
.CaseRow_root__3OfLU {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;
}

.CaseRow_root_inactive__2cHje {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.CaseRow_root_active__3j_A0 {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.CaseRow_root_inactive__2cHje:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.CaseRow_left_container__3JKeG {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.CaseRow_status_date__1GlxT {
  font-size: 10px;
  padding-top: 5px;
}

.CaseRow_middle_container__2rsAw {
  width: 210px;
}

.CaseRow_host_value__FC4pY {
  width: 220px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.CaseRow_price_value_container__2G1Z1 {
  width: 220px;
  display: block;
  padding-top: 5px;
  font-weight: bold;
  text-align: right;
}

.CaseRow_price_value__4ik53 {
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
  width: 125px;
}

.CaseRow_icon__1_E4g {
  margin: 0 5px;
}
.CaseRow_warning_icon__cKBZq {
  color: var(--color-theme-yellow);
}

.CaseRow_progress_bar_root__25x6G {
  height: 5px !important;
  background-color: var(--color-bg-caselist-item) !important;
  border: 1px solid var(--color-theme-bright-gray) !important;
  transition: none !important;
}

.CaseRow_progress_bar_bar__qbVWj {
  background-color: var(--color-theme-blue) !important;
  transition: none !important;
}

.CaseRow_progress_bar_bar_gray__2A6zi {
  background: linear-gradient(
    45deg,
    #c7c7c7 25%,
    #2680eb 25%,
    #2680eb 50%,
    #c7c7c7 50%,
    #c7c7c7 75%,
    #2680eb 75%,
    #2680eb 100%
  ) !important;
  background-size: 15.57px 17.57px !important;
  transition: none !important;
}

.CaseRow_total_value__1oRTO {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
  margin-top: -7px;
}

.CaseRow_right_container__1pqC0 {
  width: 60px;
  margin-left: -15px;
  min-width: 60px;
}

.CaseRow_right_icon_container__3c4sD {
  text-align: center;
}

.CaseRow_callback_icon__2_1_0 {
  color: var(--color-font-red);
}

.CaseRow_callback_date__1qZW2 {
  font-size: 10px;
  padding-top: 5px;
}

.CaseRow_right_icon_container__3c4sD {
  text-align: center;
}

.CaseRow_view_date__DYX-s {
  font-size: 10px;
  padding-top: 5px;
}

.CaseRow_empty__7DEbQ {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.CaseRow_license_icon_container___w5vw {
  display: inline-block;
  width: 45px;
  text-align: right;
}

.CaseRow_license_icon__3HYHo {
  color: var(--color-theme-blue);
}

.CaseRow_license_icon__3HYHo svg {
  height: 16px;
}

.CaseRow_host_url__3LEQQ {
  display: inline-block;
  width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CaseRow_curr_sign__uQ7no {
  font-size: 10px;
}

.CaseRow_unread_marker__2E62F {
  border-radius: 10px;
  width: 7px;
  /* z-index: 20; */
  height: 7px;
  background-color: royalblue;
  position: relative;
  top: -29px;
  left: 25px;
  margin-bottom: -7px;
}

.CaseRow_email_marker_alone__1VQNT {
}

.UpgradePlanpopup_upgrade_image__OjEAt {
  width: 110% !important;
  height: 344px;
  padding: 0;
}

.UpgradePlanpopup_subscription_image__17Wwj {
  height: 300px;
  width: 90%;
}
.UpgradePlanpopup_content_container__3dog4 {
  font-size: 22px !important;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  text-align: center;
}

.UpgradePlanpopup_MuiButtonBaseRoot__2KLbs {
  text-transform: 'none';
  border-radius: 0 !important;
  height: 89px;
  width: 340px;
}

.UpgradePlanpopup_MuiButtonBaseRoot__2KLbs:hover {
  background-color: var(--color-theme-lightblue) !important;
}

.UpgradePlanpopup_bold_text__wZvg- {
  font-size: 22px !important;
  font-weight: 600;
}

.UpgradePlanpopup_regular_text__3QzwB {
  font-size: 22px !important;
}

.CaseTemplates_root__253HF {
    width: 100%;
    color: var(--color-font-10);
    overflow: scroll;
    height: calc(100vh - 133px);
    overflow-y: scroll;
  }
  
  .CaseTemplates_template_list_container__8xWyo {
    position: fixed;
    left: 210px;
    top: 91px;
    width: 290px;
    background-color: var(--color-bg-caselist);
    overflow: scroll;
    padding: 10px;
    overflow-x: hidden;
    height: calc(100vh - 140px);
    z-index: 100;
  }
  
  /* add flow row  */
  .CaseTemplates_email_flow_input__1bC-O {
    background-color: var(--color-bg-caselist-item);
    outline: none;
    border: none;
    color: var(--color-font-10);
    font-weight: bold;
    font-size: 16px;

    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    align-self: center;
  }
  
  .CaseTemplates_email_row_root__1dee6 {
    margin-top: 10px;
    display: flex;
    color: var(--color-theme-10);
    height: 45px;
    cursor: pointer;
    align-items: center;
    padding: 5px;
  
    background-color: var(--color-bg-caselist-item);
    border: 2px solid var(--color-bg-caselist-item);
  }
  
  .CaseTemplates_email_row_root__1dee6:hover {
    background-color: var(--color-bg-caselist-item-hover);
    border: 2px solid var(--color-bg-caselist-item-hover);
  }
  
  .CaseTemplates_account_tree_icon__2Iv_1 {
    margin: 0px 10px;
  }
  /* template details */
  .CaseTemplates_template_container__37zHX {
    overflow: hidden;
    margin-left: 310px;
    background-color: var(--color-bg-content);
  }
  
  .CaseTemplates_template_no_data__3U6qF {
    text-align: center;
    margin-top: 300px;
    text-transform: uppercase;
    font-size: 24px;
    color: var(--color-theme-silver);
  }
  
  .CaseTemplates_add_template_container__3Xat3{
    display: flex;
    justify-content: center;
  }
.CaseTemplateRow_edit_popup__3k8YJ {
  z-index: 8000;
}

.CaseTemplateRow_edit_popup_container__11xu9 {
  position: fixed !important;
  left: 210px;
  top: 91px;
  width: 290px;
}

/* email row */
.CaseTemplateRow_email_row_root__3pKrD {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.CaseTemplateRow_email_row_root__3pKrD:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.CaseTemplateRow_root_inactive__1rAva {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.CaseTemplateRow_root_active__3hykt {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.CaseTemplateRow_email_flow_input__g05RK {
  background-color: var(--color-bg-caselist-item);
  outline: none;
  border: none;
  color: var(--color-font-10);
  font-weight: bold;
  font-size: 16px;
}

.CaseTemplateRow_account_tree_icon__1lF9t {
  margin: 0px 10px;
}

.CaseTemplateRow_edit_icon_container__OXb5H {
  display: flex;
  justify-content: flex-end;
}

.CaseTemplateRow_edit_icon__R71oI {
  padding: 0px 10px;
}

.CaseTemplateRow_edit_icon__R71oI:hover {
  color: var(--color-font-10);
}

.CaseTemplateRow_input_container__Tj3gj {
  width: 65%;
  margin-left: 30px;
}

.CaseTemplateRow_flow_list_item__uApb3 {
  padding: 0px !important;
}

.CaseTemplateRow_email_row_root__3pKrD:hover .CaseTemplateRow_edit_icon__R71oI {
  color: var(--color-font-10);
}

/* submenu */
.CaseTemplateRow_template_row_root__1-LOp {
  display: flex;
  color: var(--color-theme-10);

  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.CaseTemplateRow_template_row_root__1-LOp:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  color: var(--color-font-10) !important;
}

.CaseTemplateRow_template_row_root_selected__2q6jE {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 2px solid var(--color-bg-caselist-item-selected);
}

.CaseTemplateRow_status_item__ZoHXk {
  height: 45px;
  font-size: 12px;
}

.CaseTemplateRow_status_icon__2ElF- {
  margin: 0px 10px 0px 15px;
}

.CaseTemplateRow_status_icon__2ElF- svg {
  font-size: 20px !important;
}

.CaseTemplateRow_status_title__3wbo4 {
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.CaseTemplateRow_delete_template__kpaYU {
  width: 100%;
  display: none;
  justify-content: flex-end;
  color: var(--color-theme-cloud);
}

.CaseTemplateRow_add_template_title__38Qsx {
  color: var(--color-theme-silver);
}

.CaseTemplateRow_add_template_icon__3Fu5m {
  color: var(--color-theme-silver);
}

.CaseTemplateRow_template_row_root__1-LOp:hover .CaseTemplateRow_add_template_icon__3Fu5m {
  color: var(--color-font-10);
}

.CaseTemplateRow_template_row_root__1-LOp:hover .CaseTemplateRow_add_template_title__38Qsx {
  color: var(--color-font-10);
}

.CaseTemplateRow_template_row_root__1-LOp:hover .CaseTemplateRow_delete_template__kpaYU {
  display: flex;
  color: var(--color-theme-cloud);
}

.CaseTemplateRow_template_row_root_selected__2q6jE .CaseTemplateRow_delete_template__kpaYU {
  display: flex;
  color: var(--color-theme-cloud);
}

.CaseTemplateRow_delete_icon__3fcU5:hover {
  color: var(--color-theme-blue);
}

.CaseTemplateRow_deletionPopupText__1Fi6X {
  padding: 5px;
}

.CaseTemplateRow_button_confirm__Su2iP {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.CaseTemplateRow_button_create__1WJz9 {
  width: 200px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.CaseTemplateRow_deletionPopupButtonContainer__1DmTX {
  display: flex;
  margin-top: 36px;
  flex-direction: row-reverse;
}

.CaseTemplateEditor_root__3R1Zg {
  width: 850px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.CaseTemplateEditor_confirm_btn__2AiOU {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.CaseTemplateEditor_confirm_btn_container__39itX {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  min-width: 450px;
  width: 100%;
}

.CaseTemplateEditor_confirm_btn_container_bottom__1oF1z{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  min-width: 450px;
  width: 100%;
  margin-top: 15px;
}

.CaseTemplateEditor_currencyRow__2n-Gb {
  min-width: 450px;
  max-width: 650px;
}

.CaseTemplateEditor_feeRow__1Cuas {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  border: 1px solid DARKGRAY;
  border-radius: 5px;
  padding: 0px 0px 0px 7px;
  margin-top: 10px;
}

.CaseTemplateEditor_feeRowTextInput__2FQb4 {
  margin-right: 32px !important;
}

.CaseTemplateEditor_mt40__239Md{
  margin-top: 40px;
}
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
  .ps__rail-x {
    height: 15px;
    bottom: 0;
  }
  .ps__rail-x,
  .ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    position: absolute;
  }
  .ps__rail-y {
    width: 15px;
    right: 0;
  }
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
  }
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y,
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y {
    opacity: 0.6;
  }
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y.ps--clicking,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-y:hover {
    background-color: #eee;
    opacity: 0.9;
  }
  .ps__thumb-x {
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 6px;
    bottom: 2px;
  }
  .ps__thumb-x,
  .ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    position: absolute;
    min-height: 50px;
  }
  .ps__thumb-y {
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 6px;
    right: 2px;
  }
  .ps__rail-x.ps--clicking .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x {
    background-color: #999;
    height: 11px;
  }
  .ps__rail-y.ps--clicking .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y {
    background-color: #999;
    width: 11px;
  }
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto !important;
    }
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .ps {
      overflow: auto !important;
    }
  }
  .list-dk-scroll-view .ps__thumb-x {
    bottom: 0;
  }
  .list-dk-scroll-view .ps__rail-y {
    left: auto !important;
  }
  .list-dk-scroll-view .ps__rail-x,
  .list-dk-scroll-view .ps__rail-y {
    z-index: 6 !important;
  }
  .list-dk-scroll-view.perfect-list {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .list-dk-scroll-view.perfect-list .scroll-content {
    position: relative;
    display: block;
    width: 100%;
    height: inherit;
    max-height: inherit;
  }
  .list-dk-scroll-view.perfect-list .scroll-content.scroll-always > .ps__rail-x,
  .list-dk-scroll-view.perfect-list .scroll-content.scroll-always > .ps__rail-y {
    opacity: 0.65 !important;
  }
  .list-dk-scroll-view.perfect-list .scroll-content.scroll-wheel-stop {
    overscroll-behavior: contain;
  }
  .list-dk-scroll-view.perfect-list.ps {
    border: 0 !important;
  }
  .list-dk-scroll-view.perfect-list.ps.scroll-always > .ps__rail-x,
  .list-dk-scroll-view.perfect-list.ps.scroll-always > .ps__rail-y {
    opacity: 0.65 !important;
  }
  .list-dk-scroll-view.perfect-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
  }
  .list-dk-scroll-view.perfect-table thead {
    display: block;
  }
  .list-dk-scroll-view.perfect-table thead tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .list-dk-scroll-view.perfect-table tbody {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .list-dk-scroll-view.perfect-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .list-dk-scroll-view.perfect-table tbody.ps {
    border: 0 !important;
  }
  .list-dk-scroll-view.perfect-table tbody.scroll-always > .ps__rail-x,
  .list-dk-scroll-view.perfect-table tbody.scroll-always > .ps__rail-y {
    opacity: 0.65 !important;
  }
  .list-dk-scroll-view.perfect-table tbody.scroll-wheel-stop {
    overscroll-behavior: contain;
  }
  
.CaseDetails_root__Su6cH {
  width: 865px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
}

.CaseDetails_section_container__TfCw7 {
  width: 850px;
  margin-bottom: 5px;
}

.CaseDetails_no_data__15Vwm{
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}

.CaseDetails_top_container__3TN5D{
  width: 850px;
  margin-bottom: 5px;
  display: flex;
}
.ActivateCasePopup_content_container__1FcRh {
  padding: 5px;
}

.ActivateCasePopup_activate_hr__yhurg {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 15px 0px;
  width: 100%;
}

.ActivateCasePopup_price_row__J8pzO {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.ActivateCasePopup_price_container__2maxN {
  width: 170px;
  padding-left: 15px;
}
.ActivateCasePopup_price_label__1zuSW {
  font-size: 12px;
}

.ActivateCasePopup_price_value__2BMXt {
  font-size: 20px;
  padding-top: 3px;
  overflow-wrap: break-word;
}

.ActivateCasePopup_disclaimer__P2JA- {
  font-size: 14px;
  padding: 15px;
  color: var(--color-theme-blue);
  border: 1px solid var(--color-theme-blue);
  text-align: justify;
  margin-bottom: 10px;
}

.ActivateCasePopup_total_container__21g-a {
  margin: 15px 0px;
  padding: 15px;
  background-color: var(--color-theme-snow);
}

.ActivateCasePopup_total_label__1FhzM {
  font-size: 12px;
}

.ActivateCasePopup_total_value___oVae {
  font-size: 20px;
  font-weight: bold;
}

.ActivateCasePopup_btn_additional_fees__EtHtn {
  flex: 2 1;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
  max-width: 300px !important;
  line-height: 0px !important;
}

.ActivateCasePopup_findings_container__1hbR2 {
  display: block;
}

.ActivateCasePopup_finding_container__1g7PH {
  display: flex;
  align-items: flex-start;
}

.ActivateCasePopup_finging_image_container__6FOsZ {
  width: 70px;
}

.ActivateCasePopup_finging_image__28GjY {
  height: 55px;
  padding-top: 15px;
  padding-left: 5px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.ActivateCasePopup_finging_details_container__2-69s {
  width: 280px;
  padding: 6px 20px;
}

.ActivateCasePopup_finding_id__1Vq4y {
  font-size: 12px;
  max-width: 200px;
  overflow-x: scroll;
}

.ActivateCasePopup_finding_link__mhr5y {
  text-decoration: none;
  color: var(--color-font-10);
}

.ActivateCasePopup_finging_price_container__SQtjE {
  width: 120px;
  padding-top: 15px;
}

.ActivateCasePopup_currency__1L-3l {
  font-size: 16px;
}

.ActivateCasePopup_error_container__6vCI3 {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.ActivateCasePopup_error_text__mIXBD {
  font-size: 16px;
  color: var(--color-theme-white);
}

.StripeInfoPopup_content_container__rdAbg {
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.StripeInfoPopup_info_container__2ztUd {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.StripeInfoPopup_info_icon__2-gtN {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-blue);
}

.StripeInfoPopup_info_icon_text__3QSg- {
  color: var(--color-theme-blue);
  font-weight: bold;
}

.StripeInfoPopup_text_container__17SjL {
  text-align: center;
}

.StripeInfoPopup_confirm_btn__3G38R {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin-top: 30px !important;
}

.StripeInfoPopup_confirm_btn__3G38R a {
  text-decoration: none;
  color: var(--color-theme-blue);
}

.StripeInfoPopup_confirm_btn__3G38R:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.StripeInfoPopup_confirm_btn__3G38R:hover a {
  color: var(--color-theme-white) !important;
}

.ContactInfoPopup_content_container__2XPSg {
  display: flex;
  justify-content: space-between;
}

.ContactInfoPopup_MuiOutlinedInput-notchedOutline__3xuaK {
  background-color: var(--color-theme-silver) !important;
}

.ContactInfoPopup_btn_copy_data__2jg-f {
  flex: 2 1;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  min-width: 350px !important;
  line-height: 0px !important;
}

.ContactInfoPopup_contact_container__18nlo {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.ContactInfoPopup_TwoInputs___o5SY {
  display: flex;
  justify-content: space-between;
}
.ContactInfoPopup_subInput__3wP3O {
  width: 45% !important ;
}

.SelectEmailFlowPopup_error_container__3uYGz {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.SelectEmailFlowPopup_error_text__z1iUB {
  font-size: 16px;
  color: var(--color-theme-white);
}

.SelectTranslationPopup_error_container__6JWW4 {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.SelectTranslationPopup_error_text__3LdpZ {
  font-size: 16px;
  color: var(--color-theme-white);
}

.DateInput_calendar_icon__1C-n1 {
  color: var(--color-theme-10);
}

.ReminderPopup_btn_confirm__AgkdA:disabled {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
}

.ReminderPopup_btn_delete__1n5uj {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-font-black) !important;
  border: 1px solid var(--color-theme-black) !important;
}

.ReminderPopup_btn_delete__1n5uj:disabled {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
}

/* standard for all popups */
.ErrorMessagePopup_message__2bM2Y {
  width: 100%;
}

.ErrorMessagePopup_container__3oF_5 {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

p {
  margin-left: 10px;
}

.RenderPopup_email_content__3hE9M {
  font-size: 18px !important;
}

.RenderPopup_email_content__3hE9M p {
  font-size: 18px !important;
}

p {
  font-size: 18px !important;
}

.RenderPopup_placeholder_missing_none__2Lsf7 {
  display: none;
}

.RenderPopup_btn_confirm__13mY0 {
  width: 200px !important;
}

.RenderPopup_btn_disabled__272Cb {
  font-weight: bold !important;
  width: 200px;
  margin-left: 15px !important;
  color: var(--color-font-10) !important;
  background-color: gray !important;
  padding: 5px 8px !important;
  margin-top: 4px !important;
}

.RenderPopup_reminderNameInput__2VFlN {
  display: "inline-block";
  float: "left";
}

.RenderPopup_send_email_btn_disabled__34GRj {
  height: 80px;
  width: 80px;
  background: var(--color-bg-popup-header);
  border: 1px solid #5d5d5d !important;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #707070 !important;
  margin: 0 8px 8px 0;
}

/* standard for all popups */
.RenderPopup_title__3iCpR {
  display: flex;
}
.RenderPopup_title_icon__1T5c4 {
  margin-right: 5px;
}

.RenderPopup_send_email_btn__3ToN3 {
  height: 80px;
  width: 80px;
  border: 1px solid var(--color-theme-blue) !important;
  background-color: var(--color-theme-white);
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-theme-blue) !important;
  margin: 0 8px 8px 0;
  cursor: pointer;
}

.RenderPopup_send_icon__8OCog {
  margin: 10px;
}

.RenderPopup_send_icon_disabled__2A1_h {
  margin: 10px;
  color: var(--color-theme-cloud) !important;
}

.RenderPopup_emailContentContainer__YKQLN {
  padding: 10px;
  background-color: var(--color-theme-white);
  color: var(--color-font-0);
  max-height: 500px;
  overflow-y: scroll;
  font-size: 18px !important;
}

.RenderPopup_send_email_title__ZnMar {
  font-size: 18px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.RenderPopup_placeholder_missing__3js0g {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  background: var(--color-theme-red);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.RenderPopup_placeholder_message_icon__1iIzi {
  color: var(--color-theme-white);
}

.RenderPopup_placeholder_message__3gcCi {
  margin: 0 0 0 10px;
  color: var(--color-theme-white);
}

.RenderPopup_Mui-disabled__sj21n {
  background-color: gray !important;
  color: white !important;
}

.RenderPopup_loading__2CCW_ {
  width: 80px;
}

.RenderPopup_reminder_container_content__2uNeh {
  float: left;
}

.RenderPopup_attachments_row__aoh5n {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.RenderPopup_menu_item_span__3vxXV {
  margin: 0px 0px;
}

.RenderPopup_attachment_item__3eV9c {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px;
  border: 2px solid #e7e5df;
  background-color: #edebe9;
}

.RenderPopup_attachment_delete_icon__3KZKJ {
  cursor: pointer;
  margin-top: -10px;
  position: relative;
  top: 6px;
}

.RenderPopup_attachment_prefix__2EuMf {
  margin-right: 10px;
  top: 4px;
  position: relative;
}

.AreYouSurePopup_content_container__2o_Se {
  padding-bottom: 20px;
}

.AddressComponent_input_send__1Lb5w {
  width: 100%;
  max-width: 822px;
  padding-left: 10px;
  outline: none;
  background-color: none;
  border: solid 1px #edebe9;
  height: 32px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.AddressComponent_input_copy_paste__X5vc6 {
  width: 100%;
  max-width: 805px;
  padding-left: 10px;
  outline: none;
  background-color: none;
  border: solid 1px #edebe9;
  height: 32px;
  margin-bottom: 8px;

  box-sizing: border-box;
}

.AddressComponent_row_container__3oqpt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.AddressComponent_label__34aZT {
  width: 50px;
  text-align: right;
}

.AddressComponent_email_to_autocomplete__3ZgLT {
  width: 100%;
  max-width: 822px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.AddressComponent_email_to_autocomplete__3ZgLT::before {
  width: 100%;
  max-width: 822px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.AddressComponent_email_to_autocomplete_cp__1kfCX {
  width: 100%;
  max-width: 803px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.AddressComponent_email_to_autocomplete_cp__1kfCX::before {
  width: 100%;
  max-width: 805px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.AddressComponent_mail_chip__DU_Og {
  height: 28px !important;
  margin-left: 4px;
  margin-top: 2px;
}

.AddressComponent_send_email_form__1XC9n {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 15px;
  text-align: right;
}

.AddressComponent_to_and_cc_button_container__3nmY1
{
    display: flex;
    width: 100%;
    max-width: 822px;
    padding-left: 10px;
    flex-direction: row;
    justify-content: flex-end;
}


.AddressComponent_btn_default__1Tbt7 {
    color: var(--color-font-10) !important;
  
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    margin-top: 10px;
    height: 33px;
  }
.InfoPopup_message__rYakX {
  width: 100%;
}

.InfoPopup_container__2YhDz {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

p {
  margin-left: 10px;
}

.ClaimPopup_content_container__2Khaq {
  padding: 5px;
}

.ClaimPopup_claim_hr__1vc9a {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 10px 0px;
  width: 100%;
}

.ClaimPopup_btn_additional_fees__zh6do {
  flex: 2 1;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  max-width: 300px !important;
  min-width: 200px !important;
  line-height: 0px !important;
  border: 1px solid var(--color-font-10) !important;
}

.ClaimPopup_findings_container__iReuq {
  display: block;
}

.ClaimPopup_finding_container__VOEmw {
  display: flex;
  align-items: flex-start;
}

.ClaimPopup_finging_image_container__3e9pB {
  width: 100px;
}

.ClaimPopup_finging_image__wH24E {
  height: 55px;
  padding-top: 15px;
  padding-left: 5px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.ClaimPopup_finging_details_container__2FzqL {
  width: 220px;
  padding: 6px 20px;
}

.ClaimPopup_finding_id__22J0G {
  font-size: 12px;
}

.ClaimPopup_finding_link__DNV_h {
  text-decoration: none;
  color: var(--color-font-10);
}

.ClaimPopup_finging_price_container__14-xq {
  width: 220px;
}

.ClaimPopup_add_fee_container__2Xh7k {
  text-align: right;
}

.ClaimPopup_additional_fees_container__2gbmU {
  display: block;
}

.ClaimPopup_additional_fee_container__2hQls {
  display: flex;
}

.ClaimPopup_other_fees_container__1Y8oa {
  display: block;
}

.ClaimPopup_vat_container__2GNCo {
  display: flex;
  justify-content: space-between;
}

.ClaimPopup_vat_two_inputs__1QFT4 {
  display: flex;
  justify-content: space-between;
  width: 65%;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.ClaimPopup_first_input__1KjZv {
  margin-right: 20px !important;
}

.ClaimPopup_input_small__208TY {
  width: 174px !important;
}

.ClaimPopup_settelment_container__3JlsY {
  display: flex;
  justify-content: space-between;
}

.ClaimPopup_taxable_switch_container__28Tar {
  display: block;
  width: 190px;
  padding-top: 5px;
}

.ClaimPopup_valid_note__2GND9 {
  padding-top: 10px;
  text-align: center;
}

.ClaimPopup_taxable_label__3DZCx {
  font-size: 10px;
  text-align: center;
}

.ClaimPopup_taxable_switch__1mYNW {
  margin-left: 10px !important;
  margin-top: 0px !important;
}

.ClaimPopup_btn_delete_container__3cMER {
  width: 90px;
  padding-top: 35px;
  text-align: left;
  color: var(--color-theme-red);
}

.ClaimPopup_summary_container__3bi4E {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-darkgray);
  padding: 5px 15px;
}

.ClaimPopup_license_container__1PTzS {
  margin-right: 15px;
}

.ClaimPopup_gross_container__1SNgq {
  margin-right: 15px;
}

.ClaimPopup_discount_container__2qUum {
  margin-right: 15px;
}

.ClaimPopup_sumary_label__3LgV- {
  font-size: 12px;
}

.ClaimPopup_sumary_value__9xtCC {
  font-size: 18px;
  font-weight: bold;
}

.ClaimPopup_no_findings__PcBFn {
  text-align: center;
  padding: 20px;
}

.OverviewSection_root__1EJ3T {
  display: block;
}

.OverviewSection_root__1EJ3T hr {
  background-color: var(--color-theme-cloud);
}

.OverviewSection_finding_section__2o0zl {
  display: inline-block;
  min-width: 320px;
  max-width: 320px;
  padding-right: 15px;
}

.OverviewSection_overview_section__2sTK3 {
  display: inline-block;
  min-width: 500px;
  padding-right: 15px;
}

.OverviewSection_activation_section__yRqnl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px 20px;
  background-color: var(--color-theme-white);
  border: 1px solid var(--color-theme-cloud);
}

.OverviewSection_host_container__2-c72 {
  flex-grow: 4;
}

.OverviewSection_details_container__17E4l {
  display: flex;
}

.OverviewSection_status_container__xC-nV {
  min-width: 220px;
}

.OverviewSection_status_box__1rWWf {
  display: flex;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.OverviewSection_status_name__17AUg {
  font-size: 20px;
}

.OverviewSection_status_icon__16OBe {
  margin-right: 10px;
  font-size: 28px;
}

.OverviewSection_status_date__-MoKA {
  font-size: 14px;
  padding-top: 5px;
}

.OverviewSection_btn_container__1ZJ8q {
  margin-top: 5px;
}

.OverviewSection_btn_activate__3Wb78 {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.OverviewSection_btn_view_case__1wwVQ {
  margin-left: 5px !important;
  font-size: 14px !important;
  padding: 0px 6px !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-gray) !important;
  background-color: var(--color-theme-gray) !important;
}
.OverviewSection_view_case_icon__Remd5 {
  margin-left: 5px;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.OverviewSection_btn_activate__3Wb78:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-gray) !important;
  background-color: var(--color-theme-gray) !important;
  height: 40px;
}

.OverviewSection_btn_cancel__12lb9 {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  height: 40px;
}

.OverviewSection_btn_cancel__12lb9:disabled {
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
  background-color: var(--color-theme-gray) !important;
}

.OverviewSection_btn_reset__1yREP {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  background-color: var(--color-theme-white) !important;
  height: 40px;
}

.OverviewSection_btn_payment__2doSH {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
}

.OverviewSection_host__2iOqA {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 350px;
  text-overflow: ellipsis;
}

.OverviewSection_source_id__7V770 {
  font-size: 14px;
  padding-top: 8px;
}

hr {
  height: 1px;
  border: none;
  background-color: var(--color-bg-hr);
  margin: 0;
}

.OverviewSection_contact_container__10uh1 {
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
}

.OverviewSection_email_flow_container__1r5jm {
  padding: 5px 0px 8px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OverviewSection_email_flow_container__1r5jm:hover {
  background-color: var(--color-bg-content-box-hover);
}

.OverviewSection_no_hover__1tBmm {
  background-color: unset !important;
  cursor: default;
}

.OverviewSection_disabled__R1nRp {
  pointer-events: none;
  opacity: 0.6;
}

.OverviewSection_contact_container__10uh1:hover {
  background-color: var(--color-bg-content-box-hover);
}

.OverviewSection_contact_info_box__-1k9m {
  padding: 15px 0;
}

.OverviewSection_contact_small_title__1JJCJ {
  font-size: 14px;
}

.OverviewSection_contact_big_title__1Lkc1 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.OverviewSection_claim_container__1oJUf {
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
}
.OverviewSection_claim_container__1oJUf:hover {
  background-color: var(--color-bg-content-box-hover);
}

.OverviewSection_claim_content__3BPWx {
  display: flex;
}

.OverviewSection_claim_info_box__296Uj {
  padding: 10px 0;
  min-width: 155px;
}

.OverviewSection_claim_small_title__2ufMr {
  font-size: 14px;
}

.OverviewSection_claim_small_title_component__3cYWw {
  font-size: 14px;
  min-width: 210px;
}

.OverviewSection_claim_big_title__6u9iP {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.OverviewSection_deadline_container__1EONH {
  position: relative;
  padding-left: 20px;
}

.OverviewSection_deadline_title_container__33j4s {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OverviewSection_deadline_content__EdHpT {
  position: relative;
  display: flex;
  align-items: center;
}

.OverviewSection_deadline_info_box__1uJtO {
  padding: 10px 0;
  min-width: 155px;
}

.OverviewSection_deadline_small_title__2g8R6 {
  font-size: 14px;
}

.OverviewSection_deadline_small_title_component__umAIq {
  font-size: 14px;
  min-width: 210px;
}

.OverviewSection_deadline_big_title__2bguh {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.OverviewSection_valid_box__zYZlD {
  margin-top: 5px;
  float: right;
}

.OverviewSection_not_valid_box__2YYL4 {
  margin-top: 5px;
  float: right;
}

.OverviewSection_valid_box__zYZlD svg {
  color: var(--color-theme-green);
}

.OverviewSection_not_valid_box__2YYL4 svg {
  color: var(--color-theme-yellow);
}

.OverviewSection_currency__1qYjs {
  font-size: 16px;
}

.OverviewSection_cancel_icon__36Wy8 {
  display: inline-block;
  margin-right: 10px;
  font-size: 34px;
}
.OverviewSection_case_templage_container__1Y3ur {
  text-align: right;
}
.OverviewSection_cancel_icon__36Wy8:hover {
  color: var(--color-theme-red);
}

.OverviewSection_activate_btns_container__2L9zz {
  display: flex;
  cursor: pointer;
}

.OverviewSection_image_list__1hmzV {
  background-color: var(--color-bg-content-evidence);
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 2px solid var(--color-theme-cloud);
  position: relative;
  min-width: 310px;
}

.OverviewSection_image_item__3EGzI {
  height: 395px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.OverviewSection_evidence_container__1tmXT {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--color-bg-content-evidence);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-theme-cloud);
  border-top: none;
}

.OverviewSection_evidence_action__195H_ {
  display: flex;
  padding: 16px;
  height: 0px;
}

.OverviewSection_evidence_text__pTmKt {
  padding: 20px;
  font-size: 14px;
  font-weight: 600;
}

.OverviewSection_finding_count__2NbDd {
  line-height: 22px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}

.OverviewSection_evidence_action__195H_ svg {
  width: 25px;
  height: 25px;
}

.OverviewSection_image_icons_container___iUge {
  position: absolute;
  display: flex;
  bottom: 5px;
  padding: 5px;
  right: 0px;
  width: 100%;
  overflow: scroll;
  justify-content: flex-end;
}

.OverviewSection_image_icon__UUcrN {
  cursor: pointer;
  margin-right: 5px;
  height: 40px;
  width: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--color-theme-10);
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.OverviewSection_image_icon__UUcrN:hover {
  border: 1px solid var(--color-theme-3);
}

.OverviewSection_image_icon_selected__2AgRl {
  cursor: pointer;
  margin-right: 5px;
  height: 40px;
  width: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--color-theme-3);
}

.OverviewSection_no_finding_container__1xjid {
  height: 395px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
  min-width: 330px;
}

.OverviewSection_add_finding_container__2uQyR {
  text-align: right;
  width: 306px;
  background-color: var(--color-bg-caselist);
  margin-right: 10px;
  margin-top: 10px;
}

.OverviewSection_btn_add_finding__1uvxG {
  flex: 2 1;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 40px;
}

.OverviewSection_btn_add_finding_disabled__1SpnF {
  flex: 2 1;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-theme-lightgray) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 40px;
}

.OverviewSection_plus_sign__10Xez {
  margin-right: 10px;
  font-size: 20px;
}

.OverviewSection_btn_add_finding__1uvxG:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.OverviewSection_no_finding_container_with_image__3GhpW {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: var(--color-bg-caselist);
}

.OverviewSection_btn_add_finding_with_image__2kFG_ {
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OverviewSection_template_container__1fPKF {
  color: var(--color-font-10);
  text-decoration: none;
  display: flex;
  padding-top: 4px;
  cursor: pointer;
  align-content: space-between;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.OverviewSection_case_templage_container__1Y3ur {
  width: 100%;
}

.OverviewSection_case_template_title__14f0f {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.OverviewSection_case_template_title__14f0f .OverviewSection_MuiAutocomplete-inputRoot__17tMm {
  height: 55px !important;
}

.OverviewSection_template_icon__2PrTq svg {
  height: 25px;
  padding-top: 0px;
}

.OverviewSection_template_title__2KTZT {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
}

.OverviewSection_warning_icon__3w9qW {
  color: var(--color-theme-yellow);
  margin-left: 10px;
  margin-top: 2px;
}

.OverviewSection_eye_icon__2BHeY {
  color: var(--color-theme-white);
  margin-left: 10px;
  margin-top: 9px;
  cursor: pointer;
}

.OverviewSection_btn_send_email__-V9TP {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
}

.OverviewSection_btn_add_deadline__UD2q8 {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
  position: relative;
  top: 15px;
  z-index: 99;
}

.OverviewSection_template_dropdown__2Y63a {
  min-width: 250px !important;
}

.OverviewSection_template_dropdown_last__2j8mo {
  min-width: 250px !important;
  border-top: 1px solid var(--color-border) !important;
}

.OverviewSection_title__kANRW {
  font-size: 16px;
  padding-top: 10px;
  font-weight: bold;
}

.OverviewSection_tag_chip__3Z1Ro {
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 300px;
}

.OverviewSection_tags_container__1Ltir {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.FindingsPopup_finding_hr__jXRnX {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 10px 0px;
  width: 100%;
}

.FindingsPopup_btn_delete__3ls-K {
  width: 100px !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  font-weight: bold !important;
}

.FindingsPopup_first_input__Vtc8p {
  margin-right: 20px !important;
}

.FindingsPopup_contact_container__1nurt {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.FindingsPopup_screenshot_section_container__17fOM {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-darkgray);
  padding: 15px;
}

.FindingsPopup_imprint_image_container__24ava {
  width: 140px;
}

.FindingsPopup_imprint_image__3WgTP {
  height: 80px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.FindingsPopup_imprint_text_container__3NXpq {
  width: 250px;
  text-align: center;
  line-height: 40px;
}

.FindingsPopup_imprint_btn_container__1zwtT {
  display: block;
  width: 100px;
}

/* ------ */

.FindingsPopup_finding_container__Aj2ZZ {
  background-color: white;
  width: 100%;
  margin: 10px 0px;
  padding-bottom: 2px;
}

.FindingsPopup_finding_action_container__2gyrD {
  margin-right: 10px;
  margin-bottom: 10px;
}

.FindingsPopup_validate_container__1o_hE {
  text-align: right;
  margin: 10px 10px;
}

.FindingsPopup_finding_images_container__2yOpz {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.FindingsPopup_finding_image__1BhmP {
  height: 100px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}
.FindingsPopup_image_container__1N0DR {
  width: 45%;
  margin-top: 10px;
}
.FindingsPopup_image_name__bsZPF {
  margin: 0px 10px;
}
.FindingsPopup_finding_link__1sGAx {
  font-size: 16px;
  text-decoration: underline;
  color: var(--color-theme-blue);
  display: block;
  margin: 10px 0;
}

.FindingsPopup_finding_link_secondary__xw5Bk {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 5px;
}

.FindingsPopup_licence_container__2H10p {
  margin-top: 10px;
  display: flex;
  padding: 15px;
  background-color: var(--color-theme-black);
}
.FindingsPopup_license_text__2_c0y {
  display: inline-block;
 
}
.FindingsPopup_licence_icon__5LV7j {
  margin-right: 15px;
}

.FindingsPopup_licence_icon__5LV7j svg {
  width: 35px;
  height: 60px;
}

.FindingsPopup_license_details_container__BZx2D {
  width: 400px;
  padding: 12px 0;
}

.FindingsPopup_license_link__3NNtX {
  color: var(--color-font-10);
  font-weight: bold;
}

.FindingsPopup_license_date__3l9n3 {
  font-size: 14px;
}

.FindingsPopup_licence_validate_container__3Zaky {
  display: block;
  width: 100px;
}

.FindingsPopup_validate_label__Gx51B {
  font-size: 10px;
  text-align: center;
}

.FindingsPopup_validate_switch__3VDnX {
  margin-left: 20px !important;
  margin-top: 0px !important;
}

.FindingsPopup_add_finding_container__24RnY {
  text-align: right;
  width: 310px;
  background-color: var(--color-bg-caselist);
}

.FindingsPopup_btn_add_finding__20uQw {
  flex: 2 1;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 20px;
}

.FindingsPopup_plus_sign__o3sWs {
  margin-right: 10px;
  font-size: 20px;
}

.UploadLicense_upload_container__2tsDa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.UploadLicense_text_field__RtOBy {
  width: 70%;
}
.UploadLicense_text_field_input__O2Kcv {
  margin: 0 !important;
}

.UploadLicense_cancel__2gNkM {
  margin-right: 20px !important;
}

.UploadLicense_confirm__2z6n9 {
  margin-left: 0px !important;
}

.UploadLicense_file_box__KZoM6 {
  margin: 0px 16px;
}

.UploadLicense_upload_file_btn_wrap__1BlaZ {
  margin-right: 50px;
}

.UploadLicense_upload_btn__39BrG {
  background-color: #283371 !important;
  color: #fff !important;
}

.AddFindingPopup_content_container__3qfiJ {
}

/* upload section */
.AddFindingPopup_upload_container__3n4nV {
  display: flex;
  margin: 10px 0px;
}

.AddFindingPopup_image_box__ACwUE {
  width: 175px;
  height: 120px;
  border: 1px solid var(--color-theme-silver);
  margin: 0px 10px 10px 0px;
  border-radius: 2px;
  overflow: scroll;
}

.AddFindingPopup_image__1jKBB {
  width: 100%;
  object-fit: cover;
}

.AddFindingPopup_upload_actions_container__388N2 {
  display: flex;
  flex-direction: column;
}

.AddFindingPopup_upload_label__2bj9P {
  border: 1px solid var(--color-theme-blue);
  border-radius: 15px;
  padding: 2px 9px;
  cursor: pointer;
  margin: 5px;
  text-align: center;
  color: var(--color-theme-blue);
}

.AddFindingPopup_upload_label__2bj9P:hover {
  background-color: var(--color-theme-lightblue);
}

.AddFindingPopup_upload_input__32TLP {
  display: none;
}

.AddFindingPopup_upload_btn__20Lw1 {
  width: 120px;
  height: 28px;
  font-weight: bold !important;
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  margin: 5px !important;
}

.AddFindingPopup_delete_icon__1slGP {
  margin: 5px;
  cursor: pointer;
}
/* upload section */

.AddFindingPopup_finding_col__2pgQV {
  padding: 15px 0px 0px 0px;
}

.AddFindingPopup_artist_agency_row__56rbf {
  display: flex;
}

.AddFindingPopup_error_container__1w924 {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.AddFindingPopup_error_text__3GNX9 {
  font-size: 16px;
  color: var(--color-theme-white);
}

.AddFindingPopup_input_row__2I8A5 {
  margin-right: 10px !important;
}

.AddFindingPopup_requiredRed__3hbhR {
  color: var(--color-font-red);
}

.TemplatePopup_case_template_title__1hGxk {
  padding: 5px;
}

.PaymentInfoSection_payment_info_container__1P16A {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px 20px;
  background-color: var(--color-theme-white);
  border: 1px solid var(--color-theme-cloud);
  margin-top: 20px;
  font-size: 13px;
  cursor: pointer;
}

.PaymentInfoSection_status_column__3A2pQ {
  display: flex;
}

.PaymentInfoSection_icon__31ui0 {
  vertical-align: middle;
  display: inline-flex;
  margin: auto 20px;
  scale: 1.8;
}

.PaymentInfoSection_big_n_bold__227xT {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.PaymentInfoSection_bold__3Ei5u {
  font-weight: 700;
}

.PaymentInfoSection_small_title__HWA-0 {
  font-size: 14px;
  min-width: 210px;
  margin-top: 20px;
}

.PaymentInfoSection_big_value__26-tU {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
  min-height: 25px;
}

.PaymentInfoSection_popup_ok_button__Sc8pl {
  width: 115px;
  height: 52px;
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.PaymentInfoSection_dialog_title__1qSmj {
  font-weight: bold !important;
  margin: 20px 20px 0px 20px;
  font-size: 22px;
}

.CaseActivityCard_root__RAoR2 {
  -webkit-animation-name: CaseActivityCard_heigthChangeBack__2_mMW;
          animation-name: CaseActivityCard_heigthChangeBack__2_mMW;
  -webkit-animation-duration: 700ms;
          animation-duration: 700ms;
  position: relative;
  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.CaseActivityCard_rootFlex__1rCIO {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 8px 8px;
}

.CaseActivityCard_rootAnimation__xhQqf {
  -webkit-animation-name: CaseActivityCard_heigthChange__2pHc1;
          animation-name: CaseActivityCard_heigthChange__2pHc1;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  overflow: hidden;

  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.CaseActivityCard_left_container__3mlxw {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 115px;
}

.CaseActivityCard_left_timestamp__r77jC {
  font-size: 10px;
}

.CaseActivityCard_middle_container__1UDhK {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 165px;
}

.CaseActivityCard_middle_type__1XXR- {
  font-weight: bold;
  margin-bottom: 7px;
}

.CaseActivityCard_middle_user__2ET5p {
  font-size: 12px;
  font-weight: bold;
}
.CaseActivityCard_right_container__3Hgym {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 474px;
}

.CaseActivityCard_right_container_opener__qdTV5 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.CaseActivityCard_btn_confirm__3uzUr {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  margin-top: 10px;
}

.CaseActivityCard_unread_marker__3JSRQ {
  border-radius: 10px;
  width: 7px;
  /* z-index: 20; */
  height: 7px;
  background-color: royalblue;
  position: relative;
  top: -29px;
  left: 25px;
  margin-bottom: -7px;
}

.CaseActivityCard_btn_default__2MFry {
  color: var(--color-font-10) !important;

  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px;
  margin-right: 10px !important;
}

.CaseActivityCard_btn_default_invisible__jIYsR {
  color: var(--color-font-10) !important;

  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px;
  margin-right: 10px !important;
  visibility: hidden;
}
.CaseActivityCard_notePopupTextContainer__Phb70 {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.CaseActivityCard_delete_shopping_icon__9Yx9R {
  cursor: pointer;
}

.CaseActivityCard_delete_icon__d_0qn {
  cursor: pointer;
}

.CaseActivityCard_delete_icon_disabled__1F8vl {
  color: darkgray;
}

.CaseActivityCard_delete_icon__d_0qn:hover {
  color: var(--color-theme-blue);
}

.CaseActivityCard_edit_icon__DDpAQ {
  cursor: pointer;
}

.CaseActivityCard_notePopupTextContainer__Phb70::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.CaseActivityCard_notePopupTextContainer__Phb70::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.CaseActivityCard_notePopupActionsContainer__1Bd6x {
  display: flex;
  flex-direction: row-reverse;
}

.CaseActivityCard_gray__5oh89 {
  rotate: 180deg;
}

.CaseActivityCard_btn_confirm__3uzUr {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.CaseActivityCard_btn_confirm__3uzUr:hover {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.CaseActivityCard_btn_cancel__14cuM {
  width: 100px !important;
  border: 1px solid var(--color-font-10) !important;
  color: var(--color-font-10) !important;
}

.CaseActivityCard_btn_cancel__14cuM:hover {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-white) !important;
  background-color: var(--color-font-10) !important;
}

.CaseActivityCard_dialogContent__gTEhO {
  margin-left: 5px;
  margin-bottom: 30px;
}

.CaseActivityCard_mailContainer__2ZzBm {
  border-top: 1px solid var(--color-theme-cloud);
  margin-top: 14px;
}

.CaseActivityCard_email_tools__2flRz {
  background: rgb(0 0 0 / 3%);
  position: relative;

  border-bottom: 1px solid lightgray;
  padding: 4px 4px;
  width: calc(100% + 8px);
}

.CaseActivityCard_email_resend_container__3LMbL {
  /* background: rgb(0 0 0 / 3%); */
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid lightgray;
  padding: 4px 0px 4px 4px;
  width: calc(100% + 8px);
  right: 8px;
}
.CaseActivityCard_resend_text__XdoDx {
  /* color: var(--color-theme-yellow); */
  color: rgb(233, 170, 12);
  margin: auto 0 auto 10px;
}

.CaseActivityCard_warning_icon__2TmJ3 {
  color: var(--color-theme-yellow);
}

.CaseActivityCard_scheduled_text_container__1RNZw {
  padding: 8px;
}

.CaseActivityCard_attachement_row__rzOWy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.CaseActivityCard_attachment_button_container__1FCrR {
  position: absolute;
  right: 0px;
  top: 4px;
}

@-webkit-keyframes CaseActivityCard_heigthChange__2pHc1 {
  from {
    max-height: 56px;
  }
  to {
    max-height: 4000px;
  }
}

@keyframes CaseActivityCard_heigthChange__2pHc1 {
  from {
    max-height: 56px;
  }
  to {
    max-height: 4000px;
  }
}

@-webkit-keyframes CaseActivityCard_heigthChangeBack__2_mMW {
  from {
    max-height: 900px;
  }
  to {
    max-height: 56px;
  }
}

@keyframes CaseActivityCard_heigthChangeBack__2_mMW {
  from {
    max-height: 900px;
  }
  to {
    max-height: 56px;
  }
}

.CaseActivitySection_root__12lHF {
  margin-top: 20px;
}

.CaseActivitySection_title_container__ab9h_ {
  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.CaseActivitySection_title_font__1UfiF {
  font-weight: 600 !important;
  cursor: default;
  font-size: 20px;
}

.CaseActivitySection_add_note_button__1dRJ4 {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
  position: relative;
  z-index: 99;
}

.CaseActivitySection_title_right_side__1qKrA {
  display: flex;
}

.CaseActivitySection_future_section_hidden__2cClJ {
  -webkit-animation-name: CaseActivitySection_heigthChangeBack__36rYX;
          animation-name: CaseActivitySection_heigthChangeBack__36rYX;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  overflow: hidden;
  max-height: 0px;
}

.CaseActivitySection_future_section_visible__3T9Sq {
  -webkit-animation-name: CaseActivitySection_heigthChange__mUNfC;
          animation-name: CaseActivitySection_heigthChange__mUNfC;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  overflow: hidden;
}

.CaseActivitySection_future_section_hidden_no_animation__1JSue {
  overflow: hidden;
  max-height: 0px;
}

.CaseActivitySection_future_section_visible_no_animation__82u9j {
  overflow: hidden;
}

@-webkit-keyframes CaseActivitySection_heigthChange__mUNfC {
  from {
    max-height: 0px;
  }
  to {
    max-height: 4000px;
  }
}

@keyframes CaseActivitySection_heigthChange__mUNfC {
  from {
    max-height: 0px;
  }
  to {
    max-height: 4000px;
  }
}

@-webkit-keyframes CaseActivitySection_heigthChangeBack__36rYX {
  from {
    max-height: 900px;
  }
  to {
    max-height: 0px;
  }
}

@keyframes CaseActivitySection_heigthChangeBack__36rYX {
  from {
    max-height: 900px;
  }
  to {
    max-height: 0px;
  }
}

.CaseActivitySection_activity_container__2_fO3 {
  margin-bottom: 15px;
}

.CaseActivitySection_activity_container_more_button__3NZb7{
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.CaseActivitySection_loading__3xuXz {
  width: 100%;
  text-align: center;
  color: black;
}

.CaseActivitySection_devider_container__3rebW {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.CaseActivitySection_devider_border__18Wvi {
  border-bottom: 2px solid black;
  width: 100%;
}

.CaseActivitySection_devider_content__2N8RQ {
  padding: 0 10px 0 10px;
  font-weight: bold;
  margin: 0px 60px;
}

.CaseActivitySection_flex_right__1hwGP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
}

.CallbackPopup_date__1tgNN {
  font-weight: bold;
  padding: 15px;
  background-color: var(--color-theme-red);
}

.CallbackPopup_name__2Ga01 {
  font-weight: bold;
  padding: 15px 15px;
}

.CallbackPopup_role__1Gm5F {
  font-size: 14px;
  padding: 5px 15px;
}

.CallbackPopup_email__1krqc {
  font-size: 14px;
  padding: 5px 15px;
}

.CallbackPopup_phone__2aiRZ {
  font-weight: bold;
  padding: 15px 15px;
}

.CallbackPopup_note__1qkUP {
  border: 1px solid var(--color-theme-gray);
  padding: 15px;
  background-color: var(--color-theme-silver);
}

.CallbackLogItem_root__13IB6 {
  display: flex;
  background-color: var(--color-bg-content-callback);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 20px;
}

.CallbackLogItem_icon_container__2vwS1 {
  background-color: var(--color-theme-red);
  width: 55px;
  text-align: center;
  padding-top: 12px;
  border-radius: 10px;
}

.CallbackLogItem_left_container__2DygV {
  flex: 1 1;
  min-width: 265px;
}

.CallbackLogItem_title_icon__248Vh {
  color: var(--color-font-10);
}

.CallbackLogItem_title_icon__248Vh svg {
  height: 28px;
  width: 25px;
}

.CallbackLogItem_title_container__3o-aJ {
  display: flex;
}

.CallbackLogItem_title_text__2awjg {
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
  line-height: 33px;
}

.CallbackLogItem_date__3MvaC {
  font-size: 12px;
  padding-left: 15px;
}

.CallbackLogItem_center_container__1jOwD {
  flex: 2 1;
  min-width: 250px;
}

.CallbackLogItem_center_primary__35pBM {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 6px;
}

.CallbackLogItem_center_secondary__2jqpU {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.CallbackLogItem_center_secondary__2jqpU {
  margin-top: 3px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.CallbackLogItem_email__3YkJV {
  font-size: 12px;
  margin-top: 4px;
}

.CallbackLogItem_state__IWobD {
  padding-left: 37px;
  margin-top: 26px;
  font-size: 15px;
}

.CallbackLogItem_right_container__3DIId {
  flex: 3 1;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.CallbackLogItem_right_container_disabled__3w-LE {
  flex: 3 1;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.CallbackLogItem_right_container_disabled__3w-LE svg {
  color: var(--color-theme-silver);
}

.LicensePopup_content_container__1yWyb {
  padding: 5px;
  background-color: var(--color-theme-white);
}

.LicensePopup_claim_hr__1r5is {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 10px 0px;
  width: 100%;
}

.LicensePopup_btn_additional_fees__3hRlu {
  flex: 2 1;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  max-width: 300px !important;
  line-height: 0px !important;
}

.LicensePopup_findings_container__bQjG0 {
  display: block;
}

.LicensePopup_finding_container__3svRl {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.LicensePopup_finging_image_container__2qEJ7 {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LicensePopup_finging_image_container__2qEJ7 svg {
  color: var(--color-theme-10);
  width: 120px;
  height: 60px;
}

.LicensePopup_license_link__lHOLH {
  text-decoration: none;
  color: var(--color-theme-10);
}

.LicensePopup_finging_image__2QaFP {
  height: 85px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.LicensePopup_finging_details_container__1mYTE {
  width: 350px;
  padding: 0px 20px;
}

.LicensePopup_finding_date__3CtiI {
  font-size: 12px;
  padding: 5px 0px;
}

.LicensePopup_finding_note__1Dmhs {
  font-size: 14px;
  padding-top: 15px;
}

.LicensePopup_finding_link__2rtsm {
  text-decoration: none;
  color: var(--color-font-10);
  font-size: 18px;
  font-weight: bold;
}

.LicensePopup_finging_validity_container__1S-cP {
  width: 100px;
  padding-left: 20px;
  border-radius: 5px;
}

.LicensePopup_add_fee_container__2B0QJ {
  text-align: right;
}

.LicensePopup_additional_fees_container__2U0U- {
  display: block;
}

.LicensePopup_additional_fee_container__2DEiW {
  display: flex;
}

.LicensePopup_other_fees_container__3k3jH {
  display: block;
}

.LicensePopup_vat_container__3IwJo {
  display: flex;
  justify-content: space-between;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.LicensePopup_first_input__37P_R {
  margin-right: 20px !important;
}

.LicensePopup_settelment_container__3c0xi {
  display: flex;
  justify-content: space-between;
}

.LicensePopup_taxable_switch_container__3oQ6P {
  display: block;
  width: 190px;
  padding-top: 5px;
}

.LicensePopup_taxable_label__3vc1K {
  font-size: 10px;
  text-align: center;
}

.LicensePopup_taxable_switch__3zzvH {
  margin-left: 10px !important;
  margin-top: 0px !important;
}

.LicensePopup_btn_delete_container__30ATr {
  width: 90px;
  padding-top: 35px;
  text-align: left;
  color: var(--color-theme-red);
}

.LicensePopup_summary_container__1mlKe {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-darkgray);
  padding: 5px 15px;
}

.LicensePopup_license_container__F-IDp {
  margin-right: 15px;
}

.LicensePopup_gross_container__3nqHM {
  margin-right: 15px;
}

.LicensePopup_discount_container__1rfHm {
  margin-right: 15px;
}

.LicensePopup_sumary_label__2wvLL {
  font-size: 12px;
}

.LicensePopup_sumary_value__pkmpa {
  font-size: 18px;
  font-weight: bold;
}

.LicenseLogItem_root__1NJ8y {
  display: flex;
  background-color: var(--color-theme-white);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid var(--color-theme-bright-gray);
}

.LicenseLogItem_icon_container__1rF_f {
  background-color: var(--color-theme-blue);
  width: 55px;
  text-align: center;
  padding-top: 12px;
  border-radius: 10px;
}

.LicenseLogItem_left_container__otWbn {
  flex: 1 1;
  min-width: 265px;
}

.LicenseLogItem_title_icon__3StQ- {
  color: var(--color-theme-white);
}

.LicenseLogItem_title_icon__3StQ- svg {
  height: 25px;
  width: 30px;
}

.LicenseLogItem_title_container__6070f {
  display: flex;
}

.LicenseLogItem_title_text__2U2fV {
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
  line-height: 33px;
}

.LicenseLogItem_date__ETs9e {
  font-size: 12px;
  padding-left: 15px;
}

.LicenseLogItem_center_container__1FTkx {
  flex: 2 1;
  min-width: 250px;
}

.LicenseLogItem_center_primary__2uGrn {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 6px;
}

.LicenseLogItem_center_secondary__3l1ta {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.LicenseLogItem_center_secondary__3l1ta {
  margin-top: 3px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.LicenseLogItem_email__1CjaE {
  font-size: 12px;
  margin-top: 4px;
}

.LicenseLogItem_state___bIYw {
  padding-left: 37px;
  margin-top: 26px;
  font-size: 15px;
}

.LicenseLogItem_right_container__1DbLQ {
  flex: 3 1;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.LicenseLogItem_right_container_disabled__1cPPv {
  flex: 3 1;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.LicenseLogItem_right_container_disabled__1cPPv svg {
  color: var(--color-theme-silver);
}

.AddressPopup_address_modal__3A-_V {
  max-width: 816px;
  padding: 0px 25px;
}
.AddressPopup_address_container__2k5bg {
  display: flex;
  justify-content: space-between;
}

.AddressPopup_text_label__1mA-W {
  margin-bottom: 5px;
}

.AddressPopup_address_row__2UCFA {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.AddressPopup_address_header__2w7Ly {
  padding-top: 10px;
  color: #283371;
}

.AddressPopup_half_form__UwOHf {
  width: 48%;
}

.AddressPopup_country_text__2-J95 {
  margin: 14px 0px 0px 0px !important;
  font-weight: bold;
}

.AddressPopup_billing_header__3n-uL {
  font-size: 16px;
}

.AddressPopup_billing_container__TEcmt {
  margin-bottom: 20px;
  min-width: 530px;
}

.AddressPopup_billing_input__2Dtw9 {
  width: 100%;
  height: 30px;
  border: none;
  border-bottom: 1px solid #e4e7e8;
}

.AddressPopup_button__3WHIu {
  float: right;
}

.AddressPopup_required_info__oP763 {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}

.AddressPopup_mobile_view__1NE1o {
  display: none;
}

.AddressPopup_actionsContainer__3RvgG {
  padding-left: 15px;

  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}
@media only screen and (max-width: 600px) {
  .AddressPopup_address_modal__3A-_V {
    min-width: 0px;
    max-width: 816px;
    background-color: #ffffff;
    padding: 0px 25px;
  }
  .AddressPopup_billing_container__TEcmt {
    margin-bottom: 20px;
    min-width: 100%;
  }
  .AddressPopup_mobile_view__1NE1o {
    display: initial;
  }
  .AddressPopup_normal_view__1TRLJ {
    display: none;
  }
  .AddressPopup_actionsContainer__3RvgG {
    align-items: center;
  }
  .AddressPopup_country_text__2-J95 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .AddressPopup_mobile_view__1NE1o {
    display: initial;
  }
  .AddressPopup_normal_view__1TRLJ {
    display: none;
  }
  .AddressPopup_address_modal__3A-_V {
    width: -webkit-fill-available;
    height: auto;
    padding: 15px;
  }
  .AddressPopup_billing_container__TEcmt {
    margin-bottom: 20px;
    min-width: 100%;
  }

  .AddressPopup_address_container__2k5bg {
    display: block;
  }
  .AddressPopup_address_header_mobile__V5DvY {
    display: flex;
    margin: 0px;
  }
}

.AddressPopup_header_container__Uc5EE {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddressPopup_nameWarningContainer__sTDcl {
  font-size: 13px;
  margin-bottom: 19px;
  margin-top: -25px;
  color: red;
}

.Account_root__1JSC3 {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.Account_account_title__2OukZ {
  display: flex;
  align-items: center;
}

.Account_business_icon__Y6FVI {
  padding-right: 10px;
}

.Account_arrow_icon__Q5m6l {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.Account_account_title__2OukZ {
  display: flex;
  align-items: center;
}

.Account_business_icon__Y6FVI {
  padding-right: 10px;
}

.Account_confirm_btn_container__1Qjb6 {
  text-align: right;
  width: 775px;
  margin: 20px 0 !important;
}

.Account_confirm_btn__2mcPX {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.Account_account_content__37cW4 {
  width: 775px;
}

.Account_business_container__3R2MU {
  display: flex;
  justify-content: space-between;
}

.Account_business_col__1oWOx {
  padding: 20px 0;
}

.Account_info_text__ufoZL {
  font-size: 16px;
}

.Account_info_text__ufoZL a {
  color: var(--color-theme-blue);
  text-decoration: none;
}

.Account_input__3MISx:hover {
  text-decoration: none;
  border: none !important;
}

.Account_error_container__2o44E {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.Account_error_text__3iGL9 {
  font-size: 16px;
  color: var(--color-theme-white);
}

.Account_empty_row__EJQls {
  height: 80px;
}

.Account_input__3MISx {
  width: 370px !important;
}

.AddCasePopup_content_container__1Eril {
}

.AddCasePopup_error_container__1KlYV {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.AddCasePopup_error_text__19r2b {
  font-size: 16px;
  color: var(--color-theme-white);
}

.EmailFlowRow_edit_popup__FQx7i {
  z-index: 8000;
}

.EmailFlowRow_edit_popup_container__1t9dQ {
  position: fixed !important;
  left: 210px;
  top: 91px;
  width: 290px;
}

/* email row */
.EmailFlowRow_email_row_root__1wIsf {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.EmailFlowRow_email_row_root__1wIsf:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.EmailFlowRow_email_flow_input__dW7rJ {
  background-color: var(--color-bg-caselist-item);
  outline: none;
  border: none;
  color: var(--color-font-10);
  font-weight: bold;
  font-size: 16px;
}

.EmailFlowRow_account_tree_icon__28Bcm {
  margin: 0px 10px;
}

.EmailFlowRow_edit_icon_container__2S2IK {
  display: flex;
  justify-content: flex-end;
}

.EmailFlowRow_edit_icon__vjxCA {
  padding: 0px 10px;
}
.EmailFlowRow_warning_icon__2hKiN {
  color: var(--color-theme-yellow);
}

.EmailFlowRow_edit_icon__vjxCA:hover {
  color: var(--color-font-10);
}

.EmailFlowRow_input_container__3tnl2 {
  width: 65%;
}

.EmailFlowRow_flow_list_item__wXC_T {
  padding: 0px !important;
}

.EmailFlowRow_email_row_root__1wIsf:hover .EmailFlowRow_edit_icon__vjxCA {
  color: var(--color-font-10);
}

/* submenu */
.EmailFlowRow_template_row_root__B80K8 {
  display: flex;
  color: var(--color-theme-10);

  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.EmailFlowRow_template_row_root__B80K8:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  color: var(--color-font-10) !important;
}

.EmailFlowRow_template_row_root_selected__3aucz {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 2px solid var(--color-bg-caselist-item-selected);
}

.EmailFlowRow_status_item__pwA8f {
  height: 45px;
  font-size: 12px;
}

.EmailFlowRow_status_icon__cAVHY {
  margin: 0px 10px 0px 15px;
}

.EmailFlowRow_status_icon__cAVHY svg {
  font-size: 20px !important;
}

.EmailFlowRow_status_title__enevA {
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.EmailFlowRow_delete_template__3JOXq {
  width: 100%;
  display: none;
  justify-content: flex-end;
  color: var(--color-theme-cloud);
}

.EmailFlowRow_add_template_title__3SJVP {
  color: var(--color-theme-silver);
}

.EmailFlowRow_add_template_icon__1yl44 {
  color: var(--color-theme-silver);
}

.EmailFlowRow_template_row_root__B80K8:hover .EmailFlowRow_add_template_icon__1yl44 {
  color: var(--color-font-10);
}

.EmailFlowRow_template_row_root__B80K8:hover .EmailFlowRow_add_template_title__3SJVP {
  color: var(--color-font-10);
}

.EmailFlowRow_template_row_root__B80K8:hover .EmailFlowRow_delete_template__3JOXq {
  display: flex;
  color: var(--color-theme-cloud);
}

.EmailFlowRow_template_row_root_selected__3aucz .EmailFlowRow_delete_template__3JOXq {
  display: flex;
  color: var(--color-theme-cloud);
}

.EmailFlowRow_delete_icon__3e6P6:hover {
  color: var(--color-theme-blue);
}

.EmailFlowTemplate_flow_template_container__1pYGv {
  display: flex;
  flex-direction: column;
  color: var(--color-theme-10);

  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.EmailFlowTemplate_flow_input__V_o7b {
  text-decoration: none;
  margin: 10px 0 !important;
  background-color: var(--color-bg-caselist);
  width: 100%;
}

.EmailFlowTemplate_flow_input__V_o7b input {
  text-overflow: ellipsis;
}

.EmailFlowTemplate_divider__3qPze {
  margin: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.EmailFlowTemplate_divider_line__3rKzv {
  flex: 1 1;
  border-bottom: 1px solid black;
}

.EmailFlowTemplate_divider_word__1YJap {
  padding: 0 10px;
}

.EmailFlowTemplate_trigger_scheduled__2H-6u {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.EmailFlowTemplate_disabled__1J8IJ {
  opacity: 0.5;
}

.EmailTemplate_root__1fM2n {
  max-width: 2000px;
  margin-right: 10px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  height: 100%;
}

.EmailTemplate_confirm_btn_container__2lmsX {
  display: flex;
  justify-content: space-between;
}

.EmailTemplate_confirm_btn__1Y5wY {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 220px;
  height: 41px;
  font-weight: bold !important;
  margin: 5px 0px !important;
}

.EmailTemplate_btn_cancel__1mCqO {
  width: 220px !important;
}

.EmailTemplate_template_action_container__UMSuE {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 11px;
}
.EmailTemplate_template_name_container__3yppJ {
  width: 35%;
}

.EmailTemplate_template_days_container__24Fnt {
  width: 20%;
}

.EmailTemplate_template_container__2bOV8 {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  height: 65vh;
}

.EmailTemplate_placeholder_col__1wIqv {
  border: 0.5px solid;
  width: 230px;
  border-radius: 4px;
  border-color: var(--color-theme-gray-white);
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100%;
}
.EmailTemplate_placeholder_info_container__1BW52 {
  padding: 10px 0px;
  overflow-y: auto;
  background-color: var(--color-bg-caselist);
  border-radius: 4px;
  width: 100%;
}

.EmailTemplate_placeholder_scrollbar__uIGGr {
  width: 86% !important;
}

.EmailTemplate_placeholder_info_container__1BW52 > * {
  margin-left: 10% !important;
}

.EmailTemplate_template_col__10ubw {
  width: 80%;
  margin-right: 10px;
  z-index: 2;
}

.EmailTemplate_template_text_content__f8FJi::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.EmailTemplate_template_text_content__f8FJi::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.EmailTemplate_placeholder_info_container__1BW52::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.EmailTemplate_placeholder_info_container__1BW52::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

/* placeholders */
.EmailTemplate_placeholder_group__22qw6 {
  margin: 7px;
}

.EmailTemplate_placeholder_item__2lj06 p {
  font-size: 14px !important;
  display: inline-block;
  clear: both;
  border: 1px solid var(--color-theme-cloud);
  border-radius: 15px;
  padding: 0px 10px;
  margin: 5px;
}

.EmailTemplate_placeholder_enabled__3NbKF p {
  color: var(--color-theme-blue);
}

.EmailTemplate_placeholder_item__2lj06 p:hover {
  background-color: var(--color-theme-lightblue);
  border: 1px solid var(--color-theme-blue);
}

.EmailTemplate_tooltip_title__a_9le {
  font-size: 13px !important;
}

.EmailTemplate_placeholder_disabled__3giEi p {
  color: var(--color-theme-cloud);
}

.ChangeEmailTemplatePopup_content_container__3108T {
  padding-bottom: 20px;
}

.ChangeEmailTemplatePopup_btn_no__3K7z0 {
  font-weight: bold !important;
  width: 150px;
  color: grey !important;
  border: 1px solid grey !important;
  margin-left: 15px !important;
}

.SalutationsPlaceholder_body__16UyO {
  display: flex;
  justify-content: space-evenly;
  flex: start 1;
}
.SalutationsPlaceholder_block__2f8w6 {
  width: 90%;
  margin-bottom: 10px;
}

.SalutationsPlaceholder_block_title__2yPyj {
  margin-top: 2px;
  margin-bottom: 2px;
}

.SalutationsPlaceholder_block_body__3ghLz {
  display: flex;
  align-items: center;
  border: 2px solid lightgray;
  border-radius: 6px;
  padding: 5px;
}
.SalutationsPlaceholder_block_inputs__EiguS {
  /* display: flex; */
  width: 90%;
  margin: 0;
}
.SalutationsPlaceholder_block_icon__2ei35 {
  margin-left: 3%;
}
.SalutationsPlaceholder_block_label__jvGnJ {
  margin-right: 12%;
  width: 8%;
}

.ImageUseesPlaceholderPopup_popup_body__mpEQR {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}
.ImageUseesPlaceholderPopup_image_option__2QblO {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
}
.ImageUseesPlaceholderPopup_image_check__34XlV {
  width: 10%;
}
.ImageUseesPlaceholderPopup_image_text__27AIf {
  width: 100%;
}
.ImageUseesPlaceholderPopup_left_body__25hrz {
  width: 30%;
}
.ImageUseesPlaceholderPopup_right_body__3GDdH {
  width: 30%;
}
.ImageUseesPlaceholderPopup_subheader__24iDg {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ImageUseesPlaceholderPopup_explainatory_text__35VyD {
  margin-top: 0 !important;
}

.highlightTextarea {
  position: relative;
  width: 100%;
}

.highlightTextarea .highlightTextarea-container {
  position: absolute;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.highlightTextarea .highlightTextarea-highlighter {
  position: absolute;
  z-index: 1;
  border: none;
  margin: 0;
  color: transparent;
  cursor: text;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: inherit;
}

.highlightTextarea textarea,
.highlightTextarea input {
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: transparent;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: inherit;
}

/* Debug mode for highlighter */
.highlightTextarea.debug .highlightTextarea-highlighter {
  color: #f00;
  border: 1px solid #f00;
  margin: -1px;
}

/* Style adjustments for marks */
.highlightTextarea mark {
  line-height: inherit;
  color: transparent;
  margin: 0;
  padding: 0;
}

/* Resizable settings */
.highlightTextarea .ui-wrapper {
  margin: 0 !important;
}

.highlightTextarea .ui-resizable-se {
  bottom: 15px;
  right: 0px;
}

.EmailFlows_root__2B5Ek {
  width: 100%;
  color: var(--color-font-10);
  overflow: scroll;
  height: calc(100vh - 133px);
  overflow-y: scroll;
}

.EmailFlows_flow_list_container__1LQG3 {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 210px;
  top: 91px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: calc(100vh - 140px);
  z-index: 100;
}

.EmailFlows_new_flow_button__3jJGk {
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  align-self: center;
  background-color: var(--color-bg-caselist-item);
}

/* add flow row  */
.EmailFlows_email_flow_input__3odjm {
  background-color: var(--color-bg-caselist-item);
  outline: none;
  border: none;
  color: var(--color-font-10);
  font-weight: bold;
  font-size: 16px;
}

.EmailFlows_email_row_root__SXIfL {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.EmailFlows_email_row_root__SXIfL:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.EmailFlows_account_tree_icon__2RqiK {
  margin: 0px 10px;
}
/* template details */
.EmailFlows_template_container__2hpee {
  overflow: hidden;
  margin-left: 310px;
  background-color: var(--color-bg-content);
}

.EmailFlows_template_no_data__1VPVr {
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}

.NewEmailFlowPopup_checkbox_text__34yhd {
  font-size: small;
}
.NewEmailFlowPopup_checkbox_missing__3Swxx {
  color: red;
}

.NewEmailFlowPopup_confirm_disabled__1HnmJ {
  opacity: 0.5;
  pointer-events: none;
}

.BaseSettings_root__1Dx-- {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.BaseSettings_settlement_portal_title__2P-Va {
  display: flex;
  align-items: center;
}

.BaseSettings_device_icon__3Nc0Y {
  padding-right: 10px;
}

.BaseSettings_arrow_icon__2FL3o {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.BaseSettings_tabs_container__GjfMx {
  margin-top: 60px;
  border: 1px solid var(--color-theme-bright-gray);
}

.BaseSettings_tabs_content_container__1urof {
  padding: 60px 30px 30px 30px;
  border: 1px solid var(--color-theme-bright-gray);
  border-top: none;
}

.BaseSettings_tabs_content_container_images__lGSim {
  border: 1px solid var(--color-theme-bright-gray);
  border-top: none;
}

.BaseSettings_tab_content_portal_address__3p1_t {
  width: 70%;
}

.BaseSettings_tab_content_legal_contact__U4A_Z {
  width: 775px;
}

.BaseSettings_example_input_container__36GDf {
  display: flex;
  align-items: center;
}

.BaseSettings_example_input_container__36GDf p {
  padding: 0px 10px;
  margin-bottom: 8px;
}

.BaseSettings_input_container__22kth {
  width: 370px !important;
}

.BaseSettings_subdomain_container__25vZK {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-theme-bright-gray);
}

.BaseSettings_label__1wbSg {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.BaseSettings_label__1wbSg b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.BaseSettings_confirm_btn_container__1QZbu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0px;
}

.BaseSettings_confirm_btn__35IHO {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.BaseSettings_confirm_btn_disabled__1KIHC {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.BaseSettings_description__1x8zZ {
  padding-bottom: 20px;
}

.BaseSettings_upload_size__30Tn7 {
  padding-left: 10px;
}
/* legal & contact */

.BaseSettings_input_row__En2wD {
  display: flex;
  justify-content: space-between;
}

/* image upload */

.BaseSettings_general_col_images__2VkkY {
  display: flex;
  width: 55%;
  justify-content: space-between;
}

.BaseSettings_general_image_container__vRbiu {
  display: flex;
  justify-content: space-between;
}

.BaseSettings_upload_title_container__1Y50S {
  display: flex;
}

.BaseSettings_upload_size__30Tn7 {
  color: var(--color-theme-silver);
  font-weight: bold !important;
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}

.BaseSettings_cloud_upload_icon__2K8WA {
  color: var(--color-theme-cloud);
  cursor: pointer;
}

.BaseSettings_cloud_upload_icon__2K8WA:hover {
  color: var(--color-theme-blue);
}

.BaseSettings_logo_box__2rUCW {
  width: 300px;
  height: 84px;
  border-radius: 2px;
  overflow: scroll;
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.BaseSettings_logo__2qErE {
  width: 100%;
  object-fit: cover;
}

.BaseSettings_favicon_box__2VEFr {
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 2px;
  overflow: scroll;
  position: relative;
}

.BaseSettings_favicon__FF2Wx {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BaseSettings_upload_container__2pNje {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BaseSettings_upload_label__2F9pM {
  border: 1px solid var(--color-theme-silver);
  border-radius: 15px;
  background-color: var(--color-theme-silver);
  padding: 2px 10px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 1px 1px 1px var(--color-theme-darkgray);
  text-align: center;
}

.BaseSettings_upload_label_disabled__1MOW4 {
  border: 1px solid var(--color-theme-silver);
  border-radius: 15px;
  background-color: var(--color-theme-silver);
  padding: 2px 10px;
  margin: 10px;
  text-align: center;
}


.BaseSettings_upload_input__1ceY2 {
  display: none;
}

.BaseSettings_upload_btn__140Hd {
  width: 120px;
  height: 28px;
  font-weight: bold !important;
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  margin: 0px 10px !important;
}

.BaseSettings_about_us_image_box__3UKTm {
  border: 1px solid var(--color-theme-cloud);
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 200px;
  overflow: scroll;
  position: relative;
}

.BaseSettings_about_us_image__3w_Lq {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BaseSettings_logo_favicon_container__2GMdV {
  padding: 30px;
  padding-top: 60px;
}

.BaseSettings_about_us_container__3FRgQ {
  border-top: 1px solid var(--color-theme-bright-gray);
  padding: 30px;
}

.BaseSettings_about_us_content__1fgOF {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.BaseSettings_about_us_image_text__2yOV7 {
  padding-bottom: 20px;
  width: 70%;
}

.BaseSettings_delete_icon_container_logo__1OHko {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.BaseSettings_delete_icon_container_favicon__3f2aI {
  width: 84px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.BaseSettings_delete_icon_container_about_us__3gf2c {
  width: 150px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.BaseSettings_delete_icon__2D4OC {
  color: var(--color-theme-cloud);
  cursor: pointer;
  padding: 5px;
}

.BaseSettings_delete_icon__2D4OC:hover {
  color: var(--color-theme-blue);
}

/* upgrade */
.BaseSettings_upgrade_container__3gKJE {
  display: flex;
}

.BaseSettings_upgrade_domain_image__FfzqP {
  width: 400px;
  height: 200px;
}

.BaseSettings_upgrade_logo_image__2XTdv {
  width: 280px;
  height: 200px;
}

.BaseSettings_upgrade_content__VFKud {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
}

.BaseSettings_upgrade_text_large__190US {
  font-size: 20px;
  font-weight: bold;
}

.BaseSettings_upgrade_btn_container__2kf2S {
  padding: 20px 0px;
}


.BaseSettings_button_confirm__A19ml {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.BaseSettings_deletionPopupText__HrvwH{
  padding: 5px;
}

.BaseSettings_deletionPopupButtonContainer__1F8EA{
  display: flex;
    margin-top: 36px;
    flex-direction: row-reverse;
}
.Languages_root__2WUE8 {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
}

.Languages_languages_container__mKxDq {
  display: flex;
}

.Languages_language_list_container__37fPV {
  position: fixed;
  left: 210px;
  top: 91px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: calc(100vh - 140px);
}

.Languages_tabs_and_content_container__t_m31 {
  width: 950px;
  overflow: hidden;
  margin-left: 310px;
  background-color: var(--color-bg-content);
}

.Languages_add_language_container__QDjzt {
  background-color: var(--color-bg-caselist);
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
}

.Languages_btn_add_language__3kIWB {
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
}

.Languages_btn_add_language__3kIWB:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.Languages_plus_sign__d01a4 {
  margin-right: 10px;
  font-size: 20px;
}

.Languages_translation_no_data__5iyoV {
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}

.SettlementPortalTexts_root__lMmXA {
  border: 1px solid var(--color-theme-bright-gray);
}

.SettlementPortalTexts_language_header__EzFlK {
  background-color: var(--color-theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
}

.SettlementPortalTexts_language_name_container__3Nf7V {
  width: 18%;
  display: flex;
  justify-content: space-between;
}
.SettlementPortalTexts_language_title__oVs28 {
  width: 50%;
}

.SettlementPortalTexts_btn_default__rR0He {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
  margin-right: 20px !important;
}

.SettlementPortalTexts_btn_default__rR0He:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
  margin-right: 20px !important;
}

.SettlementPortalTexts_btn_activate__20YKY {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.SettlementPortalTexts_btn_activate__20YKY:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
}

.SettlementPortalTexts_btn_deactivate__1mmcS {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-silver) !important;
  height: 40px;
}

.SettlementPortalTexts_btn_deactivate__1mmcS:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
}

.SettlementPortalTexts_tabs_content_container__1JMuL {
  padding: 40px 30px 30px 30px;
  border-top: 1px solid var(--color-theme-gray);
}

.SettlementPortalTexts_label__3uMcL {
  padding: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.SettlementPortalTexts_label__3uMcL b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.SettlementPortalTexts_confirm_btn_container__2B_cn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0px;
}

.SettlementPortalTexts_confirm_btn__2viKX {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.SettlementPortalTexts_confirm_btn_disabled__2uSW6 {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.SettlementPortalTexts_text_description__1PRM9 {
  font-size: 14px;
}

/* faq tab */
.SettlementPortalTexts_add_faq_btn_container__EEl11 {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
}

.SettlementPortalTexts_add_faq_btn__2DADy {
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-10) !important;
}

.SettlementPortalTexts_label_faq__3T2I7 {
  padding-bottom: 10px;
  font-size: 16px;
}

.SettlementPortalTexts_faq_container__3K1ch {
  display: flex;
  border: 1px solid var(--color-theme-silver);
  align-items: top;
  padding: 10px;
  margin: 10px 0px;
}

.SettlementPortalTexts_faq_input_container__2RGc6 {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.SettlementPortalTexts_delete_icon__NLnBZ {
  cursor: pointer;
}

/* translation row */
.TranslationRow_translation_row_root__12lOc {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.TranslationRow_translation_row_root__12lOc:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.TranslationRow_translation_row_root_selected__9YioC {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 1px solid var(--color-bg-caselist-item-selected);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);

}

.TranslationRow_translation_row_root__12lOc b {
  padding: 0px 15px 0px 10px;
}

.TranslationRow_translation_row_root_selected__9YioC b {
  padding: 0px 15px 0px 10px;
}

.TranslationRow_short_language__3mRQB {
  width: 15%;
}

.TranslationRow_long_language__3KIcL {
  width: 60%;
  overflow: scroll;
}

.TranslationRow_active_translation__1TrZe {
  width: 10%;
  padding: 0px 5px;
}

.TranslationRow_default_translation__2MZRn {
  width: 10%;
  padding: 0px 5px;
}

.EmailSetup_root__2CnRm {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.EmailSetup_root__2CnRm p {
  font-size: 16px;
}

.EmailSetup_settlement_portal_title__gzjdH {
  display: flex;
  align-items: center;
}

.EmailSetup_email_icon__vzWVp {
  padding-right: 10px;
}

.EmailSetup_arrow_icon__3LRli {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.EmailSetup_email_setup_header__k001e {
  padding-bottom: 40px;
  width: 800px;
}

.EmailSetup_email_setup_dropdown__3URaF {
  min-width: 370px !important;
}

.EmailSetup_btn_select_email_setup__261GX {
  color: var(--color-theme-10) !important;
  border-color: var(--color-theme-10) !important;
  width: 370px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.EmailSetup_menu_item__9-FAM {
  display: flex;
  align-items: center;
}

.EmailSetup_menu_item__9-FAM p {
  margin: 2px;
  padding-right: 20px;
}

.EmailSetup_check_icon__IPkLa {
  padding-bottom: 3px;
  padding-left: 10px;
}

.EmailSetup_email_setup_description__2aaXO {
  padding-bottom: 20px;
}

.EmailSetup_select_setup_label__ScnNL {
  color: var(--color-theme-lightgray);
}

.CopyPasteEmailSetup_btn_container__2VDAq {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.CopyPasteEmailSetup_save_activate_btn__2332e {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin: 20px !important;
}

.SmtpSetup_input_container__wFn5v {
  width: 270px !important;
  padding-right: 40px !important;
}

.SmtpSetup_textContainer__3ZYis {
  width: 800px;
}

.SmtpSetup_input_row___eBzb {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.SmtpSetup_label__18Lro {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.SmtpSetup_label__18Lro b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.SmtpSetup_btn_container__1BWeq {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.SmtpSetup_btn_container_validate__2JMbP {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.SmtpSetup_validate_btn__1fcWJ {
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.SmtpSetup_save_activate_btn__1DWWm {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.SmtpSetup_circular_progress_container__3-ptG {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 60px;
}

.SmtpSetup_smtp_text__ZE6UK {
  padding-bottom: 20px;
}

/* TODO define a varible for the links */
.SmtpSetup_link__18ZcM {
  color: #ff6666;
}

.SmtpSetup_checkbox_container__36Ckv {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ValidateEmailSetup_error_message__2Dptn {
  color: var(--color-theme-red);
}

.InboundEmailSetup_inbound_text_container__3oz_2 {
  display: flex;
  flex-direction: column;
  width: 800px;
}

.InboundEmailSetup_header_row__yDoiL {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InboundEmailSetup_btn_container__1urEN {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.InboundEmailSetup_activate_btn__2k92e {
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.InboundEmailTestPopup_content_container__2D9m6 {
  text-align: center;
}
.InboundEmailTestPopup_icon__22zmp {
  color: var(--color-theme-blue);
}

.SpfSetup_spf_info_container__2E1de {
  padding-bottom: 10px;
}

.SpfSetup_spf_info_container__2E1de li {
  padding: 10px 0px;
}

.SpfSetup_input_container__rAhVG {
  width: 370px !important;
}

.SpfSetup_label__l8c-a {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.SpfSetup_label__l8c-a b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.SpfSetup_btn_container__3z-40 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.SpfSetup_btn_container_validate__2duag {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.SpfSetup_validate_btn__3lJqh {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.SpfSetup_save_activate_btn__1RCX6 {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.UserRow_root__2hWC9 {
  margin-bottom: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.UserRow_root_inactive__1ty9c {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.UserRow_root_active__1t-OJ {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.UserRow_root__2hWC9:hover {
  background-color: var(--color-bg-caselist-item-hover);
}

.UserRow_root_deactivated__3yIO_ {
  background-color: var(--color-theme-red);
  color: var(--color-theme-red);
}

.UserRow_left_container__3rtAc {
  width: 60px;
  text-align: center;
  min-width: 60px;
  margin-right: 10px;
}

.UserRow_user_role__2dTom {
  font-size: 10px;
  padding-top: 5px;
}

.UserRow_middle_container__2h2gX {
  width: 210px;
}

.UserRow_host_value__2ddK6 {
  width: 220px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.UserRow_user_name_container__qQpLZ {
  width: 220px;
  display: block;
  padding-top: 5px;
  font-weight: bold;
}

.UserRow_user_name_value__yb72G {
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
}

.UserRow_total_value__2bBEg {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
}

.UserRow_right_container__j7_I- {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.UserRow_right_icon_container__3y7fA {
  text-align: center;
}

.UserRow_callback_icon__1yWJY {
  color: var(--color-font-red);
}

.UserRow_callback_date__3bg7J {
  font-size: 10px;
  padding-top: 5px;
}

.UserRow_right_icon_container__3y7fA {
  text-align: center;
}

.UserRow_view_date__WKjgo {
  font-size: 10px;
  padding-top: 5px;
}

.UserRow_empty__2OtxI {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.UserRow_license_icon_container__Gw12w {
  display: inline-block;
  width: 45px;
  text-align: right;
}

.UserRow_license_icon__lKPee {
  color: var(--color-font-green);
}

.UserRow_license_icon__lKPee svg {
  height: 16px;
}

.UserRow_host_url__qE9AB {
  display: inline-block;
  width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.UserRow_curr_sign__3CEMY {
  font-size: 10px;
}

.UserRow_user_role__2dTom {
  padding: 0px;
}

.UserRow_user_role__2dTom {
  color: var(--color-font-10);
}

.UserRow_deactivated__13lNJ {
  color: var(--color-theme-deactivated);
  background-color: var(--color-theme-bright-gray);
}

.UserRow_pending__1SWsq {
  color: var(--color-theme-gray-white);
  background-color: var(--color-theme-white);
}

.UserRow_active__3FhVK {
  background-color: var(--color-theme-white);
}

.UserRow_selected__1WdGd {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.UserContent_root__11vro {
  border: 1px solid var(--color-theme-bright-gray);
}

.UserContent_user_header__1yjT8 {
  background-color: var(--color-theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
  border-bottom: 1px solid var(--color-theme-bright-gray);
}

.UserContent_user_header_deactivated__2xckV {
  background-color: var(--color-theme-bright-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
  color: var(--color-theme-deactivated);
}

.UserContent_user_header_pending__2gIQc {
  background-color: var(--color-theme-white);
  color: var(--color-theme-gray-white);
}

.UserContent_user_header_pending__2gIQc b {
  color: var(--color-font-10);
}

.UserContent_user_name_container__14i0L {
  width: 40%;
  display: flex;
}

.UserContent_user_name_container__14i0L h2 {
  margin-right: 30px;
}

.UserContent_icon_container__2LbNP {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.UserContent_icon_container__2LbNP div {
  font-size: 11px;
}

.UserContent_content_container__2mndR {
  padding: 40px 30px 30px 30px;
  border-top: 1px solid var(--color-theme-gray);
  display: flex;
  justify-content: space-between;
}

.UserContent_label__1Eg0U p {
  font-size: 13px !important;
  color: var(--color-theme-red);
  padding: 0px;
  margin: 0px;
}

.UserContent_btn_container__3dsMj {
  height: 330px;
  display: flex;
  align-items: flex-end;
  width: 50%;
  justify-content: space-between;
}

.UserContent_btn_save_and_send_invite__2nvBI {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.UserContent_btn_save_and_send_invite__2nvBI:disabled {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-gray-white) !important;
  border: 1px solid var(--color-theme-gray-white) !important;
  height: 40px;
}

.UserContent_btn_send_invite_again__aYpcl {
  width: 250px;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-10) !important;
  height: 40px;
}

.UserContent_btn_save_container__hjzBH {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.UserContent_btn_save__1xgnK {
  width: 170px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.UserContent_btn_delete__1kfxC {
  width: 200px;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-10) !important;
  height: 40px;
}

.UserContent_btn_cancel__2NGab {
  width: 120px;
  font-weight: 600 !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  height: 40px;
}

.UserContent_confirm_btn__KHn2p {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.UserContent_confirm_btn_disabled__zyUkx {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.UserContent_input_container__DSeuM {
  width: 370px !important;
  padding: 5px 0px;
}

.UserContent_accept_container__2phP7 {
  display: flex;
  align-items: flex-start;
  width: 250px;
  justify-content: space-between;
  margin: 20px 0px;
}

.UserContent_checkbox_label__1OGmN {
  font-size: 11px;
}

.UserManagement_root__2TCWm {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
}

.UserManagement_user_management_container__1co02 {
  display: flex;
}

.UserManagement_user_list_container__nfqTb {
  position: fixed;
  left: 210px;
  top: 91px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: calc(100vh - 140px);
}

.UserManagement_tabs_and_content_container__2MZKJ {
  width: 950px;
  overflow: hidden;
  margin-left: 310px;
  background-color: var(--color-bg-content);
}

.UserManagement_add_user_container__LZH6T {
  background-color: var(--color-bg-caselist);
  display: flex;
  justify-content: flex-end;
}

.UserManagement_btn_add_user__3iLY- {
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
  line-height: 0px !important;
}

.UserManagement_btn_add_user__3iLY-:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.UserManagement_plus_sign__4X9zB {
  margin-right: 10px;
  font-size: 20px;
}

.UserManagement_user_no_data__3fLou {
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}

.UserManagement_user_states_container__25vBY {
  display: flex;
  align-items: flex-end;
}

.UserManagement_user_state_col__3-KzS {
  padding-right: 20px;
}

.UserManagement_user_state_col__3-KzS p {
  padding: 0px;
  margin: 0px;
  font-size: 11px !important;
  cursor: default;
  line-height: 15px;
}

.Subscription_root__12Ayi {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px !important;
}

.Subscription_root__12Ayi p {
  font-size: 16px !important;
}

.Subscription_subscription_header__10mUa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: bold;
}

.Subscription_subscription_header_blue__2j9VU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: bold;
  color: var(--color-theme-blue);
}

.Subscription_right_row__1Y6FD {
  display: flex;
  align-items: center;
}

.Subscription_right_row__1Y6FD div {
  width: 170px;
}

.Subscription_left_row__I-CW3 {
  display: flex;
  align-items: center;
}

.Subscription_content_container__3ExeF {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.Subscription_content_container_blue__2roua {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  color: var(--color-theme-blue);
}

.Subscription_left_container__3NKog {
  width: 436px;
  padding: 40px;
}

.Subscription_features_header__2QZjG {
  font-weight: bold;
  padding-bottom: 10px;
}

.Subscription_list_item__h5QcY {
  display: flex;
  width: 360px;
  align-items: center;
}

.Subscription_list_item__h5QcY p {
  padding: 10px;
  font-weight: bold;
}

.Subscription_check_circle_icon__1R5HE {
  width: 14px !important;
  height: 14px !important;
}

.Subscription_right_container__2fb-y {
  background-color: var(--color-theme-gray);
  width: 100%;
  display: flex;
  padding: 40px;
}

.Subscription_right_container_lite__2RJXw {
  background-color: var(--color-theme-gray);
  width: 100%;
  display: flex;
  padding: 40px;
  justify-content: flex-end;
}

.Subscription_image_container__3_Hrr {
  display: flex;
}

.Subscription_pro_illustration__Nrn5D {
  width: 193px;
  height: 328px;
}

.Subscription_lite_illustration__1NDOb {
  margin-right: 40px;
  width: 118px;
  height: 265px;
}

.Subscription_enterprise_illustration__RfDW7 {
  width: 142px;
  height: 342px;
}

.Subscription_plan__1_e9n {
  font-weight: bold;
}

.Subscription_plus_circle__3uEd2 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 100px;
  left: 420px;
  background-color: var(--color-theme-white);
  border-radius: 100%;
}

.Subscription_rocket_icon__3W4F4 {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.Subscription_star_icon__zooC9 {
  width: 28px !important;
  height: 28px !important;
  margin-left: 10px;
}

.Subscription_star_icon_blue__2MsW- {
  width: 28px !important;
  height: 28px !important;
  margin-left: 10px;
  color: var(--color-theme-blue) !important;
}

.Subscription_camera_icon__1goQ9 {
  margin-left: 10px;
}

.Subscription_btn_upgrade__2fxBo {
  font-weight: bold !important;
  width: 255px;
  height: 37px;
  color: var(--color-theme-steelgray) !important;
  border: 1px solid var(--color-theme-steelgray) !important;
}

.Subscription_btn_upgrade__2fxBo:hover {
  font-weight: bold !important;
  width: 255px;
  height: 37px;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-black) !important;
}

.Subscription_btn_upgrade_blue__2D8Hw {
  font-weight: bold !important;
  width: 255px;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.Subscription_btn_upgrade_blue__2D8Hw:hover {
  font-weight: bold !important;
  height: 37px;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.Subscription_current_plan__Y9XEf {
  font-weight: bold;
}

.Subscription_check_icon__1IA5m {
  margin-left: 10px !important;
}

.Subscription_arrow_icon__3RjOb {
  width: 24px !important;
  height: 24px !important;
  color: var(--color-theme-gray-white);
}

.Subscription_info_text__ej6Fd {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  margin-top: 10px;

}
.Subscription_info_text__ej6Fd p {
  font-size: 13px !important;
  color: var(--color-theme-steelgray);
}

.PaymentSetup_root__yXOeL {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-font-10);
  font-size: 16px !important;
}

.PaymentSetup_account_title__1HuMo {
  display: flex;
  align-items: center;
}

.PaymentSetup_business_icon__2cfB9 {
  padding-right: 10px;
}

.PaymentSetup_arrow_icon__1rmXV {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.PaymentSetup_account_title__1HuMo {
  display: flex;
  align-items: center;
}

.PaymentSetup_business_icon__2cfB9 {
  padding-right: 10px;
}

.PaymentSetup_stripe_container__3bCul {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.PaymentSetup_text_container__skY4a {
  width: 50%;
  margin: 30px 0 0 40px;
}

.PaymentSetup_logo_container__3P_ut {
  width: 20%;
}

.PaymentSetup_confirm_btn__37fwF {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin-top: 30px !important;
}

.PaymentSetup_confirm_btn__37fwF a {
  text-decoration: none;
  color: var(--color-theme-blue);
}

.PaymentSetup_confirm_btn__37fwF:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.PaymentSetup_confirm_btn__37fwF:hover a {
  color: var(--color-theme-white) !important;
}

.PaymentSetup_connected_account__5IFP- {
  display: flex;
  align-items: center;
  color: var(--color-theme-green);
  padding: 20px 0px;
  font-weight: bold;
}

.PaymentSetup_connected_account__5IFP- p {
  font-size: 22px !important;
}

.PaymentSetup_check_circle_icon__1mUvW {
  margin-right: 10px;
}

.PaymentSetup_stripe_logo__2zYQD {
  width: 250px;
  height: 120px;
}

.PaymentReports_root__1m_Cq {
    padding: 15px 40px 90px 40px;
    height: 100vh;
    overflow: scroll;
    margin-right: auto;
    margin-left: auto;
    color: var(--color-font-10);
    font-size: 16px !important;
  }
  
  .PaymentReports_title__2O0Nu {
    display: flex;
    align-items: center;
  }
  
  .PaymentReports_icon__3v1zN {
    padding-right: 10px;
  }
  
  .PaymentReports_arrow_icon__2Hz9F {
    padding: 0px 20px;
    color: var(--color-theme-silver);
  }
  

  .PaymentReports_confirm_btn__TQ8MZ {
    color: var(--color-theme-blue) !important;
    border: 1px solid var(--color-theme-blue) !important;
    width: 176px;
    height: 41px;
    font-weight: bold !important;
    margin-top: 30px !important;
    margin-right: 30px !important;
  }
  
  .PaymentReports_confirm_btn__TQ8MZ a {
    text-decoration: none;
    color: var(--color-theme-blue);
  }
  
  .PaymentReports_confirm_btn__TQ8MZ:hover {
    color: var(--color-theme-white) !important;
    background-color: var(--color-theme-blue) !important;
  }
  
  .PaymentReports_confirm_btn__TQ8MZ:hover a {
    color: var(--color-theme-white) !important;
  }
  
  .PaymentReports_content_container__1S_f6 {
    width: 600px;
    padding-bottom: 80px;
  }

  .PaymentReports_date_input_container__SQX6P{
    width: 176px !important;
    display: inline-block;
    margin-right: 30px;
  }

  .PaymentReports_description__1yF6Z{
    padding-bottom: 20px;
  }

.InboundEmails_root__3CL45 {
  padding: 20px 20px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
}

.InboundEmails_list_container__SnUvd {
  position: fixed;
  left: 210px;
  top: 90px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  padding-top: 10px;
  overflow-x: hidden;
  height: calc(100vh - 148px);
}

.InboundEmails_list_item_container__1exe0 {
  margin: 5px;
  padding: 10px;
  max-height: 90px;
  min-height: 40px;
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
  cursor: pointer;
}

.InboundEmails_list_item_container_selected__1Bw8c {
  margin: 5px;
  padding: 10px;
  max-height: 90px;
  min-height: 40px;
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
  cursor: pointer;
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.InboundEmails_list_item_container__1exe0:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.InboundEmails_lower_row_gray__12dzg {
  color: #bbbbbb;
}

.InboundEmails_unread_marker__3D7ck {
  border-radius: 10px;
  width: 7px;
  height: 7px;
  background-color: royalblue;
  float: right;
}

.InboundEmailsDetail_detail_container__1I4NC {
  left: 300px;
  position: relative;
  display: flex;
  width: calc(100% - 300px);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  height: calc(100vh - 211px);
}

.InboundEmailsDetail_top_row__3SFZz {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 850px;
}

.InboundEmailsDetail_detail_row__2UmdL {
  background-color: white;
  max-width: 850px;
  border: 3px solid whitesmoke;
  
  min-width: 850px;
  padding: 8px
}

.InboundEmailsDetail_detail_scrollbar__2rCHk{
  margin-top: 36px !important;
}

.InboundEmailsDetail_btn_default__1N_Ky {
  color: var(--color-font-10) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px !important;
}

.InboundEmailsDetail_btn_default__1N_Ky:disabled {
  color: var(--color-font-10) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px !important;
  opacity: 0.6;
}

.InvoiceTemplates_root__1cqdl {
  width: 100%;
  color: var(--color-font-10);
  height: calc(100vh - 133px);
  display: flex;
}

.InvoiceTemplates_template_list_container__Nfk8R {
  width: 29px;
  min-width: 290px;
  background-color: var(--color-bg-caselist);
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: calc(100vh - 140px);
  z-index: 100;
  align-items: center;
}

.InvoiceTemplates_add_template_container__3x9HS {
  display: flex;
  justify-content: center;
}
.InvoiceTemplates_template_container__36-y6 {
  background-color: var(--color-bg-content);
  width: 78%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  overflow-y: scroll;
}

/* email row */
.InvoiceTemplateRow_invoice_template_row_root__3uq_M {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.InvoiceTemplateRow_invoice_template_row_root__3uq_M:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.InvoiceTemplateRow_root_inactive__iQFt0 {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.InvoiceTemplateRow_root_active__gj_yB {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.InvoiceTemplateRow_edit_icon_container__2sgyV {
  display: flex;
  justify-content: flex-end;
}

.InvoiceTemplateRow_edit_icon__3OovS {
  padding: 0px 10px;
}

.InvoiceTemplateRow_edit_icon__3OovS:hover {
  color: var(--color-font-10);
}

.InvoiceTemplateRow_input_container__1KTih {
  width: 65%;
  margin-left: 30px;
}

.InvoiceTemplateRow_template_list_item__2Tljf {
  padding: 0px !important;
}

.InvoiceTemplateRow_invoice_template_row_root__3uq_M:hover .InvoiceTemplateRow_edit_icon__3OovS {
  color: var(--color-font-10);
}

/* submenu */
.InvoiceTemplateRow_template_row_root__3LtyT {
  display: flex;
  color: var(--color-theme-10);

  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.InvoiceTemplateRow_template_row_root__3LtyT:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  color: var(--color-font-10) !important;
}

.InvoiceTemplateRow_template_row_root_selected__5CB4B {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 2px solid var(--color-bg-caselist-item-selected);
}

.InvoiceTemplateRow_drop_down__1qCF9 {
  z-index: 8000;
}

.InvoiceTemplateRow_status_item__3MHss {
  height: 45px;
  font-size: 12px;
}

.InvoiceTemplateRow_status_icon__CDI2m {
  margin: 0px 10px 0px 15px;
}

.InvoiceTemplateRow_status_icon__CDI2m svg {
  font-size: 20px !important;
}

.InvoiceTemplateRow_status_title__2z-ca {
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.InvoiceTemplateRow_delete_template__2tfXC {
  width: 100%;
  display: none;
  justify-content: flex-end;
  color: var(--color-theme-cloud);
}

.InvoiceTemplateRow_add_template_title__2n7W9 {
  color: var(--color-theme-silver);
}

.InvoiceTemplateRow_add_template_icon__N0zQZ {
  color: var(--color-theme-silver);
}

.InvoiceTemplateRow_template_row_root__3LtyT:hover .InvoiceTemplateRow_add_template_icon__N0zQZ {
  color: var(--color-font-10);
}

.InvoiceTemplateRow_template_row_root__3LtyT:hover .InvoiceTemplateRow_add_template_title__2n7W9 {
  color: var(--color-font-10);
}

.InvoiceTemplateRow_template_row_root__3LtyT:hover .InvoiceTemplateRow_delete_template__2tfXC {
  display: flex;
  color: var(--color-theme-cloud);
}

.InvoiceTemplateRow_template_row_root_selected__5CB4B .InvoiceTemplateRow_delete_template__2tfXC {
  display: flex;
  color: var(--color-theme-cloud);
}

.InvoiceTemplateRow_delete_icon__1cCkB:hover {
  color: var(--color-theme-blue);
}

.InvoiceTemplateRow_deletionPopupText__1xGJm {
  padding: 5px;
}

.InvoiceTemplateRow_button_confirm__1vWK9 {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.InvoiceTemplateRow_button_create__cTUin {
  width: 200px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.InvoiceTemplateRow_deletionPopupButtonContainer__35G_w {
  display: flex;
  margin-top: 36px;
  flex-direction: row-reverse;
}

.InvoiceTemplateEditor_editor_root__CirPG {
  width: 850px;
  padding: 15px;
  color: var(--color-font-10);

  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}


.InvoiceTemplateEditor_header__2Yuq3 {
  display: flex;
  justify-content: space-between;
}
.InvoiceTemplateEditor_sub_header_row__3MtgV {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InvoiceTemplateEditor_description__2vTeS {
  font-size: 10px !important;
  margin-top: 0px;
  font-style: italic;
}
.InvoiceTemplateEditor_template_action_container__3_z5R {
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}

.InvoiceTemplateEditor_preview_button__23yB1 {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 240px;
  height: 41px;
}
.InvoiceTemplateEditor_confirm_btn__21YNs {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 41px;
  width: 240px;

  font-weight: bold !important;
  margin: 5px 0px !important;
}
.InvoiceTemplateEditor_template_header__1dOM1 {
  width: 45%;
}
.InvoiceTemplateEditor_TwoInputs__8OJJc {
  display: flex;
  justify-content: space-between;
}
.InvoiceTemplateEditor_subInput__oyWY6 {
  width: 45% !important ;
}
.InvoiceTemplateEditor_block__1DpBZ {
  display: flex;
  justify-content: space-between;
}
.InvoiceTemplateEditor_left__2sdAv {
  width: 48%;
}
.InvoiceTemplateEditor_right__uT8Zc {
  width: 48%;
}
.InvoiceTemplateEditor_left3__HA0Tv {
  width: 32%;
}
.InvoiceTemplateEditor_right3__r8NeZ {
  width: 32%;
}
.InvoiceTemplateEditor_middle3__2dy_i {
  width: 32%;
}

.InvoiceManagement_root__iyVLq {
  padding: 15px 40px 90px 40px;
  height: 100vh;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-font-10);
  font-size: 16px !important;
}
.InvoiceManagement_title__2v9pq {
  display: flex;
  align-items: center;
}
.InvoiceManagement_icon__7eJ8R {
  padding-right: 10px;
}
.InvoiceManagement_loading_title__fWxMs {
  margin-left: 20px;
}

.InvoiceManagement_arrow_icon__1LI08 {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.InvoiceManagement_filter_row__3YhOu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.InvoiceManagement_date_picker__22XMA {
  width: 16% !important;
}
::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(0.2);
          filter: invert(0.2);
}
.InvoiceManagement_text_field__6XSJB {
  width: 18% !important;
}
.InvoiceManagement_select__3HuKK {
  width: 11% !important;
}
.InvoiceManagement_download_button__1QRx2 {
  width: 18% !important;
  height: 56px !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  border: 1px solid 1;
}
.InvoiceManagement_invoices_list__3PmA4 {
  padding: 0 20px;
  text-align: left;
}

.InvoiceManagement_headers_column__1pZ3L {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.InvoiceManagement_invoice_column__3lgUR {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.InvoiceManagement_more_icon__ryWGf {
  width: 6% !important;
  cursor: pointer;
}
.InvoiceManagement_more_container__2u7M- {
  z-index: 8000;
}

.Dashboard_root__1vkhm {
  padding: 15px 40px 90px 40px;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  height: 80vh;
  color: var(--color-font-10);
  font-size: 16px !important;
  /* overflow-y: scroll !important; */
}

.Dashboard_upper__1AikW {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2%;
}

.Dashboard_lower__1a038 {
  margin-top: 2%;
}

.Dashboard_settings_icon__1n7g5 {
  width: 2%;
  margin: 1%;
  float: right;
  cursor: pointer;
}
.Dashboard_settings_icon__1n7g5:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.Dashboard_loading_title__3xez8 {
  position: fixed;
  left: 50%;
}

.Dashboard_loading_label__3AqM_ {
  position: absolute;
  left: 48%;
  margin-top: 75px;
}

.Dashboard_kpi_container__3catm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 99%;
  min-height: 150px;
}

.Dashboard_kpi_item__1_VOU {
  width: 20%;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 3%;
  cursor: pointer;
}

.Dashboard_kpi_value__3C104 {
  font-weight: bold;
  font-size: 35px !important;
  margin-bottom: 0% !important;
  margin-top: 4% !important;
}

.Dashboard_kpi_name__2wV8n {
  margin-top: 0% !important;
  margin-bottom: 8% !important;
}

.Dashboard_chart_filters__x1Wc1 {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.Dashboard_chart_filter__27U_1 {
  /* width: 15%; */
  margin: 0 2%;
}
.Dashboard_date_chart_filters__gP-PG {
  display: flex;
  margin-right: 3%;
  /* width: 100%; */
}
.Dashboard_date_chart_filter__OOXXT {
  margin-right: 6px;
}

.Dashboard_charts_view___omtF {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-x: scroll;
  /* min-width: 1450px; */
  align-items: center;
  justify-content: space-between;
}

.Dashboard_bottom_disclaimer__1GUgI {
  margin: auto;
  margin-top: 3%;
  text-align: center;
}

.DashboardSettingsPopup_root__saJ6S {
  max-width: 100% !important;
}

.DashboardSettingsPopup_filter_group__2gnwW {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashboardSettingsPopup_filter_group_item__rLHEx {
  width: 30%;
}

.DashboardSettingsPopup_kpi_settings_container__3GZBJ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.DashboardSettingsPopup_kpi_item__1wJxX {
  display: flex;
  width: 20%;
  /* width: 30%; */
  height: 10vh;
  /* padding-bottom: 5%; */
  /* align-content: center; */
  align-items: center;
  text-align: center;
  padding-left: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;

  /* max-width: 30% !important; */
  /* margin-right: 3.2%; */
}

.DashboardSettingsPopup_kpi_item__1wJxX:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.DashboardSettingsPopup_kpi_value__qwkLh {
  max-width: 90px;
  word-wrap: break-word;
}

.DashboardSettingsPopup_add_kpi_text__3_FQM {
  margin: auto;
  color: rgb(171, 171, 171);
}

.DashboardSettingsPopup_chart_settings_row__3yk3I {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.DashboardSettingsPopup_kpi_clear_icon__2o82x {
  /* max-width: 2%; */

  margin-top: 35px;
  margin-left: 2px;
  /* margin-right: 5px; */
  cursor: pointer;
}

.DashboardSettingsPopup_chart_setting__3oLhe {
  width: 30%;
}

.DashboardSettingsPopup_chart_currency_filter__2C6L7 {
  width: 15%;
}
.DashboardSettingsPopup_chart_clear_icon__2H2rr {
  width: 5%;
  cursor: pointer;
}

.DashboardSettingsPopup_add_chart_button__2RLno {
  border: solid 0.5px black !important;
}

.DashboardSettingsPopup_loading__eer5_ {
  margin: auto 50px;
}

.KpisSettingsPopup_select_kpi__1nALg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.KpisSettingsPopup_text_field__2hJ63 {
  width: 35%;
  height: 80px;
}

.BarChartBlock_charts_view__2CZEv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.BarChartBlock_chart__198MP {
  margin-top: 3%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 3px 15px;
}

.BarChartBlock_chart_title__14qnw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.BarChartBlock_chart_title_filter__zEu4X {
  width: 30%;
}

.BarChartBlock_chart_currency_filter__3Fyxh {
  width: 15%;
}

.BarChartBlock_custom_tooltip__mBRSp {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 300px;
  overflow: scroll;
  pointer-events: all !important;
}

.BarChartBlock_custom_tooltip_label__2aWmv {
  margin-top: 0 !important;
  margin-bottom: 3px !important;
}

.BarChartBlock_tooltip_entry__1arPD {
  margin-top: 2px !important;
  margin-bottom: 0 !important;
}

.BarChartBlock_custom_tooltip_total__142u0 {
  font-style: oblique;
  margin-top: 3px !important;
  margin-bottom: 0 !important;
}

.BarChartBlock_year_label__3nMYY {
  font-size: 12px;
}

.Login_login__3hzQ7 {
  background-color: var(--color-bg-login);
  width: 100vw;
  height: 100vh;
}

.Login_loginFormContainer__3RpPj {
  width: 448px;
  min-height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25vh; 
  left: 50vw; 
  -webkit-transform: translate(-50%, -50%); 
          transform: translate(-50%, -50%);
}

.Login_loginFormHeader__31eQa {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.Login_loginForm__2qTQa {
  margin: 40px;
}

.Login_input_container__3Qvuf {
  width: 100% !important;
  margin: 10px 0px;
}

.Login_password_input__3SIB2 {
  width: 100% !important;
  margin-top: 20px !important;
}

.Login_btnContainer__1NuM0 {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.Login_button__1C26n {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
  margin-right: 55px;
}

.Login_button__1C26n:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.Login_inputError__1OIBG {
  margin-top: 5px;
  color: var(--color-font-red);
  font-size: 12px;
}

.Login_loginError__Zgww1 {
  margin: 12px;
  font-size: 16px;
  color: var(--color-theme-red);
}

.Login_link__2TkGJ {
  color: var(--color-font-10);
  padding: 32px;
  font-size: 16px;
}

.Login_forgot_password_container__3pQZT {
  color: var(--color-theme-blue);
  font-size: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.Login_forgot_password_gray__1MEmf {
  color: var(--color-theme-lightgray);

  font-size: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.Login_error_message__1hvqK {
  color: var(--color-theme-red);
}

/* email unverified */
.Login_email_unverified__RCO9e {
  width: 448px;
  height: 179px;
  background-color: var(--color-theme-blue);
  color: var(--color-theme-white);
}

.Login_email_unverified_content__1vJfX {
  padding: 15px;
}

.Login_unverified_info_text__3x2Yh {
  display: flex;
}

.Login_email_unverified_content__1vJfX p {
  font-weight: bold;
}

.Login_resend_btn_container__1HCcx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_resend_btn__3Vraj {
  color: var(--color-theme-white) !important;
  border: 1px solid var(--color-theme-white) !important;
  width: 336px;
  height: 41px;
  font-weight: bold !important;
  margin: 10px !important;
}

.Login_resend_email_loading__2dzl8 {
  color: var(--color-theme-white) !important;
  margin: 10px;
}

.Login_resend_email_success__3Wo8t {
  padding: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.Login_resend_email_success__3Wo8t p {
  width: 100px;
  padding-left: 20px;
}

.Login_verification_success_container__1Oept {
  background-color: var(--color-theme-success-green);
  color: var(--color-theme-white);
  display: flex;
  height: 55px;
  margin-top: 20px;
  align-items: center;
  font-weight: bold;
}

.Login_check_icon__UJYfT {
  padding: 0px 10px 0px 20px;
}

.Login_info_icon__1116i {
  margin: 3px 10px 0 10px;
}

.EnterEmail_loginFormContainer__7E8HX {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
}

.EnterEmail_loginFormHeader__3TJJu {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.EnterEmail_loginForm__2Qyrt {
  margin: 40px;
}

.EnterEmail_input_container__25mxY {
  width: 100% !important;
  margin: 10px 0px;
}

.EnterEmail_two_buttons_container__3oTs2 {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.EnterEmail_button__2hs0l {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.EnterEmail_button__2hs0l:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.EnterEmail_button_cancel__2gu00 {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-deactivated) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-deactivated) !important;
  cursor: pointer;
  font-size: 16px;
}

.EnterEmail_button_cancel__2gu00:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-deactivated) !important;
}

.LinkSent_loginFormContainer__3mWcF {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
}

.LinkSent_loginFormHeader__1Nx8t {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.LinkSent_loginForm__v9qgs {
  margin: 40px;
}

.LinkSent_info_icon__3Hi9H {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-blue);
}

.LinkSent_link_sent_content__29hqb {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.LinkSent_go_to_login_button__1EzLY {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.LinkSent_go_to_login_button__1EzLY:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.LinkSent_go_to_login_button_gray__25M3R {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-deactivated) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-deactivated) !important;
  cursor: pointer;
  font-size: 16px;
}

.LinkSent_go_to_login_button_gray__25M3R:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-deactivated) !important;
}

.LinkSent_error_message__26mdG {
  color: var(--color-theme-red);
}

.ChangePassword_loginFormHeader__17L2u {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.ChangePassword_loginForm__3WzIT {
  margin: 40px;
}

.ChangePassword_input_container__3CM8r {
  width: 100% !important;
  margin: 10px 0px;
}

.ChangePassword_password_requirements__22FWo {
  margin-top: 10px;
  font-size: 12px;
}

.ChangePassword_password_requirements_error__1SFCQ {
  margin-top: 10px;
  font-size: 12px;
  color: var(--color-theme-red);
}

.ChangePassword_btnContainer__2RQzp {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.ChangePassword_button__2DeLy {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.ChangePassword_button__2DeLy:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.PasswordChanged_loginFormHeader__37_f2 {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.PasswordChanged_loginForm__1qD9e {
  margin: 40px;
}

.PasswordChanged_link_sent_content__W94pn {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.PasswordChanged_success_icon__QNq1y {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-green);
}

.PasswordChanged_go_to_login_button__2hOC8 {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.PasswordChanged_go_to_login_button__2hOC8:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.Signup_signup__3VM2Z {
  background-color: var(--color-bg-login);
  width: 100vw;
  height: 100vh;
}

.Signup_signupFormContainer__1bmBL {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25vh; 
  left: 50vw; 
  -webkit-transform: translate(-50%, -50%); 
          transform: translate(-50%, -50%);
}

.Signup_signupFormHeader__1WT5Y {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}

.Signup_signupForm__2_SbK {
  margin: 40px;
}

.Signup_email_container__1mmuS {
  margin-bottom: 10px;
}

.Signup_input_container__76sxs {
  width: 100% !important;
  margin-bottom: 5px;
}

.Signup_password_input__2g0xv {
  width: 100% !important;
  margin-top: 20px !important;
}

.Signup_btnContainer__2OI_6 {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.Signup_signupBtn__3JMA0 {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue);
  border-radius: 4px;
  background-color: var(--color-theme-white);
  color: var(--color-theme-blue);
  cursor: pointer;
  font-size: 16px;
}

.Signup_signupBtn__3JMA0:hover {
  color: var(--color-theme-white);
  background-color: var(--color-theme-blue);
}

.Signup_inputError__3DWsN {
  margin-top: 5px;
  color: var(--color-font-red);
  font-size: 12px;
}

.Signup_signupError__3-NxY {
  padding-top: 6px;
  text-align: center;
  color: var(--color-font-red);
  font-size: 10px;
  font-weight: bold;
}

.Signup_link__pn915 {
  color: var(--color-font-10);
  padding: 32px;
  font-size: 16px;
}

.Signup_password_requirements__AbMDW {
  margin-top: 10px;
  font-size: 12px;
}

.Signup_password_requirements_error__-dS1V {
  margin-top: 10px;
  font-size: 12px;
  color: var(--color-theme-red);
}

.ExpiredSignup_container__3UGVf {
  width: 448px;
  height: 455px;
  padding: 40px;
  box-sizing: border-box;
}

.ExpiredSignup_warning_container__8r35N {
  width: 100%;
  height: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.ExpiredSignup_warning_icon__3EuYc {
  color: var(--color-theme-yellow);
  width: 54px !important;
  height: 47px !important;
}

.ExpiredSignup_signup_header__3yiN6 {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.ExpiredSignup_copy__YjY6G {
  font-size: 22px !important;
}

.AccountCreation_accountCreation__3kqz9 {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg-login);
}

.AccountCreation_accountCreationContainer__2DtrW {
  width: 880px;
  height: 690px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  padding: 20px 60px 0 60px;
  background-color: var(--color-bg-login-box);
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
}

.AccountCreation_accountCreationHeader__2OJZa {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 35px;
  color: var(--color-font-2);
}

@media screen and (min-width: 1600px) {
  .AccountCreation_accountCreationContainer__2DtrW {
    position: fixed;
    top: 35vh;
    left: 50vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1600px) {
  .AccountCreation_accountCreationContainer__2DtrW {
    padding-bottom: 40px;
  }
}

.Stepper_stepperContainer__Qp5jq {
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Stepper_dot__1zF2g {
  height: 16px;
  width: 16px;
  padding: 6px;
  line-height: 16px;
  background-color: #e8e8e8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin-right: 5px;
}

.Stepper_dotSelected__1aZP0 {
  height: 16px;
  width: 16px;

  padding: 6px;
  line-height: 16px;
  background-color: var(--color-theme-lightblue);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: var(--color-font-blue);
  font-weight: bold;
  margin-right: 5px;
}

.Stepper_stepContainer__1Z5LZ {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Stepper_stepContainerSelected__2X6Gx {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--color-theme-blue);
}

.Stepper_fontSelected__30bQ9 {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-theme-blue);
}

.PersonalInformation_personalInformationContainer__2UshP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 470px;
}

.PersonalInformation_personalInformationInput__3Bkz5 {
  max-width: 370px;
  height: 56px;
}

.PersonalInformation_personalInformationFormContainer__1ZiLG {
  display: flex;
  flex-direction: column;
  max-width: 370px;
}

.PersonalInformation_personalInformationInputPasswordField__zmERH {
  margin-bottom: 50px;
  max-width: 370px;
  height: 56px;
}

.PersonalInformation_personalImg__2yjDG {
  max-width: 50%;
}

.PersonalInformation_formContainer__ksLVC {
  margin-bottom: 50px;
}
.PersonalInformation_requiredTag__2mCPe {
  text-align: right;
}

.PersonalInformation_requiredRed__1KAtP {
  color: var(--color-font-red);
}



.Footer_footerContainer__m6lHU {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.BusinessInformation_businessInformationContainer__2sejV {
  width: 100%;
  height: 470px;
}

.BusinessInformation_businessInformationFormContainer__mFavp {
  height: 430px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BusinessInformation_businessInformationInput__2ZJkU {
  max-width: 370px;
  height: 56px;
}

.BusinessInformation_businessInformationRightFormContainer__29cru {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.BusinessInformation_businessInformationInputPasswordField__3SbFc {
  margin-bottom: 50px;
}

.BusinessInformation_businessImgage__2f8Y0 {
  margin-top: 20px;
  width: 370px;
}

.BusinessInformation_formContainer__235Cu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.BusinessInformation_requiredTag__k4vsS {
  text-align: right;
}

.BusinessInformation_requiredRed__1nSVU {
  color: var(--color-font-red);
}

.BusinessInformation_businessInformationLeftFormContainer__2Gn4T {
  width: 100%;
  height: 430px;
  display: flex;
  flex-direction: column;
}

.BusinessInformation_businessInformationDropdown__3ipcZ {
  width: 370px !important;
  margin-bottom: 25px;
}

.BusinessInformation_doubleInput__5nxIa {
  width: 370px;
  display: flex;
  justify-content: space-between;
}
.BusinessInformation_businessInformationCopy__eMybn {
  font-size: 16px !important;
}


.TermsOfService_termsOfServiceContainer__1-V4g {
  background-color: var(--color-theme-snow);
  padding: 12px 10px;
  color: var(--color-font-0);
  height: 470px;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.TermsOfService_iframe__1yV5q {
  width: 100%;
  height: 100%;
  /* background-color: var(--color-theme-snow); */
  color: var(--color-font-0);
  border: none;
}

.TermsOfService_accept_container__2vNXu {
  background-color: rgba(240, 240, 240, 0.95);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@media screen and (max-width: 1600px) {
  .TermsOfService_termsOfServiceContainer__1-V4g {
    height: 350px;
  }
}

.WelcomeToFairLicensing_welcomeToFairLicensingContainer__HixQj {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 470px;
  padding: 95px;
  box-sizing: border-box;
}

.WelcomeToFairLicensing_welcomeToFairLicensingTextContainer__3wjjX {
  max-width: 370px;
}

.WelcomeToFairLicensing_welcomeToFairLicensingTitle__1Hd8u {
  font-size: 22px !important;
  font-weight: bold;
  margin-bottom: 30px !important;
}

.WelcomeToFairLicensing_welcomeToFairLicensingSubtitle__1wSPv {
  font-size: 22px !important;
  margin-bottom: 30px !important;
}

.WelcomeToFairLicensing_welcomeToFairLicensingEmail__4ERol {
  color: var(--color-font-blue);
}

.WelcomeToFairLicensing_welcomeToFairLicensingCopy__3CQMY {
  font-size: 16px;
}

.WelcomeToFairLicensing_welcomeToFairLicensingImage__16wxN {
  height: 180px;
  margin: 0 !important;
}
.ErrorAccountCreation_errorAccountCreationContainer__3Kd2I {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 470px;
  background-color: #f7e8e8;
}

.ErrorAccountCreation_errorAccountCreationImage__13IrQ {
  height: 220px;
}

.ErrorAccountCreation_errorAccountCreationTextContainer__3cUdj {
  max-width: 370px;
}

.ErrorAccountCreation_errorAccountCreationTitle__uyYOv {
  font-size: 22px !important;
  font-weight: bold;
  margin-bottom: 30px !important;
}

.ErrorAccountCreation_errorAccountCreationSubtitle__2TFra {
  font-size: 22px !important;
}

.ErrorAccountCreation_errorAccountCreationEmail__1RzZ4 {
  color: var(--color-font-blue);
}

.ErrorAccountCreation_errorAccountCreationCopy__333i6 {
  margin-bottom: 30px !important;
  font-size: 16px;
}

.PrivacyPolicy_privacy_policy__2K0Vi {
  max-width: 1125px;
  padding: 20px;
  font-family: 'Calibri', sans-serif;
  text-align: justify;
}

.PrivacyPolicy_privacy_policy_header__1Ji65 {
  font-size: 22px;
  font-weight: bold;
  padding: 20px 0px;
}

h1 {
  font-size: 11pt;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 11pt !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* standard for all popups */
.action_container {
  text-align: right;
  margin-top: 20px;
}

.btn_confirm {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  width: 150px;
  margin-left: 15px !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.btn_cancel {
  font-weight: bold !important;
  width: 150px;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.btn_activate {
  font-weight: bold !important;
  width: 150px;
  margin-left: 15px !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}
/* standard for all popups */
.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: red !important;
}

.input[type="date"]::-webkit-calendar-picker-indicator {
  color: black;
  background-color: yellow;
  border: solid;
  border-color: pink;
}

.btn_disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border-color: rgba(0, 0, 0, 0.26) !important;
  cursor: default;
  pointer-events: none;
}

