.root {
  max-width: 2000px;
  margin-right: 10px;
  padding: 15px;
  color: var(--color-font-10);
  overflow: scroll;
  height: 100%;
}

.confirm_btn_container {
  display: flex;
  justify-content: space-between;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 220px;
  height: 41px;
  font-weight: bold !important;
  margin: 5px 0px !important;
}

.btn_cancel {
  width: 220px !important;
}

.template_action_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 11px;
}
.template_name_container {
  width: 35%;
}

.template_days_container {
  width: 20%;
}

.template_container {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  height: 65vh;
}

.placeholder_col {
  border: 0.5px solid;
  width: 230px;
  border-radius: 4px;
  border-color: var(--color-theme-gray-white);
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 100%;
}
.placeholder_info_container {
  padding: 10px 0px;
  overflow-y: auto;
  background-color: var(--color-bg-caselist);
  border-radius: 4px;
  width: 100%;
}

.placeholder_scrollbar {
  width: 86% !important;
}

.placeholder_info_container > * {
  margin-left: 10% !important;
}

.template_col {
  width: 80%;
  margin-right: 10px;
  z-index: 2;
}

.template_text_content::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.template_text_content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.placeholder_info_container::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.placeholder_info_container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

/* placeholders */
.placeholder_group {
  margin: 7px;
}

.placeholder_item p {
  font-size: 14px !important;
  display: inline-block;
  clear: both;
  border: 1px solid var(--color-theme-cloud);
  border-radius: 15px;
  padding: 0px 10px;
  margin: 5px;
}

.placeholder_enabled p {
  color: var(--color-theme-blue);
}

.placeholder_item p:hover {
  background-color: var(--color-theme-lightblue);
  border: 1px solid var(--color-theme-blue);
}

.tooltip_title {
  font-size: 13px !important;
}

.placeholder_disabled p {
  color: var(--color-theme-cloud);
}
