.container {
  width: 448px;
  height: 455px;
  padding: 40px;
  box-sizing: border-box;
}

.warning_container {
  width: 100%;
  height: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.warning_icon {
  color: var(--color-theme-yellow);
  width: 54px !important;
  height: 47px !important;
}

.signup_header {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.copy {
  font-size: 22px !important;
}
