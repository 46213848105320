.root {
  animation-name: heigthChangeBack;
  animation-duration: 700ms;
  position: relative;
  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.rootFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 8px 8px;
}

.rootAnimation {
  animation-name: heigthChange;
  animation-duration: 1500ms;
  overflow: hidden;

  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.left_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 115px;
}

.left_timestamp {
  font-size: 10px;
}

.middle_container {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 165px;
}

.middle_type {
  font-weight: bold;
  margin-bottom: 7px;
}

.middle_user {
  font-size: 12px;
  font-weight: bold;
}
.right_container {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 474px;
}

.right_container_opener {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.btn_confirm {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  margin-top: 10px;
}

.unread_marker {
  border-radius: 10px;
  width: 7px;
  /* z-index: 20; */
  height: 7px;
  background-color: royalblue;
  position: relative;
  top: -29px;
  left: 25px;
  margin-bottom: -7px;
}

.btn_default {
  color: var(--color-font-10) !important;

  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px;
  margin-right: 10px !important;
}

.btn_default_invisible {
  color: var(--color-font-10) !important;

  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px;
  margin-right: 10px !important;
  visibility: hidden;
}
.notePopupTextContainer {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}

.delete_shopping_icon {
  cursor: pointer;
}

.delete_icon {
  cursor: pointer;
}

.delete_icon_disabled {
  color: darkgray;
}

.delete_icon:hover {
  color: var(--color-theme-blue);
}

.edit_icon {
  cursor: pointer;
}

.notePopupTextContainer::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.notePopupTextContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.notePopupActionsContainer {
  display: flex;
  flex-direction: row-reverse;
}

.gray {
  rotate: 180deg;
}

.btn_confirm {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.btn_confirm:hover {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.btn_cancel {
  width: 100px !important;
  border: 1px solid var(--color-font-10) !important;
  color: var(--color-font-10) !important;
}

.btn_cancel:hover {
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-white) !important;
  background-color: var(--color-font-10) !important;
}

.dialogContent {
  margin-left: 5px;
  margin-bottom: 30px;
}

.mailContainer {
  border-top: 1px solid var(--color-theme-cloud);
  margin-top: 14px;
}

.email_tools {
  background: rgb(0 0 0 / 3%);
  position: relative;

  border-bottom: 1px solid lightgray;
  padding: 4px 4px;
  width: calc(100% + 8px);
}

.email_resend_container {
  /* background: rgb(0 0 0 / 3%); */
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid lightgray;
  padding: 4px 0px 4px 4px;
  width: calc(100% + 8px);
  right: 8px;
}
.resend_text {
  /* color: var(--color-theme-yellow); */
  color: rgb(233, 170, 12);
  margin: auto 0 auto 10px;
}

.warning_icon {
  color: var(--color-theme-yellow);
}

.scheduled_text_container {
  padding: 8px;
}

.attachement_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.attachment_button_container {
  position: absolute;
  right: 0px;
  top: 4px;
}

@keyframes heigthChange {
  from {
    max-height: 56px;
  }
  to {
    max-height: 4000px;
  }
}

@keyframes heigthChangeBack {
  from {
    max-height: 900px;
  }
  to {
    max-height: 56px;
  }
}
