.popup_body {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}
.image_option {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
}
.image_check {
  width: 10%;
}
.image_text {
  width: 100%;
}
.left_body {
  width: 30%;
}
.right_body {
  width: 30%;
}
.subheader {
  margin-top: 10px;
  margin-bottom: 10px;
}
.explainatory_text {
  margin-top: 0 !important;
}
