.body {
  display: flex;
  justify-content: space-evenly;
  flex: start;
}
.block {
  width: 90%;
  margin-bottom: 10px;
}

.block_title {
  margin-top: 2px;
  margin-bottom: 2px;
}

.block_body {
  display: flex;
  align-items: center;
  border: 2px solid lightgray;
  border-radius: 6px;
  padding: 5px;
}
.block_inputs {
  /* display: flex; */
  width: 90%;
  margin: 0;
}
.block_icon {
  margin-left: 3%;
}
.block_label {
  margin-right: 12%;
  width: 8%;
}
