.date {
  font-weight: bold;
  padding: 15px;
  background-color: var(--color-theme-red);
}

.name {
  font-weight: bold;
  padding: 15px 15px;
}

.role {
  font-size: 14px;
  padding: 5px 15px;
}

.email {
  font-size: 14px;
  padding: 5px 15px;
}

.phone {
  font-weight: bold;
  padding: 15px 15px;
}

.note {
  border: 1px solid var(--color-theme-gray);
  padding: 15px;
  background-color: var(--color-theme-silver);
}
