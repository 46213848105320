.root {
  padding: 25px 0;
  width: 100%;
  background-color: var(--color-bg-header);
  color: var(--color-font-10);
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  border: 1px solid var(--color-theme-cloud);
  align-items: center;
}

.logo {
  width: 180px;
  font-size: 27px;
  font-weight: 900;
  padding: 10px;
}
