.content_container {
}

/* upload section */
.upload_container {
  display: flex;
  margin: 10px 0px;
}

.image_box {
  width: 175px;
  height: 120px;
  border: 1px solid var(--color-theme-silver);
  margin: 0px 10px 10px 0px;
  border-radius: 2px;
  overflow: scroll;
}

.image {
  width: 100%;
  object-fit: cover;
}

.upload_actions_container {
  display: flex;
  flex-direction: column;
}

.upload_label {
  border: 1px solid var(--color-theme-blue);
  border-radius: 15px;
  padding: 2px 9px;
  cursor: pointer;
  margin: 5px;
  text-align: center;
  color: var(--color-theme-blue);
}

.upload_label:hover {
  background-color: var(--color-theme-lightblue);
}

.upload_input {
  display: none;
}

.upload_btn {
  width: 120px;
  height: 28px;
  font-weight: bold !important;
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  margin: 5px !important;
}

.delete_icon {
  margin: 5px;
  cursor: pointer;
}
/* upload section */

.finding_col {
  padding: 15px 0px 0px 0px;
}

.artist_agency_row {
  display: flex;
}

.error_container {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.error_text {
  font-size: 16px;
  color: var(--color-theme-white);
}

.input_row {
  margin-right: 10px !important;
}

.requiredRed {
  color: var(--color-font-red);
}
