.signup {
  background-color: var(--color-bg-login);
  width: 100vw;
  height: 100vh;
}

.signupFormContainer {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 25vh; 
  left: 50vw; 
  transform: translate(-50%, -50%);
}

.signupFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}

.signupForm {
  margin: 40px;
}

.email_container {
  margin-bottom: 10px;
}

.input_container {
  width: 100% !important;
  margin-bottom: 5px;
}

.password_input {
  width: 100% !important;
  margin-top: 20px !important;
}

.btnContainer {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.signupBtn {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue);
  border-radius: 4px;
  background-color: var(--color-theme-white);
  color: var(--color-theme-blue);
  cursor: pointer;
  font-size: 16px;
}

.signupBtn:hover {
  color: var(--color-theme-white);
  background-color: var(--color-theme-blue);
}

.inputError {
  margin-top: 5px;
  color: var(--color-font-red);
  font-size: 12px;
}

.signupError {
  padding-top: 6px;
  text-align: center;
  color: var(--color-font-red);
  font-size: 10px;
  font-weight: bold;
}

.link {
  color: var(--color-font-10);
  padding: 32px;
  font-size: 16px;
}

.password_requirements {
  margin-top: 10px;
  font-size: 12px;
}

.password_requirements_error {
  margin-top: 10px;
  font-size: 12px;
  color: var(--color-theme-red);
}
