.loginFormContainer {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
}

.loginFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.loginForm {
  margin: 40px;
}

.input_container {
  width: 100% !important;
  margin: 10px 0px;
}

.two_buttons_container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.button {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.button_cancel {
  width: 174px;
  height: 41px;
  border: 1px solid var(--color-theme-deactivated) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-deactivated) !important;
  cursor: pointer;
  font-size: 16px;
}

.button_cancel:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-deactivated) !important;
}
