.root {
  display: flex;
  background-color: var(--color-theme-white);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid var(--color-theme-bright-gray);
}

.icon_container {
  background-color: var(--color-theme-blue);
  width: 55px;
  text-align: center;
  padding-top: 12px;
  border-radius: 10px;
}

.left_container {
  flex: 1;
  min-width: 265px;
}

.title_icon {
  color: var(--color-theme-white);
}

.title_icon svg {
  height: 25px;
  width: 30px;
}

.title_container {
  display: flex;
}

.title_text {
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
  line-height: 33px;
}

.date {
  font-size: 12px;
  padding-left: 15px;
}

.center_container {
  flex: 2;
  min-width: 250px;
}

.center_primary {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 6px;
}

.center_secondary {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.center_secondary {
  margin-top: 3px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.email {
  font-size: 12px;
  margin-top: 4px;
}

.state {
  padding-left: 37px;
  margin-top: 26px;
  font-size: 15px;
}

.right_container {
  flex: 3;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.right_container_disabled {
  flex: 3;
  max-width: 360px;
  text-align: center;
  padding-top: 15px;
}

.right_container_disabled svg {
  color: var(--color-theme-silver);
}
