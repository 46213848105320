.stepperContainer {
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dot {
  height: 16px;
  width: 16px;
  padding: 6px;
  line-height: 16px;
  background-color: #e8e8e8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin-right: 5px;
}

.dotSelected {
  height: 16px;
  width: 16px;

  padding: 6px;
  line-height: 16px;
  background-color: var(--color-theme-lightblue);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: var(--color-font-blue);
  font-weight: bold;
  margin-right: 5px;
}

.stepContainer {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepContainerSelected {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--color-theme-blue);
}

.fontSelected {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-theme-blue);
}
