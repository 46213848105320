.root {
  padding: 15px 40px 90px 40px;
  height: 100vh;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-font-10);
  font-size: 16px !important;
}
.title {
  display: flex;
  align-items: center;
}
.icon {
  padding-right: 10px;
}
.loading_title {
  margin-left: 20px;
}

.arrow_icon {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.filter_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.date_picker {
  width: 16% !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(0.2);
}
.text_field {
  width: 18% !important;
}
.select {
  width: 11% !important;
}
.download_button {
  width: 18% !important;
  height: 56px !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  border: 1px solid 1;
}
.invoices_list {
  padding: 0 20px;
  text-align: left;
}

.headers_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoice_column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.more_icon {
  width: 6% !important;
  cursor: pointer;
}
.more_container {
  z-index: 8000;
}
