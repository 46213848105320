.root {
  border: 1px solid var(--color-theme-bright-gray);
}

.language_header {
  background-color: var(--color-theme-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 1px;
}

.language_name_container {
  width: 18%;
  display: flex;
  justify-content: space-between;
}
.language_title {
  width: 50%;
}

.btn_default {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
  margin-right: 20px !important;
}

.btn_default:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
  margin-right: 20px !important;
}

.btn_activate {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.btn_activate:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
}

.btn_deactivate {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-silver) !important;
  height: 40px;
}

.btn_deactivate:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  background-color: var(--color-theme-silver) !important;
  height: 40px;
}

.tabs_content_container {
  padding: 40px 30px 30px 30px;
  border-top: 1px solid var(--color-theme-gray);
}

.label {
  padding: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.label b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.confirm_btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0px;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.confirm_btn_disabled {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-silver) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  cursor: auto !important;
}

.text_description {
  font-size: 14px;
}

/* faq tab */
.add_faq_btn_container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
}

.add_faq_btn {
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-10) !important;
}

.label_faq {
  padding-bottom: 10px;
  font-size: 16px;
}

.faq_container {
  display: flex;
  border: 1px solid var(--color-theme-silver);
  align-items: top;
  padding: 10px;
  margin: 10px 0px;
}

.faq_input_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.delete_icon {
  cursor: pointer;
}
