.root {
  width: 100%;
}

.hide_case_list {
  display: none;
}

.case_list_container {
  position: fixed;
  left: 210px;
  top: 110px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  padding-top: 60px;
  overflow-x: hidden;
  height: -webkit-calc(100vh - 103px);
  height: -moz-calc(100vh - 103px);
  height: calc(100vh - 103px);
}

.case_details_container {
  overflow: scroll;
  margin-left: 310px;
  background-color: var(--color-bg-content);
  overflow: scroll;
  height: calc(100vh - 107px);
  padding-bottom: 38px;
}

.add_case_container {
  z-index: 2;
  position: fixed;
  left: 210px;
  top: 91px;
  width: 310px;
  background-color: var(--color-bg-caselist);
}

.filter_container {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.filter_counter {
  margin-right: 20px;
}

.btn_add_case {
  flex: 2;
  width: 160px !important;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
  line-height: 0px !important;
  border-radius: 5px !important;
  margin: 10px !important;
  margin-bottom: 0px !important;
}

.btn_add_case:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.plus_sign {
  margin-right: 10px;
  font-size: 20px;
}

.case_row_unfolded {
  overflow: hidden;
  transition: max-height 0.3s ease-in;
  max-height: 90px;
}

.case_row_folded {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
}

.filterIconActive {
  position: relative !important;
  height: 30px !important;
  margin-left: 14px !important;
  color: rgb(75, 75, 75) !important;
  cursor: pointer !important;
  min-width: 20px !important;
}

.filterMenuPaper {
  width: 218px;
  padding: 6px 6px;
  position: absolute;
  text-align: left;
  z-index: 2;
}

.filterMenuRow {
  padding: 12px 2px;
  cursor: pointer;
}

.filterMenuRow:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.add_case_button {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.add_case_button > button {
  background-color: white;
}
