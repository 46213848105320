.loginFormContainer {
  width: 448px;
  height: 455px;
  background-color: var(--color-bg-login-box);
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
  display: flex;
  flex-direction: column;
}

.loginFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.loginForm {
  margin: 40px;
}

.info_icon {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-blue);
}

.link_sent_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.go_to_login_button {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.go_to_login_button:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.go_to_login_button_gray {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-deactivated) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-deactivated) !important;
  cursor: pointer;
  font-size: 16px;
}

.go_to_login_button_gray:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-deactivated) !important;
}

.error_message {
  color: var(--color-theme-red);
}
