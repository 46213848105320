.termsOfServiceContainer {
  background-color: var(--color-theme-snow);
  padding: 12px 10px;
  color: var(--color-font-0);
  height: 470px;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.iframe {
  width: 100%;
  height: 100%;
  /* background-color: var(--color-theme-snow); */
  color: var(--color-font-0);
  border: none;
}

.accept_container {
  background-color: rgba(240, 240, 240, 0.95);
  position: sticky;
  bottom: 0;
}

@media screen and (max-width: 1600px) {
  .termsOfServiceContainer {
    height: 350px;
  }
}
