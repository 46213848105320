.spf_info_container {
  padding-bottom: 10px;
}

.spf_info_container li {
  padding: 10px 0px;
}

.input_container {
  width: 370px !important;
}

.label {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.label b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.btn_container_validate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.validate_btn {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.save_activate_btn {
  color: var(--color-theme-10) !important;
  background-color: var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}
