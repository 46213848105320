.content_container {
  padding: 5px;
  background-color: var(--color-theme-white);
}

.claim_hr {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 10px 0px;
  width: 100%;
}

.btn_additional_fees {
  flex: 2;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  max-width: 300px !important;
  line-height: 0px !important;
}

.findings_container {
  display: block;
}

.finding_container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.finging_image_container {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finging_image_container svg {
  color: var(--color-theme-10);
  width: 120px;
  height: 60px;
}

.license_link {
  text-decoration: none;
  color: var(--color-theme-10);
}

.finging_image {
  height: 85px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.finging_details_container {
  width: 350px;
  padding: 0px 20px;
}

.finding_date {
  font-size: 12px;
  padding: 5px 0px;
}

.finding_note {
  font-size: 14px;
  padding-top: 15px;
}

.finding_link {
  text-decoration: none;
  color: var(--color-font-10);
  font-size: 18px;
  font-weight: bold;
}

.finging_validity_container {
  width: 100px;
  padding-left: 20px;
  border-radius: 5px;
}

.add_fee_container {
  text-align: right;
}

.additional_fees_container {
  display: block;
}

.additional_fee_container {
  display: flex;
}

.other_fees_container {
  display: block;
}

.vat_container {
  display: flex;
  justify-content: space-between;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.first_input {
  margin-right: 20px !important;
}

.settelment_container {
  display: flex;
  justify-content: space-between;
}

.taxable_switch_container {
  display: block;
  width: 190px;
  padding-top: 5px;
}

.taxable_label {
  font-size: 10px;
  text-align: center;
}

.taxable_switch {
  margin-left: 10px !important;
  margin-top: 0px !important;
}

.btn_delete_container {
  width: 90px;
  padding-top: 35px;
  text-align: left;
  color: var(--color-theme-red);
}

.summary_container {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-darkgray);
  padding: 5px 15px;
}

.license_container {
  margin-right: 15px;
}

.gross_container {
  margin-right: 15px;
}

.discount_container {
  margin-right: 15px;
}

.sumary_label {
  font-size: 12px;
}

.sumary_value {
  font-size: 18px;
  font-weight: bold;
}
