.inbound_text_container {
  display: flex;
  flex-direction: column;
  width: 800px;
}

.header_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.activate_btn {
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}
