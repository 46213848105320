.container {
  width: 550px;
  height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  top: 92px;
  right: 10px;

  padding: 0px 20px;

  box-shadow: 0px 3px 6px var(--color-theme-gray);
  border: 1px solid var(--color-theme-cloud);

  box-sizing: border-box;
  overflow-y: scroll;
}

.signatre_body {
  height: 90%;
  min-height: 470px;
  overflow-y: scroll;
}

.button_container {
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.btn_cancel {
  font-weight: bold !important;
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-silver);
}

.btn_confirm {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.changeNameHeader {
  font-size: 22px;
  color: var(--color-font-2);
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.describtion {
  text-align: left;
}

.email {
  font-size: 16px !important;
  color: var(--color-font-2);
}

.succsse_message {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: 0px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-green);
}

.error_message {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: 0px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-red);
}
