.content_container {
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.info_icon {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-blue);
}

.info_icon_text {
  color: var(--color-theme-blue);
  font-weight: bold;
}

.text_container {
  text-align: center;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin-top: 30px !important;
}

.confirm_btn a {
  text-decoration: none;
  color: var(--color-theme-blue);
}

.confirm_btn:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.confirm_btn:hover a {
  color: var(--color-theme-white) !important;
}
