.email_content {
  font-size: 18px !important;
}

.email_content p {
  font-size: 18px !important;
}

p {
  font-size: 18px !important;
}

.placeholder_missing_none {
  display: none;
}

.btn_confirm {
  width: 200px !important;
}

.btn_disabled {
  font-weight: bold !important;
  width: 200px;
  margin-left: 15px !important;
  color: var(--color-font-10) !important;
  background-color: gray !important;
  padding: 5px 8px !important;
  margin-top: 4px !important;
}

.reminderNameInput {
  display: "inline-block";
  float: "left";
}

.send_email_btn_disabled {
  height: 80px;
  width: 80px;
  background: var(--color-bg-popup-header);
  border: 1px solid #5d5d5d !important;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #707070 !important;
  margin: 0 8px 8px 0;
}

/* standard for all popups */
.title {
  display: flex;
}
.title_icon {
  margin-right: 5px;
}

.send_email_btn {
  height: 80px;
  width: 80px;
  border: 1px solid var(--color-theme-blue) !important;
  background-color: var(--color-theme-white);
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-theme-blue) !important;
  margin: 0 8px 8px 0;
  cursor: pointer;
}

.send_icon {
  margin: 10px;
}

.send_icon_disabled {
  margin: 10px;
  color: var(--color-theme-cloud) !important;
}

.emailContentContainer {
  padding: 10px;
  background-color: var(--color-theme-white);
  color: var(--color-font-0);
  max-height: 500px;
  overflow-y: scroll;
  font-size: 18px !important;
}

.send_email_title {
  font-size: 18px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.placeholder_missing {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  background: var(--color-theme-red);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.placeholder_message_icon {
  color: var(--color-theme-white);
}

.placeholder_message {
  margin: 0 0 0 10px;
  color: var(--color-theme-white);
}

.Mui-disabled {
  background-color: gray !important;
  color: white !important;
}

.loading {
  width: 80px;
}

.reminder_container_content {
  float: left;
}

.attachments_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.menu_item_span {
  margin: 0px 0px;
}

.attachment_item {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px;
  border: 2px solid #e7e5df;
  background-color: #edebe9;
}

.attachment_delete_icon {
  cursor: pointer;
  margin-top: -10px;
  position: relative;
  top: 6px;
}

.attachment_prefix {
  margin-right: 10px;
  top: 4px;
  position: relative;
}
