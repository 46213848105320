/* email row */
.invoice_template_row_root {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.invoice_template_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.root_inactive {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.root_active {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.edit_icon_container {
  display: flex;
  justify-content: flex-end;
}

.edit_icon {
  padding: 0px 10px;
}

.edit_icon:hover {
  color: var(--color-font-10);
}

.input_container {
  width: 65%;
  margin-left: 30px;
}

.template_list_item {
  padding: 0px !important;
}

.invoice_template_row_root:hover .edit_icon {
  color: var(--color-font-10);
}

/* submenu */
.template_row_root {
  display: flex;
  color: var(--color-theme-10);

  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.template_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  color: var(--color-font-10) !important;
}

.template_row_root_selected {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 2px solid var(--color-bg-caselist-item-selected);
}

.drop_down {
  z-index: 8000;
}

.status_item {
  height: 45px;
  font-size: 12px;
}

.status_icon {
  margin: 0px 10px 0px 15px;
}

.status_icon svg {
  font-size: 20px !important;
}

.status_title {
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.delete_template {
  width: 100%;
  display: none;
  justify-content: flex-end;
  color: var(--color-theme-cloud);
}

.add_template_title {
  color: var(--color-theme-silver);
}

.add_template_icon {
  color: var(--color-theme-silver);
}

.template_row_root:hover .add_template_icon {
  color: var(--color-font-10);
}

.template_row_root:hover .add_template_title {
  color: var(--color-font-10);
}

.template_row_root:hover .delete_template {
  display: flex;
  color: var(--color-theme-cloud);
}

.template_row_root_selected .delete_template {
  display: flex;
  color: var(--color-theme-cloud);
}

.delete_icon:hover {
  color: var(--color-theme-blue);
}

.deletionPopupText {
  padding: 5px;
}

.button_confirm {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.button_create {
  width: 200px;
  margin-left: 15px !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  color: var(--color-theme-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.deletionPopupButtonContainer {
  display: flex;
  margin-top: 36px;
  flex-direction: row-reverse;
}
