.privacy_policy {
  max-width: 1125px;
  padding: 20px;
  font-family: 'Calibri', sans-serif;
  text-align: justify;
}

.privacy_policy_header {
  font-size: 22px;
  font-weight: bold;
  padding: 20px 0px;
}

h1 {
  font-size: 11pt;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 11pt !important;
}
