.content_container {
  padding-bottom: 20px;
}

.btn_no {
  font-weight: bold !important;
  width: 150px;
  color: grey !important;
  border: 1px solid grey !important;
  margin-left: 15px !important;
}
