.tos_title {
  padding-bottom: 35px;
  font-weight: 300;
  font-size: 15px;
}

.tosContentContainer {
  width: 850px;
  height: 500px;
  padding: 10px;
  background-color: var(--color-theme-white);
  color: var(--color-font-0);
  overflow-y: scroll;
}

.iframe {
  width: 100%;
  height: 100%;
  color: var(--color-font-0);
  overflow-y: scroll;
  border: none;
}
