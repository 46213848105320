.container {
  width: 450px;
  height: 425px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  position: absolute;
  top: 92px;
  right: 5px;

  padding: 25px 40px 75px;

  box-shadow: 0px 3px 6px var(--color-theme-gray);
  border: 1px solid var(--color-theme-cloud);

  box-sizing: border-box;
}

/* p {
  font-size: 22px;
  margin: 0 !important;
} */

.button_container {
  justify-content: space-between;
  width: 370px;
  display: flex;
}

.btn_cancel {
  font-weight: bold !important;
  border: 1px solid var(--color-theme-silver) !important;
  color: var(--color-theme-silver);
}

.btn_confirm {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.changeNameHeader {
  font-size: 22px !important;
  height: 30px;
  color: var(--color-font-2);
  font-weight: bold;
  text-transform: uppercase;
}

.email {
  font-size: 16px !important;
  color: var(--color-font-2);
}

.succsse_message {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: -1px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-green);
}

.error_message {
  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  bottom: -1px;
  right: 0px;

  color: var(--color-theme-white);
  font-size: 16px;
  background: var(--color-theme-red);
}
