.root {
    padding: 15px 40px 90px 40px;
    height: 100vh;
    overflow: scroll;
    margin-right: auto;
    margin-left: auto;
    color: var(--color-font-10);
    font-size: 16px !important;
  }
  
  .title {
    display: flex;
    align-items: center;
  }
  
  .icon {
    padding-right: 10px;
  }
  
  .arrow_icon {
    padding: 0px 20px;
    color: var(--color-theme-silver);
  }
  

  .confirm_btn {
    color: var(--color-theme-blue) !important;
    border: 1px solid var(--color-theme-blue) !important;
    width: 176px;
    height: 41px;
    font-weight: bold !important;
    margin-top: 30px !important;
    margin-right: 30px !important;
  }
  
  .confirm_btn a {
    text-decoration: none;
    color: var(--color-theme-blue);
  }
  
  .confirm_btn:hover {
    color: var(--color-theme-white) !important;
    background-color: var(--color-theme-blue) !important;
  }
  
  .confirm_btn:hover a {
    color: var(--color-theme-white) !important;
  }
  
  .content_container {
    width: 600px;
    padding-bottom: 80px;
  }

  .date_input_container{
    width: 176px !important;
    display: inline-block;
    margin-right: 30px;
  }

  .description{
    padding-bottom: 20px;
  }
