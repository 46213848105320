.businessInformationContainer {
  width: 100%;
  height: 470px;
}

.businessInformationFormContainer {
  height: 430px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.businessInformationInput {
  max-width: 370px;
  height: 56px;
}

.businessInformationRightFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.businessInformationInputPasswordField {
  margin-bottom: 50px;
}

.businessImgage {
  margin-top: 20px;
  width: 370px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.requiredTag {
  text-align: right;
}

.requiredRed {
  color: var(--color-font-red);
}

.businessInformationLeftFormContainer {
  width: 100%;
  height: 430px;
  display: flex;
  flex-direction: column;
}

.businessInformationDropdown {
  width: 370px !important;
  margin-bottom: 25px;
}

.doubleInput {
  width: 370px;
  display: flex;
  justify-content: space-between;
}
.businessInformationCopy {
  font-size: 16px !important;
}

