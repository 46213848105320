.accountCreation {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg-login);
}

.accountCreationContainer {
  width: 880px;
  height: 690px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  padding: 20px 60px 0 60px;
  background-color: var(--color-bg-login-box);
  box-shadow: 0px 3px 6px var(--color-theme-cloud);
}

.accountCreationHeader {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 35px;
  color: var(--color-font-2);
}

@media screen and (min-width: 1600px) {
  .accountCreationContainer {
    position: fixed;
    top: 35vh;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1600px) {
  .accountCreationContainer {
    padding-bottom: 40px;
  }
}
