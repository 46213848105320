.root {
  padding: 15px 40px 90px 40px;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  height: 80vh;
  color: var(--color-font-10);
  font-size: 16px !important;
  /* overflow-y: scroll !important; */
}

.upper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2%;
}

.lower {
  margin-top: 2%;
}

.settings_icon {
  width: 2%;
  margin: 1%;
  float: right;
  cursor: pointer;
}
.settings_icon:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.loading_title {
  position: fixed;
  left: 50%;
}

.loading_label {
  position: absolute;
  left: 48%;
  margin-top: 75px;
}

.kpi_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 99%;
  min-height: 150px;
}

.kpi_item {
  width: 20%;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 3%;
  cursor: pointer;
}

.kpi_value {
  font-weight: bold;
  font-size: 35px !important;
  margin-bottom: 0% !important;
  margin-top: 4% !important;
}

.kpi_name {
  margin-top: 0% !important;
  margin-bottom: 8% !important;
}

.chart_filters {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.chart_filter {
  /* width: 15%; */
  margin: 0 2%;
}
.date_chart_filters {
  display: flex;
  margin-right: 3%;
  /* width: 100%; */
}
.date_chart_filter {
  margin-right: 6px;
}

.charts_view {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-x: scroll;
  /* min-width: 1450px; */
  align-items: center;
  justify-content: space-between;
}

.bottom_disclaimer {
  margin: auto;
  margin-top: 3%;
  text-align: center;
}
