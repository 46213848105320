.error_container {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.error_text {
  font-size: 16px;
  color: var(--color-theme-white);
}
