.select_kpi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_field {
  width: 35%;
  height: 80px;
}
