/* translation row */
.translation_row_root {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.translation_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.translation_row_root_selected {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 1px solid var(--color-bg-caselist-item-selected);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);

}

.translation_row_root b {
  padding: 0px 15px 0px 10px;
}

.translation_row_root_selected b {
  padding: 0px 15px 0px 10px;
}

.short_language {
  width: 15%;
}

.long_language {
  width: 60%;
  overflow: scroll;
}

.active_translation {
  width: 10%;
  padding: 0px 5px;
}

.default_translation {
  width: 10%;
  padding: 0px 5px;
}
