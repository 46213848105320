.input_container {
  width: 270px !important;
  padding-right: 40px !important;
}

.textContainer {
  width: 800px;
}

.input_row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.label {
  padding: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.label b {
  font-size: 12px;
  color: var(--color-theme-red);
  padding-left: 5px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0px;
}

.btn_container_validate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.validate_btn {
  color: var(--color-theme-10) !important;
  border: 1px solid var(--color-theme-0) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.save_activate_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.circular_progress_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 60px;
}

.smtp_text {
  padding-bottom: 20px;
}

/* TODO define a varible for the links */
.link {
  color: #ff6666;
}

.checkbox_container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
