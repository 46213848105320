.container {
  margin-bottom: 10px;
}

.root_plus_7 {
  display: flex;
  color: var(--color-theme-10);
  height: 52px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;

}
.root {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 15px;
}

.root_inactive {
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
}

.root_active {
  background-color: var(--color-bg-caselist-item-hover);
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.root_inactive:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.left_container {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.status_date {
  font-size: 10px;
  padding-top: 5px;
}

.middle_container {
  width: 210px;
}

.host_value {
  width: 220px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.price_value_container {
  width: 220px;
  display: block;
  padding-top: 5px;
  font-weight: bold;
  text-align: right;
}

.price_value {
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
  width: 125px;
}

.icon {
  margin: 0 5px;
}
.warning_icon {
  color: var(--color-theme-yellow);
}

.progress_bar_root {
  height: 5px !important;
  background-color: var(--color-bg-caselist-item) !important;
  border: 1px solid var(--color-theme-bright-gray) !important;
  transition: none !important;
}

.progress_bar_bar {
  background-color: var(--color-theme-blue) !important;
  transition: none !important;
}

.progress_bar_bar_gray {
  background: linear-gradient(
    45deg,
    #c7c7c7 25%,
    #2680eb 25%,
    #2680eb 50%,
    #c7c7c7 50%,
    #c7c7c7 75%,
    #2680eb 75%,
    #2680eb 100%
  ) !important;
  background-size: 15.57px 17.57px !important;
  transition: none !important;
}

.total_value {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
  margin-top: -7px;
}

.right_container {
  width: 60px;
  margin-left: -15px;
  min-width: 60px;
}

.right_icon_container {
  text-align: center;
}

.callback_icon {
  color: var(--color-font-red);
}

.callback_date {
  font-size: 10px;
  padding-top: 5px;
}

.right_icon_container {
  text-align: center;
}

.view_date {
  font-size: 10px;
  padding-top: 5px;
}

.empty {
  width: 60px;
  text-align: center;
  min-width: 60px;
}

.license_icon_container {
  display: inline-block;
  width: 45px;
  text-align: right;
}

.license_icon {
  color: var(--color-theme-blue);
}

.license_icon svg {
  height: 16px;
}

.host_url {
  display: inline-block;
  width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.curr_sign {
  font-size: 10px;
}

.unread_marker {
  border-radius: 10px;
  width: 7px;
  /* z-index: 20; */
  height: 7px;
  background-color: royalblue;
  position: relative;
  top: -29px;
  left: 25px;
  margin-bottom: -7px;
}

.email_marker_alone {
}
