.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.root p {
  font-size: 16px;
}

.settlement_portal_title {
  display: flex;
  align-items: center;
}

.email_icon {
  padding-right: 10px;
}

.arrow_icon {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.email_setup_header {
  padding-bottom: 40px;
  width: 800px;
}

.email_setup_dropdown {
  min-width: 370px !important;
}

.btn_select_email_setup {
  color: var(--color-theme-10) !important;
  border-color: var(--color-theme-10) !important;
  width: 370px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.menu_item {
  display: flex;
  align-items: center;
}

.menu_item p {
  margin: 2px;
  padding-right: 20px;
}

.check_icon {
  padding-bottom: 3px;
  padding-left: 10px;
}

.email_setup_description {
  padding-bottom: 20px;
}

.select_setup_label {
  color: var(--color-theme-lightgray);
}
