.btn_confirm:disabled {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
}

.btn_delete {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-font-black) !important;
  border: 1px solid var(--color-theme-black) !important;
}

.btn_delete:disabled {
  font-weight: bold !important;
  width: 100px;
  margin-left: 15px !important;
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
}
