.input_send {
  width: 100%;
  max-width: 822px;
  padding-left: 10px;
  outline: none;
  background-color: none;
  border: solid 1px #edebe9;
  height: 32px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.input_copy_paste {
  width: 100%;
  max-width: 805px;
  padding-left: 10px;
  outline: none;
  background-color: none;
  border: solid 1px #edebe9;
  height: 32px;
  margin-bottom: 8px;

  box-sizing: border-box;
}

.row_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: 50px;
  text-align: right;
}

.email_to_autocomplete {
  width: 100%;
  max-width: 822px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.email_to_autocomplete::before {
  width: 100%;
  max-width: 822px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.email_to_autocomplete_cp {
  width: 100%;
  max-width: 803px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.email_to_autocomplete_cp::before {
  width: 100%;
  max-width: 805px;
  background: white;
  border: 1px solid #edebe9;
  margin-bottom: 3px;
}

.mail_chip {
  height: 28px !important;
  margin-left: 4px;
  margin-top: 2px;
}

.send_email_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 15px;
  text-align: right;
}

.to_and_cc_button_container
{
    display: flex;
    width: 100%;
    max-width: 822px;
    padding-left: 10px;
    flex-direction: row;
    justify-content: flex-end;
}


.btn_default {
    color: var(--color-font-10) !important;
  
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    margin-top: 10px;
    height: 33px;
  }