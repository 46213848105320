.upload_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.text_field {
  width: 70%;
}
.text_field_input {
  margin: 0 !important;
}

.cancel {
  margin-right: 20px !important;
}

.confirm {
  margin-left: 0px !important;
}

.file_box {
  margin: 0px 16px;
}

.upload_file_btn_wrap {
  margin-right: 50px;
}

.upload_btn {
  background-color: #283371 !important;
  color: #fff !important;
}
