.root {
  display: block;
}

.root hr {
  background-color: var(--color-theme-cloud);
}

.finding_section {
  display: inline-block;
  min-width: 320px;
  max-width: 320px;
  padding-right: 15px;
}

.overview_section {
  display: inline-block;
  min-width: 500px;
  padding-right: 15px;
}

.activation_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px 20px;
  background-color: var(--color-theme-white);
  border: 1px solid var(--color-theme-cloud);
}

.host_container {
  flex-grow: 4;
}

.details_container {
  display: flex;
}

.status_container {
  min-width: 220px;
}

.status_box {
  display: flex;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.status_name {
  font-size: 20px;
}

.status_icon {
  margin-right: 10px;
  font-size: 28px;
}

.status_date {
  font-size: 14px;
  padding-top: 5px;
}

.btn_container {
  margin-top: 5px;
}

.btn_activate {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 40px;
}

.btn_view_case {
  margin-left: 5px !important;
  font-size: 14px !important;
  padding: 0px 6px !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-gray) !important;
  background-color: var(--color-theme-gray) !important;
}
.view_case_icon {
  margin-left: 5px;
  transform: scale(0.8);
}
.btn_activate:disabled {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  border: 1px solid var(--color-theme-gray) !important;
  background-color: var(--color-theme-gray) !important;
  height: 40px;
}

.btn_cancel {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  height: 40px;
}

.btn_cancel:disabled {
  color: var(--color-theme-deactivated) !important;
  border: 1px solid var(--color-theme-deactivated) !important;
  background-color: var(--color-theme-gray) !important;
}

.btn_reset {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  background-color: var(--color-theme-white) !important;
  height: 40px;
}

.btn_payment {
  width: 150px;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
}

.host {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 350px;
  text-overflow: ellipsis;
}

.source_id {
  font-size: 14px;
  padding-top: 8px;
}

hr {
  height: 1px;
  border: none;
  background-color: var(--color-bg-hr);
  margin: 0;
}

.contact_container {
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
}

.email_flow_container {
  padding: 5px 0px 8px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email_flow_container:hover {
  background-color: var(--color-bg-content-box-hover);
}

.no_hover {
  background-color: unset !important;
  cursor: default;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.contact_container:hover {
  background-color: var(--color-bg-content-box-hover);
}

.contact_info_box {
  padding: 15px 0;
}

.contact_small_title {
  font-size: 14px;
}

.contact_big_title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.claim_container {
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
}
.claim_container:hover {
  background-color: var(--color-bg-content-box-hover);
}

.claim_content {
  display: flex;
}

.claim_info_box {
  padding: 10px 0;
  min-width: 155px;
}

.claim_small_title {
  font-size: 14px;
}

.claim_small_title_component {
  font-size: 14px;
  min-width: 210px;
}

.claim_big_title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.deadline_container {
  position: relative;
  padding-left: 20px;
}

.deadline_title_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deadline_content {
  position: relative;
  display: flex;
  align-items: center;
}

.deadline_info_box {
  padding: 10px 0;
  min-width: 155px;
}

.deadline_small_title {
  font-size: 14px;
}

.deadline_small_title_component {
  font-size: 14px;
  min-width: 210px;
}

.deadline_big_title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.valid_box {
  margin-top: 5px;
  float: right;
}

.not_valid_box {
  margin-top: 5px;
  float: right;
}

.valid_box svg {
  color: var(--color-theme-green);
}

.not_valid_box svg {
  color: var(--color-theme-yellow);
}

.currency {
  font-size: 16px;
}

.cancel_icon {
  display: inline-block;
  margin-right: 10px;
  font-size: 34px;
}
.case_templage_container {
  text-align: right;
}
.cancel_icon:hover {
  color: var(--color-theme-red);
}

.activate_btns_container {
  display: flex;
  cursor: pointer;
}

.image_list {
  background-color: var(--color-bg-content-evidence);
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 2px solid var(--color-theme-cloud);
  position: relative;
  min-width: 310px;
}

.image_item {
  height: 395px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.evidence_container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--color-bg-content-evidence);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-theme-cloud);
  border-top: none;
}

.evidence_action {
  display: flex;
  padding: 16px;
  height: 0px;
}

.evidence_text {
  padding: 20px;
  font-size: 14px;
  font-weight: 600;
}

.finding_count {
  line-height: 22px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}

.evidence_action svg {
  width: 25px;
  height: 25px;
}

.image_icons_container {
  position: absolute;
  display: flex;
  bottom: 5px;
  padding: 5px;
  right: 0px;
  width: 100%;
  overflow: scroll;
  justify-content: flex-end;
}

.image_icon {
  cursor: pointer;
  margin-right: 5px;
  height: 40px;
  width: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--color-theme-10);
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.image_icon:hover {
  border: 1px solid var(--color-theme-3);
}

.image_icon_selected {
  cursor: pointer;
  margin-right: 5px;
  height: 40px;
  width: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid var(--color-theme-3);
}

.no_finding_container {
  height: 395px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
  min-width: 330px;
}

.add_finding_container {
  text-align: right;
  width: 306px;
  background-color: var(--color-bg-caselist);
  margin-right: 10px;
  margin-top: 10px;
}

.btn_add_finding {
  flex: 2;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 40px;
}

.btn_add_finding_disabled {
  flex: 2;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-theme-lightgray) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 40px;
}

.plus_sign {
  margin-right: 10px;
  font-size: 20px;
}

.btn_add_finding:hover {
  color: var(--color-theme-blue) !important;
  background-color: var(--color-theme-lightblue) !important;
}

.no_finding_container_with_image {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  background-color: var(--color-bg-caselist);
}

.btn_add_finding_with_image {
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template_container {
  color: var(--color-font-10);
  text-decoration: none;
  display: flex;
  padding-top: 4px;
  cursor: pointer;
  align-content: space-between;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
}

.case_templage_container {
  width: 100%;
}

.case_template_title {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.case_template_title .MuiAutocomplete-inputRoot {
  height: 55px !important;
}

.template_icon svg {
  height: 25px;
  padding-top: 0px;
}

.template_title {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
}

.warning_icon {
  color: var(--color-theme-yellow);
  margin-left: 10px;
  margin-top: 2px;
}

.eye_icon {
  color: var(--color-theme-white);
  margin-left: 10px;
  margin-top: 9px;
  cursor: pointer;
}

.btn_send_email {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
}

.btn_add_deadline {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
  position: relative;
  top: 15px;
  z-index: 99;
}

.template_dropdown {
  min-width: 250px !important;
}

.template_dropdown_last {
  min-width: 250px !important;
  border-top: 1px solid var(--color-border) !important;
}

.title {
  font-size: 16px;
  padding-top: 10px;
  font-weight: bold;
}

.tag_chip {
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 300px;
}

.tags_container {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
