.flow_template_container {
  display: flex;
  flex-direction: column;
  color: var(--color-theme-10);

  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.flow_input {
  text-decoration: none;
  margin: 10px 0 !important;
  background-color: var(--color-bg-caselist);
  width: 100%;
}

.flow_input input {
  text-overflow: ellipsis;
}

.divider {
  margin: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.divider_line {
  flex: 1;
  border-bottom: 1px solid black;
}

.divider_word {
  padding: 0 10px;
}

.trigger_scheduled {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.disabled {
  opacity: 0.5;
}
