.personalInformationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 470px;
}

.personalInformationInput {
  max-width: 370px;
  height: 56px;
}

.personalInformationFormContainer {
  display: flex;
  flex-direction: column;
  max-width: 370px;
}

.personalInformationInputPasswordField {
  margin-bottom: 50px;
  max-width: 370px;
  height: 56px;
}

.personalImg {
  max-width: 50%;
}

.formContainer {
  margin-bottom: 50px;
}
.requiredTag {
  text-align: right;
}

.requiredRed {
  color: var(--color-font-red);
}


