body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* standard for all popups */
.action_container {
  text-align: right;
  margin-top: 20px;
}

.btn_confirm {
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  width: 150px;
  margin-left: 15px !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.btn_cancel {
  font-weight: bold !important;
  width: 150px;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
}

.btn_activate {
  font-weight: bold !important;
  width: 150px;
  margin-left: 15px !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}
/* standard for all popups */
.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: red !important;
}

.input[type="date"]::-webkit-calendar-picker-indicator {
  color: black;
  background-color: yellow;
  border: solid;
  border-color: pink;
}

.btn_disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border-color: rgba(0, 0, 0, 0.26) !important;
  cursor: default;
  pointer-events: none;
}
