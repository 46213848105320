.checkbox_text {
  font-size: small;
}
.checkbox_missing {
  color: red;
}

.confirm_disabled {
  opacity: 0.5;
  pointer-events: none;
}
