.root {
  width: 100%;
  color: var(--color-font-10);
  overflow: scroll;
  height: calc(100vh - 133px);
  overflow-y: scroll;
}

.flow_list_container {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 210px;
  top: 91px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: -webkit-calc(100vh - 105px);
  height: -moz-calc(100vh - 105px);
  height: calc(100vh - 140px);
  z-index: 100;
}

.new_flow_button {
  margin: 0 auto;
  width: fit-content;
  text-align: center;
  align-self: center;
  background-color: var(--color-bg-caselist-item);
}

/* add flow row  */
.email_flow_input {
  background-color: var(--color-bg-caselist-item);
  outline: none;
  border: none;
  color: var(--color-font-10);
  font-weight: bold;
  font-size: 16px;
}

.email_row_root {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.email_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.account_tree_icon {
  margin: 0px 10px;
}
/* template details */
.template_container {
  overflow: hidden;
  margin-left: 310px;
  background-color: var(--color-bg-content);
}

.template_no_data {
  text-align: center;
  margin-top: 300px;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--color-theme-silver);
}
