.paper {
  min-width: 450px;
  max-width: 650px !important;
  overflow: visible;
  min-height: 100px;
  background-color: var(--color-bg-popup) !important;
  color: var(--color-font-10) !important;
}

.paper_big {
  min-width: 1000px;
  max-width: 1000px !important;
  overflow: visible;
  min-height: 100px;
  background-color: var(--color-bg-popup) !important;
  color: var(--color-font-10) !important;
}

.paper::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.paper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}
.paper_big::-webkit-scrollbar {
  width: 8px !important;
  display: inherit !important;
  background-color: white !important;
}

.paper_big::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
  display: inherit !important;
  width: 10px !important;
}

.popup_header {
  text-transform: uppercase;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--color-bg-popup-header);
}

.popup_content {
  padding: 15px;
}
