.sidebar {
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 104px);
  overflow-y: scroll;
  width: 210px;
  background-color: var(--color-bg-sidebar);
  flex-grow: 1;
  padding-top: 75px;
}

.sidebar_header {
  padding-left: 25px;
}

.navbar {
  margin-top: 8px;
  color: var(--color-font-10);
}

.List_item {
  background-color: var(--color-bg-sidebar-item) !important;
}

.List_item:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.selected {
  background-color: var(--color-bg-sidebar-item-selected) !important;
}

.selected:hover {
  background-color: var(--color-bg-sidebar-item-selected) !important;
}

.row_container {
  width: 220px;
  display: flex;
  align-items: center;
}

.count {
  font-size: 15px;
  text-align: right;
  width: 30px;
  display: inline-block;
  font-weight: bold;
}

.cases_item {
  font-size: 12px;
  color: var(--color-font-10);
  height: 50px;
}

.cases_icon {
  height: 22px;
}

.cases_icon svg {
  font-size: 22px !important;
  margin-left: 7px;
  margin-right: 10px;
}

.cases_title {
  width: 106px;
  font-size: 16px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.status_item {
  height: 45px;
  font-size: 12px;
}

.status_icon {
  height: 19px;
}

.status_no_icon {
  margin-left: 65px;
}

.status_icon svg {
  font-size: 20px !important;
  margin-left: 24px;
  margin-right: 11px;
}

.status_title {
  width: 90px;
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.status_title_non_clickable {
  width: 90px;
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: default;
}

.settings_container {
  padding-bottom: 150px;
}

.settings_nav {
  overflow: scroll;
}

.settings_icon {
  font-size: 22px !important;
  margin-left: 7px;
  margin-right: 10px;
}

.email_flows_agent {
  margin: 0px 0px;
}
