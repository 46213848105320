.upgrade_image {
  width: 110% !important;
  height: 344px;
  padding: 0;
}

.subscription_image {
  height: 300px;
  width: 90%;
}
.content_container {
  font-size: 22px !important;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  text-align: center;
}

.MuiButtonBaseRoot {
  text-transform: 'none';
  border-radius: 0 !important;
  height: 89px;
  width: 340px;
}

.MuiButtonBaseRoot:hover {
  background-color: var(--color-theme-lightblue) !important;
}

.bold_text {
  font-size: 22px !important;
  font-weight: 600;
}

.regular_text {
  font-size: 22px !important;
}
