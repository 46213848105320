.welcomeToFairLicensingContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 470px;
  padding: 95px;
  box-sizing: border-box;
}

.welcomeToFairLicensingTextContainer {
  max-width: 370px;
}

.welcomeToFairLicensingTitle {
  font-size: 22px !important;
  font-weight: bold;
  margin-bottom: 30px !important;
}

.welcomeToFairLicensingSubtitle {
  font-size: 22px !important;
  margin-bottom: 30px !important;
}

.welcomeToFairLicensingEmail {
  color: var(--color-font-blue);
}

.welcomeToFairLicensingCopy {
  font-size: 16px;
}

.welcomeToFairLicensingImage {
  height: 180px;
  margin: 0 !important;
}