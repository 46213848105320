.highlightTextarea {
  position: relative;
  width: 100%;
}

.highlightTextarea .highlightTextarea-container {
  position: absolute;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.highlightTextarea .highlightTextarea-highlighter {
  position: absolute;
  z-index: 1;
  border: none;
  margin: 0;
  color: transparent;
  cursor: text;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: inherit;
}

.highlightTextarea textarea,
.highlightTextarea input {
  position: relative;
  z-index: 2;
  left: 0;
  top: 0;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: transparent;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: inherit;
}

/* Debug mode for highlighter */
.highlightTextarea.debug .highlightTextarea-highlighter {
  color: #f00;
  border: 1px solid #f00;
  margin: -1px;
}

/* Style adjustments for marks */
.highlightTextarea mark {
  line-height: inherit;
  color: transparent;
  margin: 0;
  padding: 0;
}

/* Resizable settings */
.highlightTextarea .ui-wrapper {
  margin: 0 !important;
}

.highlightTextarea .ui-resizable-se {
  bottom: 15px;
  right: 0px;
}
