.loginFormHeader {
  color: var(--color-font-2);
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 40px;
}

.loginForm {
  margin: 40px;
}

.link_sent_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.success_icon {
  width: 70px !important;
  height: 80px !important;
  color: var(--color-theme-green);
}

.go_to_login_button {
  width: 270px;
  height: 41px;
  border: 1px solid var(--color-theme-blue) !important;
  border-radius: 4px;
  background-color: var(--color-theme-white) !important;
  color: var(--color-theme-blue) !important;
  cursor: pointer;
  font-size: 16px;
}

.go_to_login_button:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}
