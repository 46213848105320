.detail_container {
  left: 300px;
  position: relative;
  display: flex;
  width: calc(100% - 300px);
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  height: calc(100vh - 211px);
}

.top_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 850px;
}

.detail_row {
  background-color: white;
  max-width: 850px;
  border: 3px solid whitesmoke;
  
  min-width: 850px;
  padding: 8px
}

.detail_scrollbar{
  margin-top: 36px !important;
}

.btn_default {
  color: var(--color-font-10) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px !important;
}

.btn_default:disabled {
  color: var(--color-font-10) !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  margin-top: 10px !important;
  opacity: 0.6;
}
