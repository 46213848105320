.message {
  width: 100%;
}

.container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

p {
  margin-left: 10px;
}
