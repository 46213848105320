.payment_info_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px 20px;
  background-color: var(--color-theme-white);
  border: 1px solid var(--color-theme-cloud);
  margin-top: 20px;
  font-size: 13px;
  cursor: pointer;
}

.status_column {
  display: flex;
}

.icon {
  vertical-align: middle;
  display: inline-flex;
  margin: auto 20px;
  scale: 1.8;
}

.big_n_bold {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.bold {
  font-weight: 700;
}

.small_title {
  font-size: 14px;
  min-width: 210px;
  margin-top: 20px;
}

.big_value {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
  min-height: 25px;
}

.popup_ok_button {
  width: 115px;
  height: 52px;
  font-weight: bold !important;
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
}

.dialog_title {
  font-weight: bold !important;
  margin: 20px 20px 0px 20px;
  font-size: 22px;
}
