.user_container {
  flex: 1 1;
  cursor: pointer;
}

.user_name {
  padding-right: 25px;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.6px;
}

.user_icon {
  margin-right: 25px;
  transform: scale(1.41);
  color: var(--color-theme-blue);
}

.right_container {
  flex: 1;
  text-align: right;
  min-width: 400px;
  display: flex;
  overflow-y: scroll;
}

.MuiButtonBaseRoot {
  text-transform: "none";
  border-radius: 0 !important;
  height: 89px;
  width: 340px;
}

.MuiButtonBaseRoot:hover {
  background-color: var(--color-theme-lightblue) !important;
}

.menu {
  overflow-y: scroll;
}
