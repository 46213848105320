.editor_root {
  width: 850px;
  padding: 15px;
  color: var(--color-font-10);

  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}


.header {
  display: flex;
  justify-content: space-between;
}
.sub_header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description {
  font-size: 10px !important;
  margin-top: 0px;
  font-style: italic;
}
.template_action_container {
  display: flex;
  flex-direction: column;
  margin-top: 11px;
}

.preview_button {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 240px;
  height: 41px;
}
.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  height: 41px;
  width: 240px;

  font-weight: bold !important;
  margin: 5px 0px !important;
}
.template_header {
  width: 45%;
}
.TwoInputs {
  display: flex;
  justify-content: space-between;
}
.subInput {
  width: 45% !important ;
}
.block {
  display: flex;
  justify-content: space-between;
}
.left {
  width: 48%;
}
.right {
  width: 48%;
}
.left3 {
  width: 32%;
}
.right3 {
  width: 32%;
}
.middle3 {
  width: 32%;
}
