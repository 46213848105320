.content_container {
  display: flex;
  justify-content: space-between;
}

.MuiOutlinedInput-notchedOutline {
  background-color: var(--color-theme-silver) !important;
}

.btn_copy_data {
  flex: 2;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  min-width: 350px !important;
  line-height: 0px !important;
}

.contact_container {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.TwoInputs {
  display: flex;
  justify-content: space-between;
}
.subInput {
  width: 45% !important ;
}
