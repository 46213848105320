.errorAccountCreationContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 470px;
  background-color: #f7e8e8;
}

.errorAccountCreationImage {
  height: 220px;
}

.errorAccountCreationTextContainer {
  max-width: 370px;
}

.errorAccountCreationTitle {
  font-size: 22px !important;
  font-weight: bold;
  margin-bottom: 30px !important;
}

.errorAccountCreationSubtitle {
  font-size: 22px !important;
}

.errorAccountCreationEmail {
  color: var(--color-font-blue);
}

.errorAccountCreationCopy {
  margin-bottom: 30px !important;
  font-size: 16px;
}
