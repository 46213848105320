.root {
  padding: 20px 20px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
}

.list_container {
  position: fixed;
  left: 210px;
  top: 90px;
  width: 290px;
  background-color: var(--color-bg-caselist);
  overflow: scroll;
  padding: 10px;
  padding-top: 10px;
  overflow-x: hidden;
  height: calc(100vh - 148px);
}

.list_item_container {
  margin: 5px;
  padding: 10px;
  max-height: 90px;
  min-height: 40px;
  background-color: var(--color-bg-caselist-item);
  border: 1px solid var(--color-theme-bright-gray);
  cursor: pointer;
}

.list_item_container_selected {
  margin: 5px;
  padding: 10px;
  max-height: 90px;
  min-height: 40px;
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
  cursor: pointer;
  box-shadow: 2px 2px 3px var(--color-theme-cloud);
}

.list_item_container:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 1px solid var(--color-bg-caselist-item-hover);
}

.lower_row_gray {
  color: #bbbbbb;
}

.unread_marker {
  border-radius: 10px;
  width: 7px;
  height: 7px;
  background-color: royalblue;
  float: right;
}
