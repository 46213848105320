.edit_popup {
  z-index: 8000;
}

.edit_popup_container {
  position: fixed !important;
  left: 210px;
  top: 91px;
  width: 290px;
}

/* email row */
.email_row_root {
  margin-top: 10px;
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.email_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
}

.email_flow_input {
  background-color: var(--color-bg-caselist-item);
  outline: none;
  border: none;
  color: var(--color-font-10);
  font-weight: bold;
  font-size: 16px;
}

.account_tree_icon {
  margin: 0px 10px;
}

.edit_icon_container {
  display: flex;
  justify-content: flex-end;
}

.edit_icon {
  padding: 0px 10px;
}
.warning_icon {
  color: var(--color-theme-yellow);
}

.edit_icon:hover {
  color: var(--color-font-10);
}

.input_container {
  width: 65%;
}

.flow_list_item {
  padding: 0px !important;
}

.email_row_root:hover .edit_icon {
  color: var(--color-font-10);
}

/* submenu */
.template_row_root {
  display: flex;
  color: var(--color-theme-10);

  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;

  background-color: var(--color-bg-caselist-item);
  border: 2px solid var(--color-bg-caselist-item);
}

.template_row_root:hover {
  background-color: var(--color-bg-caselist-item-hover);
  border: 2px solid var(--color-bg-caselist-item-hover);
  color: var(--color-font-10) !important;
}

.template_row_root_selected {
  display: flex;
  color: var(--color-theme-10);
  height: 45px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  background-color: var(--color-bg-caselist-item-selected);
  border: 2px solid var(--color-bg-caselist-item-selected);
}

.status_item {
  height: 45px;
  font-size: 12px;
}

.status_icon {
  margin: 0px 10px 0px 15px;
}

.status_icon svg {
  font-size: 20px !important;
}

.status_title {
  font-size: 13px;
  display: inline-block;
  height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.delete_template {
  width: 100%;
  display: none;
  justify-content: flex-end;
  color: var(--color-theme-cloud);
}

.add_template_title {
  color: var(--color-theme-silver);
}

.add_template_icon {
  color: var(--color-theme-silver);
}

.template_row_root:hover .add_template_icon {
  color: var(--color-font-10);
}

.template_row_root:hover .add_template_title {
  color: var(--color-font-10);
}

.template_row_root:hover .delete_template {
  display: flex;
  color: var(--color-theme-cloud);
}

.template_row_root_selected .delete_template {
  display: flex;
  color: var(--color-theme-cloud);
}

.delete_icon:hover {
  color: var(--color-theme-blue);
}
