.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  color: var(--color-font-10);
  overflow: scroll;
  max-width: 1125px;
}

.account_title {
  display: flex;
  align-items: center;
}

.business_icon {
  padding-right: 10px;
}

.arrow_icon {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.account_title {
  display: flex;
  align-items: center;
}

.business_icon {
  padding-right: 10px;
}

.confirm_btn_container {
  text-align: right;
  width: 775px;
  margin: 20px 0 !important;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
}

.account_content {
  width: 775px;
}

.business_container {
  display: flex;
  justify-content: space-between;
}

.business_col {
  padding: 20px 0;
}

.info_text {
  font-size: 16px;
}

.info_text a {
  color: var(--color-theme-blue);
  text-decoration: none;
}

.input:hover {
  text-decoration: none;
  border: none !important;
}

.error_container {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.error_text {
  font-size: 16px;
  color: var(--color-theme-white);
}

.empty_row {
  height: 80px;
}

.input {
  width: 370px !important;
}
