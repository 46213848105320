.root {
  width: 100%;
  color: var(--color-font-10);
  height: calc(100vh - 133px);
  display: flex;
}

.template_list_container {
  width: 29px;
  min-width: 290px;
  background-color: var(--color-bg-caselist);
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
  height: -webkit-calc(100vh - 105px);
  height: -moz-calc(100vh - 105px);
  height: calc(100vh - 140px);
  z-index: 100;
  align-items: center;
}

.add_template_container {
  display: flex;
  justify-content: center;
}
.template_container {
  background-color: var(--color-bg-content);
  width: 78%;
  min-width: fit-content;
  overflow-y: scroll;
}
