.finding_hr {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 10px 0px;
  width: 100%;
}

.btn_delete {
  width: 100px !important;
  color: var(--color-font-red) !important;
  border: 1px solid var(--color-theme-red) !important;
  font-weight: bold !important;
}

.first_input {
  margin-right: 20px !important;
}

.contact_container {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.screenshot_section_container {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-theme-darkgray);
  padding: 15px;
}

.imprint_image_container {
  width: 140px;
}

.imprint_image {
  height: 80px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.imprint_text_container {
  width: 250px;
  text-align: center;
  line-height: 40px;
}

.imprint_btn_container {
  display: block;
  width: 100px;
}

/* ------ */

.finding_container {
  background-color: white;
  width: 100%;
  margin: 10px 0px;
  padding-bottom: 2px;
}

.finding_action_container {
  margin-right: 10px;
  margin-bottom: 10px;
}

.validate_container {
  text-align: right;
  margin: 10px 10px;
}

.finding_images_container {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

.finding_image {
  height: 100px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}
.image_container {
  width: 45%;
  margin-top: 10px;
}
.image_name {
  margin: 0px 10px;
}
.finding_link {
  font-size: 16px;
  text-decoration: underline;
  color: var(--color-theme-blue);
  display: block;
  margin: 10px 0;
}

.finding_link_secondary {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 5px;
}

.licence_container {
  margin-top: 10px;
  display: flex;
  padding: 15px;
  background-color: var(--color-theme-black);
}
.license_text {
  display: inline-block;
 
}
.licence_icon {
  margin-right: 15px;
}

.licence_icon svg {
  width: 35px;
  height: 60px;
}

.license_details_container {
  width: 400px;
  padding: 12px 0;
}

.license_link {
  color: var(--color-font-10);
  font-weight: bold;
}

.license_date {
  font-size: 14px;
}

.licence_validate_container {
  display: block;
  width: 100px;
}

.validate_label {
  font-size: 10px;
  text-align: center;
}

.validate_switch {
  margin-left: 20px !important;
  margin-top: 0px !important;
}

.add_finding_container {
  text-align: right;
  width: 310px;
  background-color: var(--color-bg-caselist);
}

.btn_add_finding {
  flex: 2;
  width: 190px !important;
  font-weight: 600 !important;
  color: var(--color-font-10) !important;
  height: 40px;
  line-height: 0px !important;
  margin-left: 20px;
}

.plus_sign {
  margin-right: 10px;
  font-size: 20px;
}
