.root {
  margin-top: 20px;
}

.title_container {
  margin-bottom: 30px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.title_font {
  font-weight: 600 !important;
  cursor: default;
  font-size: 20px;
}

.add_note_button {
  color: var(--color-font-10) !important;
  border-color: var(--color-font-10) !important;
  position: relative;
  z-index: 99;
}

.title_right_side {
  display: flex;
}

.future_section_hidden {
  animation-name: heigthChangeBack;
  animation-duration: 1500ms;
  overflow: hidden;
  max-height: 0px;
}

.future_section_visible {
  animation-name: heigthChange;
  animation-duration: 1500ms;
  overflow: hidden;
}

.future_section_hidden_no_animation {
  overflow: hidden;
  max-height: 0px;
}

.future_section_visible_no_animation {
  overflow: hidden;
}

@keyframes heigthChange {
  from {
    max-height: 0px;
  }
  to {
    max-height: 4000px;
  }
}

@keyframes heigthChangeBack {
  from {
    max-height: 900px;
  }
  to {
    max-height: 0px;
  }
}

.activity_container {
  margin-bottom: 15px;
}

.activity_container_more_button{
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.loading {
  width: 100%;
  text-align: center;
  color: black;
}

.devider_container {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.devider_border {
  border-bottom: 2px solid black;
  width: 100%;
}

.devider_content {
  padding: 0 10px 0 10px;
  font-weight: bold;
  margin: 0px 60px;
}

.flex_right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
}
