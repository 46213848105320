.content_container {
  padding: 5px;
}

.activate_hr {
  height: 1px;
  border: none;
  background-color: var(--color-theme-silver);
  margin: 15px 0px;
  width: 100%;
}

.price_row {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.price_container {
  width: 170px;
  padding-left: 15px;
}
.price_label {
  font-size: 12px;
}

.price_value {
  font-size: 20px;
  padding-top: 3px;
  overflow-wrap: break-word;
}

.disclaimer {
  font-size: 14px;
  padding: 15px;
  color: var(--color-theme-blue);
  border: 1px solid var(--color-theme-blue);
  text-align: justify;
  margin-bottom: 10px;
}

.total_container {
  margin: 15px 0px;
  padding: 15px;
  background-color: var(--color-theme-snow);
}

.total_label {
  font-size: 12px;
}

.total_value {
  font-size: 20px;
  font-weight: bold;
}

.btn_additional_fees {
  flex: 2;
  font-weight: 600 !important;
  color: var(--color-theme-10) !important;
  height: 40px;
  max-width: 300px !important;
  line-height: 0px !important;
}

.findings_container {
  display: block;
}

.finding_container {
  display: flex;
  align-items: flex-start;
}

.finging_image_container {
  width: 70px;
}

.finging_image {
  height: 55px;
  padding-top: 15px;
  padding-left: 5px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.finging_details_container {
  width: 280px;
  padding: 6px 20px;
}

.finding_id {
  font-size: 12px;
  max-width: 200px;
  overflow-x: scroll;
}

.finding_link {
  text-decoration: none;
  color: var(--color-font-10);
}

.finging_price_container {
  width: 120px;
  padding-top: 15px;
}

.currency {
  font-size: 16px;
}

.error_container {
  padding: 10px;
  margin: 0px 5px 25px 5px;
  background-color: var(--color-theme-red);
}

.error_text {
  font-size: 16px;
  color: var(--color-theme-white);
}
