.root {
  padding: 15px 40px 90px 40px;
  height: calc(100vh - 188px);
  overflow-y: scroll;
  overflow: scroll;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-font-10);
  font-size: 16px !important;
}

.account_title {
  display: flex;
  align-items: center;
}

.business_icon {
  padding-right: 10px;
}

.arrow_icon {
  padding: 0px 20px;
  color: var(--color-theme-silver);
}

.account_title {
  display: flex;
  align-items: center;
}

.business_icon {
  padding-right: 10px;
}

.stripe_container {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.text_container {
  width: 50%;
  margin: 30px 0 0 40px;
}

.logo_container {
  width: 20%;
}

.confirm_btn {
  color: var(--color-theme-blue) !important;
  border: 1px solid var(--color-theme-blue) !important;
  width: 174px;
  height: 41px;
  font-weight: bold !important;
  margin-top: 30px !important;
}

.confirm_btn a {
  text-decoration: none;
  color: var(--color-theme-blue);
}

.confirm_btn:hover {
  color: var(--color-theme-white) !important;
  background-color: var(--color-theme-blue) !important;
}

.confirm_btn:hover a {
  color: var(--color-theme-white) !important;
}

.connected_account {
  display: flex;
  align-items: center;
  color: var(--color-theme-green);
  padding: 20px 0px;
  font-weight: bold;
}

.connected_account p {
  font-size: 22px !important;
}

.check_circle_icon {
  margin-right: 10px;
}

.stripe_logo {
  width: 250px;
  height: 120px;
}
